import { useEffect, useRef } from "react";
import { Role } from "../classes/Scenario";
import Facilitator from "../res/svg/receptionist.svg";
import { playerType } from "../ServerCommunication";
import useModalStore from "../stores/modalStore";
import Img from "./Img";
import CountUp from "react-countup";

export default function Players({ playersData, leaveGameRequest, roles }: { playersData: Array<any>; leaveGameRequest: Function; roles: Role[] }) {
  const [lobbyScores, setSelectedElement] = useModalStore((state) => [state.lobbyScores, state.setSelectedElement]);
  const p = useRef<any>();
  function getRoleByID(id: string) {
    for (let j = 0; j < roles.length; j++) {
      if (id === roles[j].id) {
        return roles[j];
      }
    }
  }
  function mouseEnter(item: any, type: string) {
    if (type === "player") {
      setSelectedElement({ player: item, type: type });
    } else {
      setSelectedElement({ role: item, type: type });
    }
  }
  function mouseOut() {
    setSelectedElement(null);
  }
  return (
    <>
      {playersData.map(function (player: any, index: number) {
        let role: Role | null | undefined = null;
        role = getRoleByID(player.selectedRoleId);
        if (role) {
          if (role.image) {
            return (
              <div className="username-container" key={index} ref={p}>
                <span style={{ color: player.userColor }} onMouseOver={() => mouseEnter(player, "player")} onMouseOut={() => mouseOut()}>
                  {player.username}
                </span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="lobby_score" style={{ color: player.userColor }}>
                    {lobbyScores && JSON.stringify(lobbyScores.playerScores[player.playerId])}
                  </span>
                  <Img src={role.image} className="username-role" onMouseEnter={() => mouseEnter(role, "role")} onMouseOut={() => mouseOut()}></Img>
                </div>
              </div>
            );
          } else {
            return (
              <div className="username-container" key={index} ref={p}>
                <span style={{ color: player.userColor }} onMouseOver={() => mouseEnter(player, "player")} onMouseOut={() => mouseOut()}>
                  {player.username}
                </span>
                <div style={{ display: "flex", alignItems: "center" }} onMouseEnter={() => mouseEnter(role, "role")} onMouseOut={() => mouseOut()}>
                  <span className="lobby_score" style={{ color: player.userColor }}>
                    {lobbyScores && JSON.stringify(lobbyScores.playerScores[player.playerId])}
                  </span>
                  <div
                    style={{ backgroundColor: role.backgroundColor }}
                    className="username-role round"
                    onMouseEnter={() => mouseEnter(role, "role")}
                    onMouseOut={() => mouseOut()}
                  ></div>
                </div>
              </div>
            );
          }
        } else {
          if (player.playerType === playerType.facilitator) {
            return (
              <div className="username-container" key={index} ref={p}>
                <span style={{ color: player.userColor }} onMouseEnter={() => mouseEnter(player, "player")} onMouseOut={() => mouseOut()}>
                  {player.username}
                </span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="lobby_score" style={{ color: player.userColor }}>
                    {lobbyScores && JSON.stringify(lobbyScores.playerScores[player.playerId])}
                  </span>
                  <Img src={Facilitator} className="username-role"></Img>
                </div>
              </div>
            );
          } else {
            return (
              <div className="username-container" key={index} ref={p}>
                <span style={{ color: player.userColor }} onMouseEnter={() => mouseEnter(player, "player")} onMouseOut={() => mouseOut()}>
                  {player.username}
                </span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="lobby_score" style={{ color: player.userColor }}>
                    {lobbyScores && JSON.stringify(lobbyScores.playerScores[player.playerId])}
                  </span>
                  <div
                    style={{ backgroundColor: "transparent" }}
                    className="username-role round"
                    onMouseEnter={() => mouseEnter(role, "role")}
                    onMouseOut={() => mouseOut()}
                  ></div>
                </div>
              </div>
            );
          }
        }
      })}
      <Scores />
      <button
        id="leave-game"
        className="login-button"
        onClick={(e) => {
          leaveGameRequest();
        }}
      >
        Leave
      </button>
    </>
  );
}
/*{player.selectedRole.image && <img src={player.selectedRole.image} className="username-role"></img>}*/

function Scores() {
  const [lobbyScores, setSelectedElement] = useModalStore((state) => [state.lobbyScores, state.setSelectedElement]);
  const score = useRef<any>();
  function mouseEnter(role: Role) {
    setSelectedElement({ role: role, type: "role" });
  }
  function mouseOut() {
    setSelectedElement(null);
  }
  return (
    <>
      {lobbyScores && (
        <div className="team-score-container">
          {lobbyScores.rolesPlayedInSession.length > 0 && <hr className="team-score-separator"></hr>}
          {lobbyScores.rolesPlayedInSession.length > 0 && <span style={{ textAlign: "center" }}>Roles Played</span>}
          <div style={{ display: "flex", overflowX: "auto", overflowY: "hidden" }}>
            {lobbyScores.rolesPlayedInSession.map((role: Role, index: number) => {
              return (
                <div ref={score} key={index}>
                  {role.image ? (
                    <img className="username-role mg" src={role.image} alt="" onMouseEnter={() => mouseEnter(role)} onMouseOut={() => mouseOut()} />
                  ) : (
                    <div
                      className="username-role round mg"
                      style={{ backgroundColor: role.backgroundColor }}
                      onMouseEnter={() => mouseEnter(role)}
                      onMouseOut={() => mouseOut()}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>
          {Object.keys(lobbyScores.teamScore).length > 0 && (
            <>
              <hr className="team-score-separator"></hr>
              <span style={{ textAlign: "center" }}>Team Score</span>
            </>
          )}

          {Object.keys(lobbyScores.teamScore).map((score, index) => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }} key={`${index}_score`}>
                <span>{score}</span>
                <span>{lobbyScores.teamScore[score]}</span>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

function Wrapper(props: any) {
  const ref = useRef<any>();

  /*useEffect(() => {
    ref.current.addEventListener("onmouseenter", props.onMouseEnter);
    ref.current.addEventListener("onmouseout", props.onMouseOut);
    return function cleanup() {
      ref.current.removeEventListener("onmouseenter", props.onMouseEnter);
      ref.current.removeEventListener("onmouseout", props.onMouseOut);
    };
  }, []);*/

  return <div ref={ref}>{props.children}</div>;
}
