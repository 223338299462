import { useState, useEffect, useRef } from "react";
import { isMobile, getMousePos } from "../functions";
import Scenario, { ScenarioElement, scenarioElementType, RoleOutcomesSelector } from "../classes/Scenario";
import { Point, Vector } from "../utils";
import Img from "../components/Img";
import { ReactComponent as Lock } from "../assets/svg/lock.svg";
import { ReactComponent as Hidden } from "../assets/svg/invisible.svg";

var selector = false;
var roleOutcomesSelectorGlobal: RoleOutcomesSelector | null = null;
var offset: Vector;
let dragStartPosition: Point;
let startPosition: Point;
export default function EditorOutcome({
  outcomeIndex,
  scenario,
  roleOutcomesSelector, // this is used for the Role settings, where the ideal, settle, and failure outcomes can be set for a given role. When the user is selecting these outcomes, then the onclick events of the outcomes need to perform different operations, and this class isused to communicate the selected outcomes between the EditorOutcome and RoleOutcomes components
  setRoleOutcomesSelector,
  updateScenario,
  setSelectedElement,
  locked = false,
}: {
  outcomeIndex: number;
  scenario: Scenario;
  roleOutcomesSelector: RoleOutcomesSelector | null;
  setRoleOutcomesSelector: Function;
  updateScenario: Function;
  setSelectedElement: Function;
  locked: boolean;
}) {
  const outcomeHTML: any = useRef();
  let outcome = scenario.outcomes[outcomeIndex];
  let dragging: boolean = false;
  const [radius, setRadius] = useState<number>(0);
  useEffect(() => {
    //console.log("roleoutcomeselectior useffect!", roleOutcomesSelector);
    outcomeHTML.current.classList.remove("selected-editor-outcome");
    selector = roleOutcomesSelector != null;
    roleOutcomesSelectorGlobal = roleOutcomesSelector;
    //if()
    if (roleOutcomesSelectorGlobal) {
      if (roleOutcomesSelectorGlobal.selectedOutcomes.includes(outcome.id)) {
        outcomeHTML.current.classList.add("selected-editor-outcome");
      }
    }
  }, [roleOutcomesSelector]);
  useEffect(() => {
    if (outcome.position) {
      let canvas: any = document.getElementsByClassName("editor-canvas")[0];
      outcomeHTML.current.style.left = canvas.offsetLeft + (outcome.position.x / 135) * canvas.offsetWidth + "px";
      outcomeHTML.current.style.top = canvas.offsetTop + (outcome.position.y / 100) * canvas.offsetHeight + "px";
      outcomeHTML.current.style.width = (outcome.width / 135) * canvas.offsetWidth + "px";
      outcomeHTML.current.style.height = (outcome.width / 100) * canvas.offsetHeight + "px";
    }
    window.addEventListener("resize", resize);
    if (isMobile.any()) {
      outcomeHTML.current.addEventListener("touchstart", dragStart);
      window.addEventListener("touchmove", drag);
      window.addEventListener("touchend", dragEnd);
    } else {
      outcomeHTML.current.addEventListener("mousedown", dragStart);
      window.addEventListener("mousemove", drag);
      window.addEventListener("mouseup", dragEnd);
    }
    outcomeHTML.current.addEventListener("click", click);
    setRadius(parseFloat(outcomeHTML.current.style.width) / 8);
    return function cleanup() {
      if (outcomeHTML.current) {
        if (isMobile.any()) {
          outcomeHTML.current.removeEventListener("touchstart", dragStart);
          window.removeEventListener("touchmove", drag);
          window.removeEventListener("touchend", dragEnd);
        } else {
          outcomeHTML.current.removeEventListener("mousedown", dragStart);
          window.removeEventListener("mousemove", drag);
          window.removeEventListener("mouseup", dragEnd);
        }
        outcomeHTML.current.removeEventListener("click", click);
      }
      window.removeEventListener("resize", resize);
    };
  }, [scenario, locked]);

  function dragStart(e: any) {
    if (!selector) {
      dragging = true;
      selectElement();
      offset = new Vector(0, 0);
      startPosition = new Point(parseFloat(outcomeHTML.current.style.left), parseFloat(outcomeHTML.current.style.top));
      let mousePos = getMousePos(e);
      dragStartPosition = new Point(mousePos!.x, mousePos!.y);
    }
  }

  function drag(e: any) {
    if (!locked) {
      if (!selector) {
        if (dragging) {
          if (outcomeHTML.current) {
            offset = new Vector(globalThis.mousePos.x - dragStartPosition.x, globalThis.mousePos.y - dragStartPosition.y);
            //console.log("mouseposx:", globalThis.mousePos.x);
            outcomeHTML.current.style.left = startPosition.x + offset.x + "px";
            outcomeHTML.current.style.top = startPosition.y + offset.y + "px";
          }
        }
      }
    }
  }

  useEffect(() => {
    selectElement(); //@ts-ignore
  }, [outcome.outcomeCondition.scoreCurrency]);

  function selectElement() {
    let selectedElement = new ScenarioElement(outcome, scenarioElementType.outcome);
    setSelectedElement(selectedElement);
    let outcomes = document.getElementsByClassName("editor-outcome");
    for (let i = 0; i < outcomes.length; i++) {
      outcomes[i].classList.remove("selected-editor-outcome");
    }
    outcomeHTML.current.classList.add("selected-editor-outcome");
  }
  function dragEnd(e: any) {
    if (!locked) {
      if (!selector) {
        if (dragging) {
          dragging = false;
          let coords: Point;
          let canvas: any = document.getElementsByClassName("editor-canvas")[0];
          coords = new Point(
            ((parseFloat(outcomeHTML.current.style.left) - canvas.offsetLeft) / canvas.offsetWidth) * 135,
            ((parseFloat(outcomeHTML.current.style.top) - canvas.offsetTop) / canvas.offsetHeight) * 100
          );
          if (coords.x <= 135 && coords.y <= 100 && coords.x >= 0 && coords.y >= 0) {
            scenario.outcomes[outcomeIndex].position = coords;
          } else {
            scenario.outcomes.splice(outcomeIndex, 1);
          }
          updateScenario(scenario);
        }
      }
    }
  }

  function click() {
    if (roleOutcomesSelectorGlobal) {
      let array: string[] = [];
      if (!roleOutcomesSelectorGlobal.selectedOutcomes.includes(outcome.id)) {
        if (roleOutcomesSelectorGlobal) {
          for (let i = 0; i < roleOutcomesSelectorGlobal.selectedOutcomes.length; i++) {
            array.push(roleOutcomesSelectorGlobal.selectedOutcomes[i]);
          }
        }

        array.push(outcome.id);
        if (roleOutcomesSelectorGlobal.outcomeCategory !== "ideal") {
          for (let i = 0; i < roleOutcomesSelectorGlobal.role.idealOutcomes.length; i++) {
            if (roleOutcomesSelectorGlobal.role.idealOutcomes[i] === outcome.id) {
              roleOutcomesSelectorGlobal.role.idealOutcomes.splice(i, 1);
            }
          }
        }
        if (roleOutcomesSelectorGlobal.outcomeCategory !== "settle") {
          for (let i = 0; i < roleOutcomesSelectorGlobal.role.settleOutcomes.length; i++) {
            if (roleOutcomesSelectorGlobal.role.settleOutcomes[i] === outcome.id) {
              roleOutcomesSelectorGlobal.role.settleOutcomes.splice(i, 1);
            }
          }
        }
        if (roleOutcomesSelectorGlobal.outcomeCategory !== "failure") {
          for (let i = 0; i < roleOutcomesSelectorGlobal.role.failureOutcomes.length; i++) {
            if (roleOutcomesSelectorGlobal.role.failureOutcomes[i] === outcome.id) {
              roleOutcomesSelectorGlobal.role.failureOutcomes.splice(i, 1);
            }
          }
        }

        setRoleOutcomesSelector(new RoleOutcomesSelector(array, roleOutcomesSelectorGlobal.role, roleOutcomesSelectorGlobal.outcomeCategory));
      } else {
        if (roleOutcomesSelectorGlobal) {
          for (let i = 0; i < roleOutcomesSelectorGlobal.selectedOutcomes.length; i++) {
            if (roleOutcomesSelectorGlobal.selectedOutcomes[i] != outcome.id) {
              array.push(roleOutcomesSelectorGlobal.selectedOutcomes[i]);
            }
          }
        }
        setRoleOutcomesSelector(new RoleOutcomesSelector(array, roleOutcomesSelectorGlobal.role, roleOutcomesSelectorGlobal.outcomeCategory));
      }
      for (let i = 0; i < scenario.roles.length; i++) {
        if (scenario.roles[i] == roleOutcomesSelectorGlobal.role) {
          let tempScen: Scenario = scenario.clone();
          if (roleOutcomesSelectorGlobal.outcomeCategory === "ideal") {
            tempScen.roles[i].idealOutcomes = array;
          } else if (roleOutcomesSelectorGlobal.outcomeCategory === "settle") {
            tempScen.roles[i].settleOutcomes = array;
          } else if (roleOutcomesSelectorGlobal.outcomeCategory === "failure") {
            tempScen.roles[i].failureOutcomes = array;
          } else {
            throw Error("not gud");
          }
          updateScenario(tempScen);
        }
      }
    }
  }

  function resize() {
    let canvas: any = document.getElementsByClassName("editor-canvas")[0];
    outcomeHTML.current.style.left = canvas.offsetLeft + (outcome.position.x / 135) * canvas.offsetWidth + "px";
    outcomeHTML.current.style.top = canvas.offsetTop + (outcome.position.y / 100) * canvas.offsetHeight + "px";
    outcomeHTML.current.style.width = (outcome.width / 135) * canvas.offsetWidth + "px";
    outcomeHTML.current.style.height = (outcome.width / 135) * canvas.offsetWidth + "px";
    setRadius(parseFloat(outcomeHTML.current.style.width) / 8);
  }

  return (
    <div ref={outcomeHTML} className="editor-outcome">
      {outcome.image !== null ? (
        <Img className="editor-image" src={outcome.image}></Img>
      ) : (
        <div
          style={{
            backgroundColor: outcome.backgroundColor,
            borderRadius: radius + "px",
          }}
          className="editor-image"
        ></div>
      )}
      <div className="editor-outcome-topper-container">
        {outcome.outcomeCondition.behavior === "lock" && <Lock className="editor-outcome-topper" />}
        {outcome.outcomeCondition.behavior === "hide" && <Hidden className="editor-outcome-topper" />}
      </div>
    </div>
  );
}
