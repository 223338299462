import Img from "../components/Img";
//import Cursor from "../assets/cursor4.png";
import { useEffect, useState } from "react";
import { CursorMovedRequestData, CursorUpdateData, events } from "../ServerCommunication";
import { sendMessage } from "../functions";
import { ReactComponent as Cursor } from "../assets/svg/pointer-arrow-icon.svg";
let startTime = Date.now();

export default function MousePanel({ lobbyId }: { lobbyId: string }) {
  const [cursors, setCursors] = useState<any[]>([]);
  useEffect(() => {
    let container = document.getElementsByClassName("main-container")[0];
    container?.addEventListener("mousemove", onMouseMove);
    globalThis.globalEvents?.addEventListener(events.cursorUpdate, cursorUpdate);

    return function cleanup() {
      let container = document.getElementsByClassName("main-container")[0];
      container?.removeEventListener("mousemove", onMouseMove);
      globalThis.globalEvents?.removeEventListener(events.cursorUpdate, cursorUpdate);
    };
  }, []);

  function onMouseMove(e: any) {
    let now = Date.now();
    if (now - startTime > 50) {
      startTime = now;
      let container = document.getElementsByClassName("main-container")[0];
      let rect = container.getBoundingClientRect();
      const data = new CursorMovedRequestData(lobbyId, { x: (e.clientX - rect.x) / rect.width, y: (e.clientY - rect.y) / rect.height });
      sendMessage(data);
    }
  }

  function cursorUpdate(data: CursorUpdateData) {
    let container = document.getElementsByClassName("main-container")[0];
    let rect = container.getBoundingClientRect();
    setCursors((cursors) => {
      let temp = [...cursors];
      let isInArray: boolean = false;
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].playerId === data.playerId) {
          isInArray = true;
          temp[i].x = data.cursorPosition.x * rect.width;
          temp[i].y = data.cursorPosition.y * rect.height;
          break;
        }
      }
      if (!isInArray) {
        let player = globalThis.playersData.find((e: any) => e.playerId === data.playerId);
        if (player) {
          temp.push({
            playerId: data.playerId,
            x: data.cursorPosition.x * rect.x,
            y: data.cursorPosition.y * rect.height,
            color: player.userColor,
            username: player.username,
          });
        }
      }
      return temp;
    });
  }

  return (
    <div className="mouse-panel-container">
      {cursors.map((player: any, index: number) => {
        return <Mouse x={player.x} y={player.y} color={player.color} key={index} username={player.username} />;
      })}
    </div>
  );
}

function Mouse({ x, y, color, username }: { x: number; y: number; color: string; username: string }) {
  return (
    <div className="multi-cursor" style={{ transform: `translate(${x}px,${y}px)` }}>
      <Cursor className="multi-cursor-svg" fill={color}></Cursor>
    </div>
  );
}
