import { useState, useEffect, useRef } from "react";
import {
  events,
  GetMediaLibraryListRequestData,
  GetMediaLibraryListResponseData,
  GetMediaLibraryRequestData,
  GetMediaLibraryResponseData,
  responses,
} from "../ServerCommunication";
import { MediaData } from "../classes/Media";
import { sendMessage } from "../functions";
import useModalStore from "../stores/modalStore";
import Loader from "./Loader";
import Img from "./Img";
import { request } from "http";
export default function ImageContainer({ visible, toggleImagePanel, imageChanged }: { visible: boolean; toggleImagePanel: Function; imageChanged: Function }) {
  const [library, setLibrary] = useState<MediaData[]>([]);
  const [libraries, setLibraries] = useState<{ name: string; id: string }[]>([]);
  const [setToggleImageUploadModal] = useModalStore((state) => [state.setToggleImageUploadModal]);
  const select = useRef<any>();
  const requestFromHere = useRef<boolean>(false);
  useEffect(() => {
    globalThis.globalEvents.addEventListener(events.getMediaLibraryListResponse, getMediaLibraryListResponse);
    globalThis.globalEvents.addEventListener(events.getMediaLibraryResponse, getMediaLibraryResponse);
    globalThis.globalEvents.addEventListener("reloadImages", reloadImages);
    return function cleanup() {
      globalThis.globalEvents.removeEventListener(events.getMediaLibraryListResponse, getMediaLibraryListResponse);
      globalThis.globalEvents.removeEventListener(events.getMediaLibraryResponse, getMediaLibraryResponse);
      globalThis.globalEvents.removeEventListener("reloadImages", reloadImages);
    };
  }, []);

  /*useEffect(() => {
        console.log(libraries);
    }, [libraries]);

    useEffect(() => {
        console.log(library);
    }, [library]);*/

  function reloadImages() {
    getMediaLibraryRequest(select.current.value);
  }

  function getMediaLibraryListResponse(data: GetMediaLibraryListResponseData) {
    switch (data.response) {
      // case responses.loginRequired:
      //   popup("Login required!", "red");
      //   break;
      // case responses.serverError:
      //   popup("Server Error", "red");
      //    break;
      case responses.success:
        setLibraries(data.libraryPropList);
        if (select.current.value === "") {
          getMediaLibraryRequest(data.libraryPropList[0].id);
        }
        break;
      default:
        break;
    }
  }

  function getMediaLibraryResponse(data: GetMediaLibraryResponseData) {
    switch (data.response) {
      case responses.loginRequired:
        //    popup("Login required!", "red");
        break;
      case responses.serverError:
        //   popup("Server Error", "red");
        break;
      case responses.notFound:
        //  popup("Not Found", "red");
        break;
      case responses.success:
        if (requestFromHere.current) {
          //console.log(data);
          setLibrary(data.mediaLibrary!.mediaList);
        }
        break;
      default:
        break;
    }
    requestFromHere.current = false;
  }

  function getMediaLibraryListRequest() {
    let requestData: GetMediaLibraryListRequestData = {
      event: events.getMediaLibraryListRequest,
    };
    globalThis.socket?.send(JSON.stringify(requestData));
    requestFromHere.current = true;
  }

  function getMediaLibraryRequest(libraryId: string) {
    requestFromHere.current = true;

    setLibrary([]);
    let requestData: GetMediaLibraryRequestData = {
      event: events.getMediaLibraryRequest,
      libraryId: libraryId,
    };
    sendMessage(requestData);
  }

  useEffect(() => {
    if (visible) {
      //getAllPresetImagesRequest();
      getMediaLibraryListRequest();
    }
  }, [visible]);

  function setImage(e: any) {
    imageChanged(e.target.src);
  }

  return (
    <>
      <div className="image-container" style={visible ? { display: "flex" } : { display: "none" }}>
        <div className="image-container-title-container">
          <button className="scenario-button upload-button" onClick={() => setToggleImageUploadModal(true)}></button>
          <select ref={select} onChange={(e) => getMediaLibraryRequest(e.target.value)} className="gallery-dropdown">
            {libraries.map((library: any, index: number) => {
              return (
                <option value={library.id} key={index}>
                  {library.name}
                </option>
              );
            })}
          </select>
          <button
            className="x-button"
            onClick={(e) => {
              toggleImagePanel();
            }}
          >
            X
          </button>
        </div>

        <div className="container-images">
          {library.map((image: any, index: number) => (
            <GalleryImage image={image} index={index} key={index} setImage={setImage} />
          ))}
        </div>
      </div>
    </>
  );
}

function GalleryImage({ image, index, setImage }: { image: any; index: any; setImage: Function }) {
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <div className="container-image-container">
      {loading && <Loader />}
      <Img
        onLoad={() => setLoading(false)}
        key={index}
        className="container-image"
        src={typeof image == "string" ? image : image.url}
        onClick={(e: any) => setImage(e)}
      ></Img>
    </div>
  );
}
