enum TableEnumType {
    JOIN = "JOIN",
    LOBBY_JOIN = "LOBBY_JOIN",
    OUTCOME = "OUTCOME",
    START = "START",
    LOGIN = "LOGIN",
    REGISTER = "REGISTER",
    SCENARIO_EDIT = "SCENARIO_EDIT",
    SITE_VISIT = "SITE_VISIT"
}

export {TableEnumType}