//@ts-nocheck
import Slider from "../components/Slider";
import TextArea from "./TextArea";
import ColorOrImage from "../components/ColorOrImage";
import {
  NoOutcomeCondition,
  Outcome,
  PlayerScoreOutcomeCondition,
  Role,
  RoleScoreOutcomeCondition,
  ScoreTable,
  TeamScoreOutcomeCondition,
} from "../classes/Scenario";
import { useEffect, useRef, useState } from "react";
import Scenario from "../classes/Scenario";
import Select from "./Select";
import InputWithLabel from "./InputWithLabel";
import NumberInput from "./NumberInput";
import { ReactComponent as Lock } from "../assets/svg/lock.svg";
import { ReactComponent as Hidden } from "../assets/svg/invisible.svg";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

export default function OutcomeEditor({
  outcome,
  scenario,
  updateScenario,
  scenarios,
}: {
  outcome: Outcome;
  scenario: Scenario;
  updateScenario: Function;
  scenarios: Scenario[];
}) {
  const title: any = useRef();
  const index = getOutcomeIndex(outcome, scenario.outcomes);
  const [triggerRender, setTriggerRender] = useState<boolean>(false);

  useEffect(() => {
    title.current.value = outcome.title;
  }, [outcome]);

  function updateTitle(title: string) {
    scenario.outcomes[index].title = title;
    updateOutcome();
  }
  function updateDescription(text: string) {
    scenario.outcomes[index].description = text;
    updateOutcome();
  }
  function updateSize(value: number) {
    scenario.outcomes[index].width = value;
    scenario.outcomes[index].height = value;
    updateOutcome();
  }

  function updateColorOrImage({ color, image }: { color: string; image: string | null }) {
    if (!image) {
      scenario.outcomes[index].backgroundColor = color;
      scenario.outcomes[index].image = image;
    } else {
      scenario.outcomes[index].image = image;
    }
    updateOutcome();
  }

  function deleteOutcome() {
    let scenarioClone: Scenario = scenario.clone();
    scenarioClone.outcomes.splice(index, 1);
    updateScenario(scenarioClone);
  }
  function updateOutcome() {
    let scenarioClone: Scenario = scenario.clone();
    scenarioClone.outcomes[index] = scenario.outcomes[index];
    updateScenario(scenarioClone);
  }
  function getOutcomeIndex(outcome: Outcome, outcomes: Outcome[]) {
    for (let i = 0; i < outcomes.length; i++) {
      if (outcome.id === outcomes[i].id) return i;
    }
    return -1;
  }

  function updateEndGameText(text: string) {
    scenario.outcomes[index].endgameText = text;
    updateOutcome();
  }

  function updateChainScenario(scenarioId: string) {
    scenario.outcomes[index].chainedScenarioId = scenarioId;
    updateOutcome();
  }

  function clearOutcome() {
    scenario.outcomes[index].outcomeCondition = new NoOutcomeCondition();
    updateOutcome();
  }

  function updateOutcomeCondition(
    scoreType: string | null,
    outcomeBehavior: "lock" | "hide",
    minimumScore: number,
    selectedRole: string,
    scoreCurrency: string
  ) {
    switch (scoreType) {
      case "Role Score":
        scenario.outcomes[index].outcomeCondition = new RoleScoreOutcomeCondition(outcomeBehavior, minimumScore, scoreCurrency, selectedRole);
        break;
      case "Team Score":
        scenario.outcomes[index].outcomeCondition = new TeamScoreOutcomeCondition(outcomeBehavior, minimumScore, scoreCurrency);
        break;
      case "Player Score":
        scenario.outcomes[index].outcomeCondition = new PlayerScoreOutcomeCondition(outcomeBehavior, minimumScore, scoreCurrency);
        break;
      default:
        break;
    }
    updateOutcome();
    setTriggerRender(!triggerRender);
  }
  return (
    <div className="right-editor-container">
      <span className="right-editor-title">Edit Outcome</span>
      <input ref={title} className="input input-username" type="name" placeholder="Outcome Title" onChange={(e) => updateTitle(e.target.value)}></input>
      <ColorOrImage color={outcome.backgroundColor} image={outcome.image} colorOrImageChanged={updateColorOrImage} />
      <Slider title={"Size:"} min={6} max={50} size={outcome.height} sliderChanged={updateSize} />
      <ChainScenario
        scoreTable={scenario.scoreTable}
        scenarios={scenarios}
        updateChainScenario={updateChainScenario}
        outcome={outcome}
        roles={scenario.roles}
        updateOutcomeCondition={updateOutcomeCondition}
        clearOutcome={clearOutcome}
        key={outcome.id}
      />
      <TextArea textContent={outcome.description} textContentChanged={updateDescription} title={"Description"} placeholder="Description..." />
      <TextArea textContent={outcome.endgameText} textContentChanged={updateEndGameText} title={"End-game Text"} placeholder="End game text..." />
      <div className="delete-container">
        <button className="delete-button" onClick={deleteOutcome}>
          Delete Outcome
        </button>
      </div>
    </div>
  );
}

function ChainScenario({
  scoreTable,
  scenarios,
  updateChainScenario,
  outcome,
  roles,
  updateOutcomeCondition,
  clearOutcome,
}: {
  scoreTable: ScoreTable;
  scenarios: Scenario[];
  updateChainScenario: Function;
  outcome: Outcome;
  roles: Role[];
  updateOutcomeCondition: Function;
  clearOutcome: Function;
}) {
  const [minimumScoreDisabled, setMinimumScoreDisabled] = useState<boolean>(outcome.outcomeCondition.type === "NoOutcomeCondition" ? true : false);
  const [selectedScoreType, setSelectedScoreType] = useState<string>(getType(outcome.outcomeCondition.type));
  const [outcomeBehavior, setOutcomeBehavior] = useState<"lock" | "hide">(
    outcome.outcomeCondition.behavior === "none" ? "lock" : outcome.outcomeCondition.behavior
  );
  const [minimumScore, setMinimumScore] = useState<number>(outcome.outcomeCondition.minimumScore ? outcome.outcomeCondition.minimumScore : 0);
  const [selectedRole, setSelectedRole] = useState<string>(
    outcome.outcomeCondition.roleTitle ? outcome.outcomeCondition.roleTitle : roles.length ? roles[0].title : ""
  );
  const [selectedCurrency, setSelectedCurrency] = useState<string>(
    outcome.outcomeCondition.scoreCurrency ? outcome.outcomeCondition.scoreCurrency : scoreTable.scoreCurrencies.length ? scoreTable.scoreCurrencies[0] : ""
  );

  const firstRender = useRef<boolean>(true);

  function updateScoreType(value: string) {
    setSelectedScoreType(value);
  }

  useEffect(() => {
    if (!firstRender.current) {
      updateOutcomeCondition(selectedScoreType, outcomeBehavior, Number(minimumScore), selectedRole, selectedCurrency);
    }
  }, [outcomeBehavior, selectedScoreType, minimumScore, selectedRole, selectedCurrency]);

  useEffect(() => {
    if (!firstRender.current) {
      if (minimumScoreDisabled) {
        clearOutcome();
      } else {
        updateOutcomeCondition(selectedScoreType, outcomeBehavior, Number(minimumScore), selectedRole, selectedCurrency);
      }
    }
  }, [minimumScoreDisabled]);

  useEffect(() => {
    firstRender.current = false;
    return function cleanup() {
      firstRender.current = true;
    };
  }, []);

  return (
    <>
      <div className="right-editor checkbox-container" style={{ alignSelf: "flex-start" }}>
        {/*<InputWithLabel onChange={setChainDisabled} label={"Chain Scenario"} />*/}
        <span className="editor-title-sm">Chain Scenario</span>
      </div>
      <>
        <Select
          options={scenarios}
          type="name"
          onChange={updateChainScenario}
          valueType={"id"}
          defaultOption={outcome.chainedScenarioId}
          defaultText={"No chaining"}
        />
        <div className="minimum-score">
          <div className="right-editor checkbox-container" style={{ alignSelf: "flex-start" }}>
            <InputWithLabel
              onChange={setMinimumScoreDisabled}
              label={"Enable only if minimum score reached"}
              labelClass="small-font"
              defaultValue={minimumScoreDisabled}
            />
          </div>
          {!minimumScoreDisabled && (
            <>
              <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", width: "45%", alignItems: "center" }}>
                  <Select options={scoreTable.scoreCurrencies} onChange={setSelectedCurrency} defaultOption={outcome.outcomeCondition.scoreCurrency} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", width: "45%", alignItems: "center" }}>
                  {/*<span className="med-font">Score type</span>*/}
                  <Select
                    options={["Team Score", "Role Score", "Player Score"]}
                    onChange={updateScoreType}
                    defaultOption={getType(outcome.outcomeCondition.type)}
                  />
                </div>
              </div>
              {selectedScoreType === "Role Score" && (
                <div style={{ display: "flex", justifyContent: "space-around", width: "95%" }} className="dropdown-input">
                  <Select
                    options={roles}
                    type="title"
                    defaultOption={outcome.outcomeCondition.roleTitle ? outcome.outcomeCondition.roleTitle : roles.length ? roles[1].title : null}
                    onChange={setSelectedRole}
                    valueType="title"
                  ></Select>
                </div>
              )}
              <div className="number-input-container">
                <NumberInput
                  defaultValue={outcome.outcomeCondition.minimumScore ? outcome.outcomeCondition.minimumScore : 0}
                  label="Minimum score :"
                  labelClass="behavior-title"
                  inputClass="number-input"
                  onChange={setMinimumScore}
                />
              </div>
              <div className="behavior-container">
                <Tooltip anchorSelect="#lock-icon" className="tooltip">
                  <span className="tooltip-text">
                    A locked outcome shows as locked and does not activate when the ball lands on it unless the minimum score is reached. If the minimum score
                    has been reached, it works as a regular outcome.
                  </span>
                </Tooltip>
                <Lock
                  id="lock-icon"
                  className={`behavior ${outcomeBehavior === "lock" ? "selected" : ""}`}
                  onClick={() => {
                    setOutcomeBehavior("lock");
                  }}
                />
                <Tooltip anchorSelect="#hidden-icon" className="tooltip">
                  <span className="tooltip-text">
                    A hidden outcome is not be visible to players and does not activate when the ball lands on it until the minimum score is reached. After the
                    minimum score has been reached, it becomes visible and works as a regular outcome.
                  </span>
                </Tooltip>
                <Hidden
                  id="hidden-icon"
                  className={`behavior ${outcomeBehavior === "hide" ? "selected" : ""}`}
                  onClick={() => {
                    setOutcomeBehavior("hide");
                  }}
                />
              </div>
            </>
          )}
        </div>
      </>
    </>
  );
}

function getType(type) {
  let t = "Team Score";
  switch (type) {
    case "TeamScoreOutcomeCondition":
      t = "Team Score";
      break;
    case "RoleScoreOutcomeCondition":
      t = "Role Score";
      break;
    case "PlayerScoreOutcomeCondition":
      t = "Player Score";
      break;
    default:
      break;
  }
  return t;
}
