import { Ball } from "../classes/Ball";
import { Outcome } from "../classes/Scenario";
import { useState, useEffect, useRef } from "react";
import Events from "../Events";

export default function Slider({ title, min, max, size, sliderChanged }: { title: String; min: number; max: number; size: number; sliderChanged: Function }) {
    const input: any = useRef();
    const [sizeText, setSizeText] = useState<number>(size);
    function sliderOnChange(value: string) {
        const valueInt: number = parseInt(value);
        setSizeText(valueInt);
        sliderChanged(valueInt);
    }
    useEffect(() => {
        input.current.value = size;
        setSizeText(size);
    }, [size]);
    return (
        <>
            <div className="ball-editor-container">
                <span className="right-editor-subtitle">{title}</span>
                <span className="right-editor-subtitle">{sizeText}</span>
            </div>
            <input ref={input} className="editor-slider" type="range" min={min} max={max} onChange={(e) => sliderOnChange(e.target.value)}></input>
        </>
    );
}
