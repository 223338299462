import { useId } from "react";

export default function NumberInput({
  label = null,
  labelClass = "",
  inputClass = "",
  disabled = false,
  defaultValue,
  onChange = () => {},
}: {
  label?: string | null;
  labelClass?: string;
  inputClass?: string;
  disabled?: boolean;
  defaultValue?: string | number;
  onChange?: any;
}) {
  const id = useId();
  return (
    <>
      {label && (
        <label style={{ whiteSpace: "nowrap" }} className={`${labelClass}`} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        className={`${inputClass}`}
        type={"number"}
        name={id}
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}
      ></input>
    </>
  );
}
