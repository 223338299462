import React from "react";
import ErrorImage from "../assets/no-image.png";

const Img = React.forwardRef((props: any, ref: any) => (
  <img
    src={props.src}
    className={props.className}
    alt={props.alt}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null;
      currentTarget.src = ErrorImage;
    }}
    onLoad={props.onLoad}
    onClick={props.onClick}
    title={props.title}
    onMouseEnter={props.onMouseEnter}
    onMouseOut={props.onMouseOut}
    data-role={props.dataRole}
    ref={ref}
  ></img>
));

export default Img;
