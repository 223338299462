import { ThreeDots } from "react-loader-spinner";

export default function Connecting() {
  return (
    <div className="connecting-screen">
      <div className="connecting-text">Attempting to reconnect...</div>
      <ThreeDots color="white" wrapperClass="connecting-loader" width="100%" />
    </div>
  );
}
