import { useEffect, useState, useRef } from "react";
var timeout: any;
export default function Popup(props: any) {
    const popup: any = useRef();
    if (props.popup.color === undefined) {
        props.popup.color = "red";
    }
    useEffect(() => {
      if (props.popup.trigger) {
        timeout = setTimeout(() => {
          props.setTriggerPopup({ trigger: false, content: props.popup.content, color: props.popup.color });
        }, props.popup.duration || 2000);
      }

      if (popup.current) {
        if (props.popup.trigger) {
          popup.current.style.opacity = 1;
          popup.current.style.transform = "translate(-50%,0) scale(1)";
        } else {
          popup.current.style.opacity = 0;
          popup.current.style.transform = " translate(-50%,0) scale(0) ";
        }
      }
    }, [props.popup.trigger]);

    useEffect(() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        props.setTriggerPopup({ trigger: false, content: props.popup.content, color: props.popup.color });
      }, props.popup.duration || 2000);
    }, [props.popup.content]);

    return (
        <div className="popup" ref={popup}>
            <div className="popup-inner">
                <span style={{ color: props.popup.color }} className="popup-alert">
                    {props.popup.content}
                </span>
                {props.children}
            </div>
        </div>
    );
}
