import { useEffect, useRef, useState } from "react";

import Delete from "../assets/svg/ImageUpload/trash.svg";
import Create from "../assets/svg/ImageUpload/add.svg";
import Upload from "../assets/svg/ImageUpload/upload.svg";
import Exit from "../assets/svg/ImageUpload/exit.svg";
import Search from "../assets/svg/ImageUpload/search.svg";
import { containsOnlySpaces, popup, sendMessage } from "../functions";
import Img from "../components/Img";
import useModalStore from "../stores/modalStore";
import useImageUploadStore from "../stores/imageUploadStore";

import Compressor from "compressorjs";
import {
  ChangeMediaLibraryNameRequestData,
  ChangeMediaLibraryNameResponseData,
  CreateMediaLibraryRequestData,
  CreateMediaLibraryResponseData,
  DeleteMediaLibraryRequestData,
  DeleteMediaLibraryResponseData,
  DeleteMediaRequestData,
  events,
  GetMediaLibraryListRequestData,
  GetMediaLibraryListResponseData,
  GetMediaLibraryRequestData,
  GetMediaLibraryResponseData,
  MediaLibraryUpdateData,
  responses,
} from "../ServerCommunication";
import { MediaData, MediaLibrary } from "../classes/Media";
import Loader from "./Loader";

var dummyData = [
  { name: "Library1", id: "1", images: [2, 1, 2, 2, 4, 56, 6] },
  { name: "Library2", id: "2", images: [1, 2, 2, 4, 56, 6] },
  { name: "Library3", id: "3", images: [12, 2, 6] },
  { name: "Library4", id: "4", images: [1, 2, 1, 2, 2, 4, 56, 6, 2, 312, 31, 23, 12, 3123, 312, 31, 23, 12, 3123, 312, 31, 23, 12, 3123] },
  { name: "Library5", id: "5", images: [1, 2, 1, 2, 2, 4, 56, 4, 4, 6] },
  { name: "Library6", id: "6", images: [1, 2, 1, 2, 2, 4, 56, 4, 6] },
  { name: "Library7", id: "7", images: [1, 2, 1, 2, 2, 4, 56, 6, 4, 2, 1, 6] },
  { name: "Library8", id: "8", images: [1, 2, 1, 2, 2, 4, 56, 6] },
];

var firstIndex: number | null = null;
var secondIndex: number | null = null;
var lastLibrary: { name: string; id: string } | null = null;

export default function ImageUploadModal() {
  return (
    <div className="image-upload-container">
      <div className="image-upload-modal">
        <ImageUploadLibrary />
        <ImageUploadImages />
      </div>
    </div>
  );
}

function ImageUploadLibrary() {
  const selectedLibrary = useImageUploadStore((state) => state.selectedLibrary);
  const setSelectedLibrary = useImageUploadStore((state) => state.setSelectedLibrary);
  const setConfirmModal = useModalStore((state) => state.setConfirmModal);
  const newName = useRef<string | null>(null);
  const [libraries, setLibraries] = useState<{ name: string; id: string }[]>([]);

  const selectedLibraryId = useRef<string>("");

  const changeByName = useRef<boolean>(false);

  useEffect(() => {
    globalThis.globalEvents.addEventListener(events.createMediaLibraryResponse, createMediaLibraryResponse);
    globalThis.globalEvents.addEventListener(events.getMediaLibraryListResponse, getMediaLibraryListResponse);
    globalThis.globalEvents.addEventListener(events.deleteMediaLibraryResponse, deleteMediaLibraryResponse);
    globalThis.globalEvents.addEventListener(events.changeMediaLibraryNameResponse, changeMediaLibraryNameResponse);
    getMediaLibraryListRequest();
    return function cleanup() {
      globalThis.globalEvents.removeEventListener(events.createMediaLibraryResponse, createMediaLibraryResponse);
      globalThis.globalEvents.removeEventListener(events.getMediaLibraryListResponse, getMediaLibraryListResponse);
      globalThis.globalEvents.removeEventListener(events.deleteMediaLibraryResponse, deleteMediaLibraryResponse);
      globalThis.globalEvents.removeEventListener(events.changeMediaLibraryNameResponse, changeMediaLibraryNameResponse);
    };
  }, []);

  function changeMediaLibraryNameResponse(data: ChangeMediaLibraryNameResponseData) {
    changeByName.current = true;
    getMediaLibraryListRequest();
  }

  function deleteMediaLibraryResponse(data: DeleteMediaLibraryResponseData) {
    switch (data.response) {
      case responses.loginRequired:
        popup("Login required!", "red");
        break;
      case responses.serverError:
        popup("Server Error", "red");
        break;
      case responses.success:
        getMediaLibraryListRequest();
        break;
      case responses.unauthorized:
        popup("Unauthorized", "red");
        break;
      case responses.notFound:
        popup("Not Found", "red");
        break;
      default:
        break;
    }
  }

  function deleteMediaLibraryRequest() {
    let requestData: DeleteMediaLibraryRequestData = {
      event: events.deleteMediaLibraryRequest,
      libraryId: selectedLibraryId.current,
    };
    globalThis.socket?.send(JSON.stringify(requestData));
  }

  function createMediaLibraryResponse(data: CreateMediaLibraryResponseData) {
    switch (data.response) {
      case responses.loginRequired:
        popup("Login required!", "red");
        newName.current = null;
        break;
      case responses.serverError:
        popup("Server Error", "red");
        newName.current = null;

        break;
      case responses.success:
        getMediaLibraryListRequest();
        break;
      case responses.unauthorized:
        popup("Unauthorized", "red");
        newName.current = null;

        break;
      default:
        newName.current = null;

        break;
    }
  }

  function createLibraryRequest() {
    let rnd = Math.round(Math.random() * 100000);
    let requestData: CreateMediaLibraryRequestData = {
      event: events.createMediaLibraryRequest,
      name: "New Library " + rnd,
    };
    newName.current = "New Library " + rnd;
    globalThis.socket?.send(JSON.stringify(requestData));
  }

  function getMediaLibraryListRequest() {
    let requestData: GetMediaLibraryListRequestData = {
      event: events.getMediaLibraryListRequest,
    };
    globalThis.socket?.send(JSON.stringify(requestData));
  }

  function getMediaLibraryListResponse(data: GetMediaLibraryListResponseData) {
    switch (data.response) {
      case responses.loginRequired:
        popup("Login required!", "red");
        break;
      case responses.serverError:
        popup("Server Error", "red");
        break;
      case responses.success:
        setLibraries(data.libraryPropList);
        if (data.libraryPropList.length > 0) {
          if (newName.current) {
            let library = data.libraryPropList.find((elem) => elem.name === newName.current);
            if (library) {
              setSelectedLibrary(library);
            } else {
              if (lastLibrary) {
                setSelectedLibrary(lastLibrary);
              } else {
                setSelectedLibrary(data.libraryPropList[0]);
              }
            }
            newName.current = null;
          } else if (!changeByName.current) {
            if (lastLibrary) {
              setSelectedLibrary(lastLibrary);
            } else {
              setSelectedLibrary(data.libraryPropList[0]);
            }
          }
        } else {
          setSelectedLibrary(null);
        }
        changeByName.current = false;
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (selectedLibrary) {
      getMediaLibraryRequest(selectedLibrary.id);
      selectedLibraryId.current = selectedLibrary.id;
      lastLibrary = selectedLibrary;
    }
  }, [selectedLibrary]);

  function getMediaLibraryRequest(libraryId: string) {
    let requestData: GetMediaLibraryRequestData = {
      event: events.getMediaLibraryRequest,
      libraryId: libraryId,
    };
    globalThis.socket?.send(JSON.stringify(requestData));
  }

  return (
    <div className="image-upload-library">
      <div className="image-upload-library-header">
        <span className="image-upload-title">Libraries</span>
      </div>
      <div className="image-upload-library-content">
        <div className="image-upload-library-list">
          {libraries.map((item, index) => {
            return (
              <span
                key={index}
                className={`image-upload-library-element ${selectedLibrary && selectedLibrary.id === item.id ? "active" : ""}`}
                onClick={() => setSelectedLibrary(item)}
                data-id={item.id}
              >
                {item.name}
              </span>
            );
          })}
        </div>
      </div>
      <div className="image-upload-library-footer">
        <div
          className={`image-upload-button-container ${!selectedLibrary ? "disabled" : ""} `}
          onClick={() => {
            setConfirmModal({
              onConfirm: deleteMediaLibraryRequest,
              title: `Are you sure you want to delete the library? (${selectedLibrary.name})`,
            });
          }}
        >
          <Img src={Delete} className="image-upload-icon"></Img>
          <span className="image-upload-icon-label">Delete</span>
        </div>
        {/*<div className="image-upload-button-container">
                    <img src={Copy} className="image-upload-icon"></img>
                    <span className="image-upload-icon-label">Copy</span>
                </div>*/}
        <div className="image-upload-button-container" onClick={createLibraryRequest}>
          <Img src={Create} className="image-upload-icon"></Img>
          <span className="image-upload-icon-label">Create</span>
        </div>
      </div>
    </div>
  );
}

function ImageUploadImages() {
  const selectedLibrary = useImageUploadStore((state) => state.selectedLibrary);
  const dropArea: any = useRef();
  const dropAreaSmall: any = useRef();
  const selectedLibraryId = useRef<string>("");
  const selectedImagesToDelete = useRef<any>([]);
  const [libraryName, setLibraryName] = useState<string>("");
  const setConfirmModal = useModalStore((state) => state.setConfirmModal);
  const [uploadModal, setToggleImageUploadModal, setUploadModal] = useModalStore((state) => [
    state.uploadModal,
    state.setToggleImageUploadModal,
    state.setUploadModal,
  ]);

  const nameInput = useRef<any>();

  const newLibName = useRef<any>("");

  const [selectedImages, setSelectedImages] = useState<number[]>([]);

  const [library, setLibrary] = useState<MediaData[]>([]);

  function mediaLibraryUpdate(data: MediaLibraryUpdateData) {
    setLibrary(data.mediaLibrary.mediaList);
  }

  useEffect(() => {
    newLibName.current = libraryName;
  }, [libraryName]);

  let shift = useRef<boolean>(false);
  let control = useRef<boolean>(false);

  useEffect(() => {
    setSelectedImages([]);
    setLibrary([]);
    if (selectedLibrary) {
      selectedLibraryId.current = selectedLibrary.id;
      setLibraryName(selectedLibrary.name);
    } else {
      selectedLibraryId.current = "";
      setLibraryName("");
    }
  }, [selectedLibrary]);

  function onKeyDown(e: any) {
    if (e.key === "Shift") {
      shift.current = true;
    }
    if (e.key === "Control") {
      control.current = true;
    }
  }

  function onKeyUp(e: any) {
    if (e.key === "Shift") {
      shift.current = false;
      firstIndex = null;
      secondIndex = null;
    }
    if (e.key === "Control") {
      control.current = false;
    }
    if (e.key === "Delete" && selectedImagesToDelete.current.length > 0) {
      setConfirmModal({ onConfirm: deleteMediaRequest, title: "Are you sure you want to delete?" });
    }
    if (e.key === "Enter" && document.activeElement === nameInput.current) {
      changeMediaLibraryNameRequest(newLibName.current);
    }
  }

  function getMediaLibraryResponse(data: GetMediaLibraryResponseData) {
    switch (data.response) {
      case responses.loginRequired:
        popup("Login required!", "red");
        break;
      case responses.serverError:
        popup("Server Error", "red");
        break;
      case responses.notFound:
        popup("Not Found", "red");
        break;
      case responses.success:
        setLibrary(data.mediaLibrary!.mediaList);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    globalThis.globalEvents.addEventListener(events.mediaLibraryUpdate, mediaLibraryUpdate);
    globalThis.globalEvents.addEventListener(events.getMediaLibraryResponse, getMediaLibraryResponse);
    globalThis.globalEvents.addEventListener(events.deleteMediaResponse, deleteMediaResponse);

    if (uploadModal) {
      if (dropArea.current) {
        ["dragenter", "dragover"].forEach((eventName) => {
          dropArea.current.addEventListener(eventName, highlight, false);
        });
        ["dragleave", "drop"].forEach((eventName) => {
          dropArea.current.addEventListener(eventName, unhighlight, false);
        });

        dropArea.current.addEventListener("drop", handleDrop, false);
      }
    }

    return function cleanup() {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
      globalThis.globalEvents.removeEventListener(events.mediaLibraryUpdate, mediaLibraryUpdate);
      globalThis.globalEvents.removeEventListener(events.getMediaLibraryResponse, getMediaLibraryResponse);
      globalThis.globalEvents.removeEventListener(events.deleteMediaResponse, deleteMediaResponse);

      if (dropArea.current) {
        ["dragenter", "dragover"].forEach((eventName) => {
          dropArea.current.removeEventListener(eventName, highlight, false);
        });
        ["dragleave", "drop"].forEach((eventName) => {
          dropArea.current.removeEventListener(eventName, unhighlight, false);
        });
        dropArea.current.removeEventListener("drop", handleDrop, false);
      }
    };
  }, [uploadModal]);

  function getMediaLibraryRequest(libraryId: string) {
    let requestData: GetMediaLibraryRequestData = {
      event: events.getMediaLibraryRequest,
      libraryId: libraryId,
    };
    sendMessage(requestData);
  }

  function getMediaLibraryListRequest() {
    let requestData: GetMediaLibraryListRequestData = {
      event: events.getMediaLibraryListRequest,
    };
    globalThis.socket?.send(JSON.stringify(requestData));
  }

  function changeMediaLibraryNameRequest(libraryName: string) {
    if (containsOnlySpaces(libraryName)) {
      popup("Library name must contain valid characters", "red");
    } else if (libraryName.length > 20) {
      popup("Library name must be 20 or less characters", "red");
    } else {
      sendMessage(new ChangeMediaLibraryNameRequestData(selectedLibraryId.current, libraryName));
    }
  }

  function deleteMediaResponse(data: DeleteMediaLibraryResponseData) {
    setSelectedImages([]);
    switch (data.response) {
      case responses.success:
        getMediaLibraryRequest(selectedLibraryId.current);
        break;
      default:
        break;
    }
  }

  function deleteMediaRequest() {
    let data = new DeleteMediaRequestData(selectedImagesToDelete.current);
    sendMessage(data);
  }

  function highlight(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (dropAreaSmall.current) dropAreaSmall.current.classList.add("highlight");
  }

  function unhighlight(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (dropAreaSmall.current) dropAreaSmall.current.classList.remove("highlight");
  }

  function handleDrop(e: any) {
    e.preventDefault();
    e.stopPropagation();
    let dt = e.dataTransfer;
    let files = dt.files;
    handleFiles(files);
  }

  function handleFiles(files: any) {
    let noImages = true;
    var imageType = /image.*/;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.match(imageType)) {
        uploadFile(files[i]);
        noImages = false;
      }
    }
    if (noImages) {
      popup("No images", "red");
    } /* else if (files.length > 1) {
            setUploadModal(false);
        }*/
  }

  function uploadFile(file: any) {
    var image: any = new Image();
    var objectUrl = window.URL.createObjectURL(file);
    image.onload = function () {
      window.URL.revokeObjectURL(objectUrl);
      let w: number | undefined = undefined;
      let h: number | undefined = undefined;
      if (image.width > 500 || image.height > 500) {
        if (image.width > image.height) {
          w = 500;
        } else {
          h = 500;
        }
      }
      new Compressor(file, {
        quality: 0.2,
        convertSize: 2000,
        convertTypes: ["image/webp"],
        width: w,
        height: h,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        async success(result: any) {
          const formData: FormData = new FormData();
          formData.append("auth-token", globalThis.authToken);
          formData.append("libraryId", selectedLibraryId.current);
          //console.log(result);
          formData.append("file", result, result.name);

          let res = await fetch(globalThis.uploadServerUrl, {
            //localUpload / liveUpload
            mode: "no-cors",
            body: formData,
            method: "POST",
            headers: {
              "auth-token": "globalThis.authToken",
            },
          });
          globalThis.globalEvents.triggerEvent("reloadImages");
          setUploadModal(false);
        },
        error(err) {
          console.log(err.message);
        },
      });
    };
    image.src = objectUrl;

    /*let url = "YOUR URL HERE";
        let formData = new FormData();

        formData.append("file", file);

        fetch(url, {
            method: "POST",
            body: formData,
        })
            .then(() => {
            })
            .catch(() => {
            });*/
  }

  useEffect(() => {}, [uploadModal]);

  function selectImageRange(index: number) {
    let images: number[] = [...selectedImages];
    if (firstIndex === null) {
      firstIndex = index;
      if (images.includes(index)) {
        //let ind = images.indexOf(index);
        //images.splice(ind, 1);
      } else {
        images.push(index);
      }
      setSelectedImages(images);
    } else {
      if (secondIndex === null) {
        secondIndex = index + 1;
        if (firstIndex !== null && secondIndex !== null) {
          if (firstIndex > secondIndex) {
            for (let i = secondIndex - 1; i < firstIndex; i++) {
              if (!images.includes(i)) {
                images.push(i);
              }
            }
          } else {
            for (let i = firstIndex + 1; i < secondIndex; i++) {
              if (!images.includes(i)) {
                images.push(i);
              }
            }
          }
          firstIndex = null;
          secondIndex = null;
          setSelectedImages(images);
        }
      }
    }
  }

  function multiSelectImage(index: number) {
    let images: number[] = [...selectedImages];
    if (images.includes(index)) {
      let ind = images.indexOf(index);
      images.splice(ind, 1);
    } else {
      images.push(index);
    }
    setSelectedImages(images);
  }

  function selectImage(index: number) {
    let oldImages: number[] = [...selectedImages];
    let images: number[] = [];
    firstIndex = index;
    if (oldImages.includes(index)) {
      if (oldImages.length === 1) {
        let ind = oldImages.indexOf(index);
        oldImages.splice(ind, 1);
        setSelectedImages(oldImages);
      } else {
        images.push(index);
        setSelectedImages(images);
      }
    } else {
      images.push(index);
      setSelectedImages(images);
    }
  }

  useEffect(() => {
    selectedImagesToDelete.current = [];
    for (let i = 0; i < selectedImages.length; i++) {
      if (library[selectedImages[i]]) {
        selectedImagesToDelete.current.push(library[selectedImages[i]].id);
      }
    }
  }, [library, selectedImages]);

  return (
    <div className="image-upload-images">
      <div className="image-upload-images-header">
        <div className="images-title">
          <input
            ref={nameInput}
            type="text"
            placeholder="Library name"
            className="library-name"
            onBlur={(e) => changeMediaLibraryNameRequest(e.target.value)}
            onChange={(e) => setLibraryName(e.target.value)}
            value={libraryName}
          ></input>
        </div>
        <span
          className="images-exit"
          onClick={() => {
            setToggleImageUploadModal(false);
          }}
        >
          X
        </span>
      </div>
      <div className="image-upload-gallery">
        {!uploadModal && (
          <>
            <div className="image-upload-image-gallery">
              {library.map((item: MediaData, index: number) => {
                return (
                  <GalleryImage
                    key={index}
                    index={index}
                    selectedImages={selectedImages}
                    item={item}
                    shift={shift}
                    control={control}
                    selectImageRange={selectImageRange}
                    multiSelectImage={multiSelectImage}
                    selectImage={selectImage}
                  />
                );
              })}
              {/*selectedLibrary &&
                                selectedLibrary.images.map((item: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`image-upload-image ${selectedImages.includes(index) ? "selected" : ""}`}
                                            onClick={() => {
                                                if (shift.current && !control.current) {
                                                    selectImageRange(index);
                                                } else if (control.current && !shift.current) {
                                                    multiSelectImage(index);
                                                } else {
                                                    selectImage(index);
                                                }
                                            }}
                                        ></div>
                                    );
                                })*/}
            </div>
          </>
        )}

        {uploadModal && (
          <div ref={dropArea} className="drag-and-drop-container">
            <div className="drag-and-drop">
              <div ref={dropAreaSmall} className="drag-and-drop-small">
                <div className="drag-and-drop-upload">
                  <Img src={Upload} className="drag-and-drop-upload-icon"></Img>
                  <span>Drag and Drop files here or</span>
                  <span>or</span>
                  <input type="file" id="fileElem" multiple accept="image/*" onChange={(e) => handleFiles(e.target.files)}></input>
                  <label className="custom-file-upload" htmlFor="fileElem">
                    <Img className="upload-icon" src={Search}></Img>
                    Choose Files
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="images-footer">
          {!uploadModal ? (
            <>
              <div className="image-upload-button-container" onClick={() => setUploadModal(true)}>
                <Img src={Upload} className="image-upload-icon"></Img>
                <span className="image-upload-icon-label">Upload</span>
              </div>
              <div
                className={`image-upload-button-container ${selectedImages.length === 0 ? "disabled" : ""} `}
                onClick={() => {
                  setConfirmModal({ onConfirm: deleteMediaRequest, title: "Are you sure you want to delete?" });
                }}
              >
                <Img src={Delete} className="image-upload-icon"></Img>
                <span className="image-upload-icon-label">Delete</span>
              </div>
            </>
          ) : (
            <>
              <div className="image-upload-button-container" onClick={() => setUploadModal(false)}>
                <Img src={Exit} className="image-upload-icon"></Img>
                <span className="image-upload-icon-label">Exit</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function GalleryImage({
  index,
  selectedImages,
  item,
  shift,
  control,
  selectImageRange,
  multiSelectImage,
  selectImage,
}: {
  index: number;
  selectedImages: number[];
  item: any;
  shift: any;
  control: any;
  selectImageRange: any;
  multiSelectImage: any;
  selectImage: any;
}) {
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <div className="upload-image-container" key={index}>
      {loading && <Loader />}
      <div className={`selected-image ${selectedImages.includes(index) ? "selected" : ""}`}></div>
      <Img
        onLoad={() => {
          setLoading(false);
        }}
        key={index}
        className={`image-upload-image ${selectedImages.includes(index) ? "selected" : ""} ${selectedImages.length > 0 ? "more-selected" : ""}`}
        src={item.url}
        alt=""
        onClick={() => {
          if (shift.current && !control.current) {
            selectImageRange(index);
          } else if (control.current && !shift.current) {
            multiSelectImage(index);
          } else {
            selectImage(index);
          }
        }}
      ></Img>
    </div>
  );
}
