import { useEffect, useId, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { events, GetInvitedUsersRequestData, GetInvitedUsersResponseData, SendInviteLinkResponseData } from "../ServerCommunication";
import { popup } from "../functions";
import { inviteStatus, responses } from "../ServerCommunication";
import { useQuery, ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { GET_ALL_SITEVISITS } from "../Graphql/Querries";
import { GET_ALL_QUERY } from "../Graphql/Querries";
import useAnalyticsStore from "../stores/analyticsStore";
import { TableEnumType } from "../Graphql/EnumDef";
import { tab } from "@testing-library/user-event/dist/tab";
import { Tab } from "@mui/material";
import { constants } from "buffer";
import useModalStore from "../stores/modalStore";

export default function AdminScreen() {
  let navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [usersData, setUsersData] = useState<any[]>([]);
  const [selectedPanel, setSelectedPanel] = useState<number>(0);

  function sendInvitation() {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.sendInviteLinkRequest,
        email: email,
      })
    );
  }

  function sendInviteLinkResponse(data: SendInviteLinkResponseData) {
    switch (data.response) {
      case responses.success:
        popup(`Invitation sent to ${data.email}`, "green");
        getInvitedUsersRequest();
        break;
      default:
        popup(`Invitation failed`, "red");
        break;
    }
  }

  useEffect(() => {
    getInvitedUsersRequest();
    globalThis.globalEvents.addEventListener(events.sendInviteLinkResponse, sendInviteLinkResponse);
    globalThis.globalEvents.addEventListener(events.getInvitedUsersResponse, getInvitedUsersResponse);
    return function cleanup() {
      globalThis.globalEvents.removeEventListener(events.sendInviteLinkResponse);
      globalThis.globalEvents.removeEventListener(events.getInvitedUsersResponse);
    };
  }, []);

  function getInvitedUsersResponse(data: GetInvitedUsersResponseData) {
    setUsersData(data.invitedUserList);
  }

  function getInvitedUsersRequest() {
    let requestData: GetInvitedUsersRequestData = {
      event: events.getInvitedUsersRequest,
    };
    globalThis.socket?.send(JSON.stringify(requestData));
  }
  function convertDate(date: string) {
    return date.split("T")[0];
  }

  return (
    <div className="admin-screen-container">
      <span style={{ textAlign: "center" }}>Admin Panel</span>
      <input placeholder="invite" className="input admin-input" onChange={(e) => setEmail(e.target.value)}></input>
      <button className="login-button" onClick={sendInvitation}>
        send invite
      </button>
      <div className="admin-screen-activity-table">
        <div className="button-container">
          <button className="button" style={selectedPanel === 0 ? { textDecoration: "underline" } : {}} onClick={() => setSelectedPanel(0)}>
            Invitations
          </button>
          <button className="button" style={selectedPanel === 1 ? { textDecoration: "underline" } : {}} onClick={() => setSelectedPanel(1)}>
            Logs
          </button>
        </div>
        {selectedPanel === 0 && (
          <div className="table">
            <RegistrationTable usersData={usersData} />
          </div>
        )}
        {selectedPanel === 1 && <ActivityTable usersData={usersData} />}
      </div>

      <button
        className="login-button"
        onClick={() => {
          navigate("/menu");
        }}
      >
        Leave
      </button>
    </div>
  );
}

function RegistrationTable({ usersData }: { usersData: any }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th>Registration Date</th>
          <th>Email</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {usersData.map((item: any, index: number) => {
          return (
            <tr key={index}>
              <td>{new Date(item.inviteDate).toLocaleDateString("en-US")}</td>
              <td>{item.registerDate ? new Date(item.registerDate).toLocaleDateString("en-US") : "-"}</td>
              <td>{item.email.length > 0 ? item.email : "-"}</td>
              <td className="align-right" style={item.inviteStatus === inviteStatus.registered ? { color: "rgb(126,212,129)" } : { color: "rgb(230,65,65)" }}>
                {item.inviteStatus === inviteStatus.registered ? "Registered" : "Invited"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function ActivityTable({ usersData }: { usersData: any }) {
  //const [data, setData] = useState<any>(usersData);
  //console.log(data)
  //const data = useAnalyticsStore((state) => state.data);
  //const setData = useAnalyticsStore((state) => state.setData);
  //const [selectedLabel, setselectedLabel] = useState("")
  const [changed, setChanged] = useState<boolean>(false);
  const [displayData, setdisplayData] = useState<any>(null);
  const [filteredTables, setFilteredTables] = useState<TableEnumType[]>([]);
  const [sortitem, setsortitem] = useState<string>("");
  const [ascending, setascending] = useState<boolean>(true);
  const [filterstring, setfilterstring] = useState<string | null>(null);
  const [activeFilters, setActiveFilters] = useState<any>([]);

  const [toggleFilters, setToggleFilters] = useModalStore((state) => [state.toggleFilters, state.setToggleFilters]);

  const apolloclient = new ApolloClient({
    uri: globalThis.graphQlURL,
    cache: new InMemoryCache(),
  });

  function addRemove(inenum: TableEnumType) {
    if (filteredTables.includes(inenum)) {
      setFilteredTables(filteredTables.filter((a) => a !== inenum));
    } else {
      setFilteredTables([...filteredTables, inenum]);
    }
  }

  function CheckinHandler(label: string) {
    setActiveFilters((activeFilters: any) => {
      let temp = [...activeFilters];
      if (temp.includes(label)) {
        let ind = temp.indexOf(label);
        temp.splice(ind, 1);
      } else {
        temp.push(label);
      }
      return temp;
    });
    switch (label) {
      case "Registrations":
        addRemove(TableEnumType.REGISTER);
        break;
      case "Site visits":
        addRemove(TableEnumType.SITE_VISIT);
        break;
      case "Logins":
        addRemove(TableEnumType.LOGIN);
        break;
      case "Scenario edits":
        addRemove(TableEnumType.SCENARIO_EDIT);
        break;
      case "Game joins":
        addRemove(TableEnumType.JOIN);
        break;
      case "Lobby joins":
        addRemove(TableEnumType.LOBBY_JOIN);
        break;
      case "Games started":
        addRemove(TableEnumType.START);
        break;
      case "Game outcomes":
        addRemove(TableEnumType.OUTCOME);
        break;
    }
    setChanged(true);
  }

  const { data, loading, error, client } = useQuery(GET_ALL_QUERY, {
    skip: !changed,
    variables: {
      table: filteredTables,
    },
  });

  useEffect(() => {
    console.log("loading is... ", loading);
  }, [loading]);

  useEffect(() => {
    console.log("error is... ", error);
  }, [error]);

  useEffect(() => {
    console.log("data is... ", data);
    setdisplayData(data);
  }, [changed, data]);

  /*useEffect(()=>{
    const {data} = useQuery(GET_ALL_SITEVISITS, {
        skip: selectedLabel==""
    });
    setData(data)
  }, [selectedLabel]);*/

  function headerstringformatting(label: string) {
    let data = label == "Outcome" ? "Game Outcome" : label == "Timestamp" ? "Timestamp (UTC)" : label;
    if (sortitem !== label) {
      return "  " + data + "  ";
    } else {
      return data + (ascending ? " \\/" : " /\\");
    }
  }

  function getFormattedDate(date: any) {
    const time = date.split("T")[1].replace(".000Z", "");
    const d = date.split("T")[0];
    const t = time.split(":")[0] + ":" + time.split(":")[1];
    /*return (
            <div className="date-text">
                <span>{d}</span>
                <br />
                <span>{t}</span>
            </div>
        );*/
    return (
      <div className="date-text">
        <span>{d + "\t:\t" + t}</span>
      </div>
    );
  }

  function sorting(bywhat: string) {
    const datacopy = [...displayData.getAllAnalytics.data];
    let datacopy2;

    let firsts = bywhat !== sortitem;
    setsortitem(bywhat);
    let asc = false;

    if (firsts) {
      setascending(true);
      asc = true;
    } else {
      setascending(!ascending);
      asc = !ascending;
    }

    //console.log(bywhat)

    switch (bywhat) {
      case "Log Type":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.table > b.table ? 1 : b.table > a.table ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "Email":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.email > b.data.email ? 1 : b.data.email > a.data.email ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "ConnectionID":
        datacopy2 = datacopy.sort(
          (a, b) => (asc ? 1 : -1) * (a.data.connectionID > b.data.connectionID ? 1 : b.data.connectionID > a.data.connectionID ? -1 : 0)
        );
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "SceneID":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.sceneID > b.data.sceneID ? 1 : b.data.sceneID > a.data.sceneID ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "SceneName":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.sceneName > b.data.sceneName ? 1 : b.data.sceneName > a.data.sceneName ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "ID":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.id > b.data.id ? 1 : b.data.id > a.data.id ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "LobbyID":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.lobbyID > b.data.lobbyID ? 1 : b.data.lobbyID > a.data.lobbyID ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "Timestamp":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.timestamp > b.data.timestamp ? 1 : b.data.timestamp > a.data.timestamp ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "Outcome":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.outcome > b.data.outcome ? 1 : b.data.outcome > a.data.outcome ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "Username":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.username > b.data.username ? 1 : b.data.username > a.data.username ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "Status":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.status > b.data.status ? 1 : b.data.status > a.data.status ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
      case "IP":
        datacopy2 = datacopy.sort((a, b) => (asc ? 1 : -1) * (a.data.ip > b.data.ip ? 1 : b.data.ip > a.data.ip ? -1 : 0));
        setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
        break;
    }
  }

  function inputChangeHandler(value: any) {
    const filterstring = value.target.value;

    if (filterstring === "") {
      setdisplayData(data);
    } else {
      const datacopy = [...data.getAllAnalytics.data];
      let datacopy2 = datacopy.filter((a) => {
        if (a.table?.includes(filterstring)) {
          return true;
        }

        if (String(a.data.email).includes(filterstring)) {
          return true;
        }

        if (String(a.data.ip).includes(filterstring)) {
          return true;
        }

        if (String(a.data.sceneID).includes(filterstring)) {
          return true;
        }

        if (String(a.data.sceneName).includes(filterstring)) {
          return true;
        }

        if (String(a.data.publicSession).includes(filterstring)) {
          return true;
        }

        if (String(a.data.timestamp).includes(filterstring)) {
          return true;
        }

        if (String(a.data.outcome).includes(filterstring)) {
          return true;
        }

        if (String(a.data.username).includes(filterstring)) {
          return true;
        }

        if (String(a.data.status).includes(filterstring)) {
          return true;
        }

        if (String(a.data.connectionID).includes(filterstring)) {
          return true;
        }

        return false;
      });

      setdisplayData({ getAllAnalytics: { data: datacopy2, uniques: [...displayData.getAllAnalytics.uniques] } });
    }
  }

  return (
    <>
      <ApolloProvider client={apolloclient}>
        <>
          <div style={{ display: "flex", width: "20%" }} className="search-bar">
            <input className="search-input" type={"text"} placeholder={"Search..."} onInput={(value) => inputChangeHandler(value)} style={{ flex: 1 }}></input>
            <button
              className="search-reset refresh"
              onClick={() => {
                client?.resetStore();
              }}
            ></button>
          </div>
        </>
        {toggleFilters && <Filters checkinHandler={CheckinHandler} inputChangeHandler={inputChangeHandler} client={client} activeFilters={activeFilters} />}
        <div style={{ display: "flex", overflow: "hidden", flex: 1 }}>
          <div className="padding" style={{ overflow: "auto", flex: 1 }}>
            <table /*className="admin-screen-table"*/>
              <thead style={{ display: "table-header-group", position: "sticky", top: 0 }}>
                <tr>
                  <th id="th0" key={0} style={{ inlineSize: "min-content", whiteSpace: "pre" }}>
                    <button className="toggle-filters" onClick={(e) => setToggleFilters(true)}></button>
                    <span
                      className="table-title"
                      onClick={() => {
                        sorting("Log Type");
                      }}
                    >
                      {headerstringformatting("Log Type")}
                    </span>
                  </th>

                  {displayData &&
                    displayData.getAllAnalytics.uniques.map((item: any, index: number) => {
                      return (
                        <th
                          id={"th" + (index + 1)}
                          key={index + 1}
                          style={{ inlineSize: "min-content", whiteSpace: "pre" }}
                          onClick={() => {
                            sorting(item);
                          }}
                        >
                          {headerstringformatting(item)}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {displayData &&
                  displayData.getAllAnalytics.data.map((item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td className="special" headers="th0" key={0} style={{ inlineSize: "min-content" }}>
                          {item.table ? item.table : "-"}
                        </td>
                        {displayData.getAllAnalytics.uniques.map((col_name: any, index2: number) => {
                          switch (col_name) {
                            case "Email":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.email ? item.data.email : "-"}
                                </td>
                              );
                            case "ConnectionID":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.connectionID ? item.data.connectionID : "-"}
                                </td>
                              );
                            case "LobbyID":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.lobbyID ? item.data.lobbyID.slice(0, 5) : "-"}
                                </td>
                              );
                            case "GameRoomID":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.gameRoomID ? item.data.gameRoomID.slice(0, 5) : "-"}
                                </td>
                              );
                            case "ScenarioID":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.scenarioID ? item.data.scenarioID : "-"}
                                </td>
                              );
                            case "ScenarioName":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.scenarioName ? item.data.scenarioName : "-"}
                                </td>
                              );
                            case "ID":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.id ? item.data.id : "-"}
                                </td>
                              );
                            /*case "PublicSession":
                                                            return (
                                                                <td
                                                                    className="special"
                                                                    headers={"th" + (index2 + 1)}
                                                                    key={index2 + 1}
                                                                    style={{ inlineSize: "min-content" }}
                                                                >
                                                                    {item.data.publicSession ? item.data.publicSession : "-"}
                                                                </td>
                                                            );*/
                            case "Timestamp":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.timestamp ? getFormattedDate(item.data.timestamp) : "-"}
                                </td>
                              );
                            case "Outcome":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.outcome ? item.data.outcome : "-"}
                                </td>
                              );
                            case "Username":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.username ? item.data.username : "-"}
                                </td>
                              );
                            case "Status":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.status ? item.data.status : "-"}
                                </td>
                              );
                            case "IP":
                              return (
                                <td className="special" headers={"th" + (index2 + 1)} key={index2 + 1} style={{ inlineSize: "min-content" }}>
                                  {item.data.ip ? item.data.ip : "-"}
                                </td>
                              );
                          }
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </ApolloProvider>
    </>
  );
}

function Filters({
  checkinHandler,
  inputChangeHandler,
  client,
  activeFilters,
}: {
  checkinHandler: Function;
  inputChangeHandler: Function;
  client: ApolloClient<object>;
  activeFilters: any;
}) {
  const searchInput = useRef<any>();
  const [setToggleFilters] = useModalStore((state) => [state.setToggleFilters]);

  function reset() {
    searchInput.current.value = "";
    inputChangeHandler("");
  }

  //console.log(activeFilters);
  return (
    <div className="filters-wrapper">
      <div className="filters-container">
        <div className="filters">
          <span className="filter-title">FILTERS</span>
          <Filter defaultChecked={activeFilters.includes("Registrations")} label={"Registrations"} checkinHandler={checkinHandler} />
          <Filter defaultChecked={activeFilters.includes("Site visits")} label={"Site visits"} checkinHandler={checkinHandler} />
          <Filter defaultChecked={activeFilters.includes("Logins")} label={"Logins"} checkinHandler={checkinHandler} />
          <Filter defaultChecked={activeFilters.includes("Scenario edits")} label={"Scenario edits"} checkinHandler={checkinHandler} />
          <Filter defaultChecked={activeFilters.includes("Public game joins")} label={"Public game joins"} checkinHandler={checkinHandler} />
          <Filter defaultChecked={activeFilters.includes("Lobby joins")} label={"Lobby joins"} checkinHandler={checkinHandler} />
          <Filter defaultChecked={activeFilters.includes("Games started")} label={"Games started"} checkinHandler={checkinHandler} />
          <Filter defaultChecked={activeFilters.includes("Game outcomes")} label={"Game outcomes"} checkinHandler={checkinHandler} />
          <div className="login-button" onClick={() => setToggleFilters(false)} style={{ alignSelf: "center" }}>
            Close
          </div>
        </div>
      </div>
    </div>
  );
}

function Filter({ label, checkinHandler, defaultChecked }: { label: string; checkinHandler: Function; defaultChecked: boolean }) {
  const id = useId();
  return (
    <div className="checkbox-container">
      <input type="checkbox" id={id} name={id} onChange={() => checkinHandler(label)} defaultChecked={defaultChecked} />
      <label className="input-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
