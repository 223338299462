import { events } from "../ServerCommunication";
import { useEffect, useState, useRef } from "react";
import Scenario from "../classes/Scenario";
import Dropdown from "./Dropdown";
import useModalStore from "../stores/modalStore";
export default function SettingsPanel({
  inviteCode,
  copyToClipboard,
  startGameRequest,
  lobbyID,
  scenario,
  playersData,
}: {
  inviteCode: string;
  copyToClipboard: Function;
  startGameRequest: Function;
  lobbyID: string;
  scenario: Scenario;
  playersData: any[];
}) {
  const [scenarios, setScenarios] = useState<Array<Scenario>>([]);
  useEffect(() => {
    globalThis.globalEvents.addEventListener(events.getScenarioListResponse, getScenarioListResponse);
    getScenarioListRequest();
    return function cleanup() {
      globalThis.globalEvents.removeEventListener(events.getScenarioListResponse, getScenarioListResponse);
    };
  }, []);

  function getScenarioListResponse(data: any): void {
    setScenarios(data.scenarioPropList);
  }

  function generateUrl(): void {
    const url: URL = new URL(window.location.href);
    const clipboardURL: string = url.origin + "?invite_code=" + inviteCode;
    copyToClipboard(clipboardURL, "Invite URL copied to clipboard");
  }
  function getScenario(scenarioID: string): void {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.lobbyChangeScenarioRequest,
        scenarioID: scenarioID,
        lobbyID: lobbyID,
      })
    );
  }
  function getScenarioListRequest(): void {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.getScenarioListRequest,
      })
    );
  }

  return (
    <>
      <div className="scenario-selector-container">
        <span className="scenario-selector-title">Select Scenario</span>
        <Dropdown
          elements={scenarios}
          selected={scenario.id}
          scenario={scenario}
          filters={{ user: true, template: true }}
          getScen={getScenario}
          className="right"
        />
      </div>
      <div className="invite-code">
        <button
          className="login-button"
          onClick={(e) => {
            startGameRequest();
          }}
        >
          Start
        </button>
        <div>
          <span className="invite-code-text">Invite code:&nbsp;&nbsp;</span>
          <span className="invite-code-code" onClick={() => copyToClipboard(inviteCode, "Invite code copied to clipboard")}>
            {inviteCode}
          </span>
          <span className="invite-code-text">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <span className="invite-code-url" onClick={generateUrl}>
            URL
          </span>
        </div>
      </div>
    </>
  );
}
