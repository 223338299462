import Scenario, { ScenarioElement, scenarioElementType } from "../classes/Scenario";
import { Ball } from "../classes/Ball";
import { isMobile, getMousePos } from "../functions";
import { useRef, useEffect } from "react";
import { Point, Vector } from "../utils";

var offset: Vector;
let dragStartPosition: Point;
let startPosition: Point;

export default function EditorBall({
    scenario,
    updateScenario,
    setSelectedElement,
    locked = false,
}: {
    scenario: Scenario;
    updateScenario: Function;
    setSelectedElement: Function;
    locked: boolean;
}) {
    const ballHTML: any = useRef();
    let ball: Ball = new Ball(new Point(scenario.ball.position.x, scenario.ball.position.y), scenario.ball.radius, scenario.ball.backgroundColor);
    let dragging: boolean = false;
    useEffect(() => {
        if (scenario.ball) {
            let canvas: any = document.getElementsByClassName("editor-canvas")[0];
            ballHTML.current.style.left = canvas.offsetLeft + (ball.position.x / 135) * canvas.offsetWidth + "px";
            ballHTML.current.style.top = canvas.offsetTop + (ball.position.y / 100) * canvas.offsetHeight + "px";
            ballHTML.current.style.width = (ball.radius / 135) * canvas.offsetWidth * 2 + "px";
            ballHTML.current.style.height = (ball.radius / 135) * canvas.offsetWidth * 2 + "px";
        }
        window.addEventListener("resize", resize);
        if (isMobile.any()) {
            ballHTML.current.addEventListener("touchstart", dragStart);
            window.addEventListener("touchmove", drag);
            window.addEventListener("touchend", dragEnd);
        } else {
            ballHTML.current.addEventListener("mousedown", dragStart);
            window.addEventListener("mousemove", drag);
            window.addEventListener("mouseup", dragEnd);
        }

        return function cleanup() {
            if (ballHTML.current) {
                if (isMobile.any()) {
                    ballHTML.current.removeEventListener("touchstart", dragStart);
                    window.removeEventListener("touchmove", drag);
                    window.removeEventListener("touchend", dragEnd);
                } else {
                    ballHTML.current.removeEventListener("mousedown", dragStart);
                    window.removeEventListener("mousemove", drag);
                    window.removeEventListener("mouseup", dragEnd);
                }
            }

            window.removeEventListener("resize", resize);
        };
    }, [scenario, locked]);

    function dragStart(e: any) {
        dragging = true;
        selectElement();
        offset = new Vector(0, 0);
        startPosition = new Point(parseFloat(ballHTML.current.style.left), parseFloat(ballHTML.current.style.top));
        let mousePos = getMousePos(e);
        dragStartPosition = new Point(mousePos!.x, mousePos!.y);
    }

    function drag(e: any) {
        if (!locked) {
            if (dragging) {
                if (ballHTML.current) {
                    offset = new Vector(globalThis.mousePos.x - dragStartPosition.x, globalThis.mousePos.y - dragStartPosition.y);
                    //console.log("mouseposx:", globalThis.mousePos.x);

                    ballHTML.current.style.left = startPosition.x + offset.x + "px";
                    ballHTML.current.style.top = startPosition.y + offset.y + "px";
                }
            }
        }
    }

    function dragEnd(e: any) {
        if (!locked) {
            if (dragging) {
                dragging = false;
                let coords: Point;
                let canvas: any = document.getElementsByClassName("editor-canvas")[0];
                coords = new Point(
                    ((parseFloat(ballHTML.current.style.left) - canvas.offsetLeft) / canvas.offsetWidth) * 135,
                    ((parseFloat(ballHTML.current.style.top) - canvas.offsetTop) / canvas.offsetHeight) * 100
                );
                if (coords.x <= 135 && coords.y <= 100 && coords.x >= 0 && coords.y >= 0) {
                    scenario.ball.position = coords;
                }

                updateScenario(scenario);
            }
        }
    }

    function selectElement() {
        ballHTML.current.classList.add("selected-editor-outcome");
        let selectedElement = new ScenarioElement(ball, scenarioElementType.ball);
        setSelectedElement(selectedElement);
    }

    function resize() {
        let canvas: any = document.getElementsByClassName("editor-canvas")[0];
        ballHTML.current.style.left = canvas.offsetLeft + (ball.position.x / 135) * canvas.offsetWidth + "px";
        ballHTML.current.style.top = canvas.offsetTop + (ball.position.y / 100) * canvas.offsetHeight + "px";
        ballHTML.current.style.width = (ball.radius / 135) * canvas.offsetWidth * 2 + "px";
        ballHTML.current.style.height = (ball.radius / 135) * canvas.offsetWidth * 2 + "px";
    }
    return (
        <div ref={ballHTML} className="editor-ball">
            <div style={{ backgroundColor: scenario.ball.backgroundColor }} className="editor-ball-visual"></div>
        </div>
    );
}
