import { Vector, Point } from "../utils";
export class BallVF {
  //ball Visual Frame
  ball: Ball;

  position: Point;
  radius: number;
  v: Vector;
  frictionF: number;

  canvas: HTMLCanvasElement;
  constructor(ball: Ball, canvas: HTMLCanvasElement) {
    this.ball = ball;
    this.canvas = canvas;
    this.position = this.convertPointAbsoluteToScreen(ball.position);
    this.v = this.convertVectorAbsoluteToScreen(ball.v);
    this.frictionF = ball.frictionF * this.canvas.height;
    this.radius = (ball.radius / 100) * this.canvas.height;
  }
  setBall(ball: Ball) {
    this.ball = ball;
    this.position = this.convertPointAbsoluteToScreen(ball.position);
    this.v = this.convertVectorAbsoluteToScreen(ball.v);
    this.frictionF = ball.frictionF * this.canvas.height;
    this.radius = (ball.radius / 100) * this.canvas.height;
  }
  setPositionScreen(positionScreen: Point) {
    //sets the position based on screen coordinates (updates corresponding absolute coordinates as well)
    this.position = positionScreen.clone();
    this.ball.position = this.convertPointScreenToAbs(this.position);
  }
  setPositionAbs(positionAbs: Point) {
    //sets the position based on absolute coordinates (updates corresponding screen coordinates as well)
    this.ball.position = positionAbs.clone();
    this.position = this.convertPointAbsoluteToScreen(this.ball.position);
  }
  setForceScreen(forceScreen: Vector) {
    //sets the force vector based on screen coordinates (updates corresponding absolute vector as well)
    this.v = forceScreen.clone();
    this.ball.v = this.convertVectorScreenToAbs(this.v);
  }
  setForceAbs(forceAbs: Vector) {
    //sets the force vector based on absolute coordinates (updates corresponding screen vector as well)
    this.ball.v = forceAbs.clone();
    //console.log("FORCEABS:", this.ball.v);
    //console.log("ball force changed to:", forceAbs);
    this.v = this.convertVectorAbsoluteToScreen(this.ball.v);
  }

  resize(canvas: any) {
    this.canvas = canvas;
    this.setBall(this.ball);
    this.frictionF = this.ball.frictionF * this.canvas.height;
    this.radius = (this.ball.radius / 100) * this.canvas.height;
  }
  reset() {}

  convertPointAbsoluteToScreen(pointAbs: Point) {
    return new Point((pointAbs.x / 135) * this.canvas.width, (pointAbs.y * this.canvas.height) / 100);
  }
  convertPointScreenToAbs(pointScreen: Point) {
    return new Point((pointScreen.x / this.canvas.width) * 135, (pointScreen.y / this.canvas.height) * 100);
  }
  convertVectorAbsoluteToScreen(vectorAbs: Vector) {
    return new Vector((vectorAbs.x / 135) * this.canvas.width, (vectorAbs.y * this.canvas.height) / 100);
  }
  convertVectorScreenToAbs(vectorScreen: Vector) {
    return new Vector((vectorScreen.x / this.canvas.width) * 135, (vectorScreen.y / this.canvas.height) * 100);
  }
  update(dt: number) {
    this.ball.update(dt);
    this.position = this.convertPointAbsoluteToScreen(this.ball.position);
    this.v = this.convertVectorAbsoluteToScreen(this.ball.v);
  }
  draw(ctx: CanvasRenderingContext2D) {
    ctx.beginPath();
    ctx.fillStyle = this.ball.backgroundColor;
    ctx.shadowColor = "rgba(0,0,0,0.5)";
    ctx.shadowBlur = 5;
    ctx.shadowOffsetX = this.radius / 20;
    ctx.shadowOffsetY = this.radius / 20;
    ctx.arc(this.position.x, this.position.y, this.radius, 0, Math.PI * 2);
    ctx.fill(); // fill() is to fill in the circle, stroke() is for a empty circle.*/*/
  }
}
export class Ball {
  position: Point;
  radius: number;
  v: Vector;
  frictionF: number;
  backgroundColor: string;

  accumdx: number;
  accumdy: number;
  accumt: number;
  printonce: boolean;
  constructor(position: Point, radius: number, backgroundColor: string = "#f2523d", frictionF = 17) {
    this.position = new Point(position.x, position.y);
    this.radius = radius;
    this.v = new Vector(0, 0);
    this.frictionF = frictionF;
    this.backgroundColor = backgroundColor;
    this.accumdx = 0;
    this.accumdy = 0;
    this.accumt = 0;
    this.printonce = false;
  }
  //applyForce(force: Vector) {
  //    this.force.x = force.x;
  //    this.force.y = force.y;
  //}
  update(dt: number) {
    let v_0 = this.v.clone();
    let v_final: Vector;

    let v_magnitude = this.v.length();
    let v_u = this.v.getUnitVector();
    let dx: number;
    let dy: number;
    let v_u2 = v_u.clone();
    v_u2.multiplyByScalar(this.frictionF);
    let frictionForceVector = v_u2;

    let newVMagnitude = v_magnitude - this.frictionF * dt;
    v_u.multiplyByScalar(newVMagnitude);
    v_final = v_u;
    if (Math.abs(this.v.x) > Math.abs(frictionForceVector.x * dt)) {
      dx = dt * v_0.x - (frictionForceVector.x * dt * dt) / 2;
    } else {
      //don't overshoot, ball was supposed to stop before the time this function got called
      let t = Math.abs(this.v.x / frictionForceVector.x);
      if (t > 0) {
        dx = t * v_0.x - (frictionForceVector.x * t * t) / 2;
        //this.accumt += t2;
      } else {
        dx = 0;
      }
      v_final.x = 0;
    }
    if (Math.abs(this.v.y) > Math.abs(frictionForceVector.y * dt)) {
      dy = dt * v_0.y - (frictionForceVector.y * dt * dt) / 2;
    } else {
      //don't overshoot, ball was supposed to stop before the time this function got called
      let t = Math.abs(this.v.y / frictionForceVector.y);
      if (t > 0) {
        dy = t * v_0.y - (frictionForceVector.y * t * t) / 2;
        //this.accumt += t2;
      } else {
        dy = 0;
      }
      v_final.y = 0;
    }

    this.accumdx += dx;
    this.accumdy += dy;

    //this logic only accounts well for one bounce, not intended for more complex trajectory calculation with large t's (t>1 or large v)
    let predictedPosition = new Point(this.position.x + dx, this.position.y + dy);
    if (predictedPosition.x - this.radius < 0) {
      // console.log(dx)
      // console.log('before:',this.position.x)
      this.position.x = this.radius + Math.abs(Math.abs(dx) - (this.position.x - this.radius));
      // console.log('after:',this.position.x)
      v_final.x = -v_final.x;
    } else if (predictedPosition.x + this.radius > 135) {
      this.position.x = 135 - this.radius - Math.abs(Math.abs(dx) - (135 - (this.position.x + this.radius)));
      v_final.x = -v_final.x;
    } else {
      this.position.x += dx;
    }
    if (predictedPosition.y - this.radius < 0) {
      this.position.y = this.radius + Math.abs(Math.abs(dy) - (this.position.y - this.radius));
      v_final.y = -v_final.y;
    } else if (predictedPosition.y + this.radius > 100) {
      this.position.y = 100 - this.radius - Math.abs(Math.abs(dy) - (100 - (this.position.y + this.radius)));
      v_final.y = -v_final.y;
    } else {
      this.position.y += dy;
    }

    //this.position = predictedPosition;
    this.v = v_final;
    if (this.v.length() > 0) {
      this.printonce = true;
    }
    if (this.v.length() === 0 && this.printonce) {
      this.printonce = false;
      // console.log("dt:", dt);
      // console.log("v:", this.v.x);
      // console.log("accumdx", this.accumdx);
      // console.log("accumdy", this.accumdy);
      // console.log("accumt", this.accumt);
    }
  }
}
