import create from "zustand";

interface State {
    selectedLibrary: any;
    setSelectedLibrary: (selectedLibrary: any) => void;
}

const useImageUploadStore = create<State>((set, get) => ({
    selectedLibrary: null,
    setSelectedLibrary: (selectedLibrary) => set({ selectedLibrary }),
}));

export default useImageUploadStore;
