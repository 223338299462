import create from "zustand";
import { Outcome, ScoreTable } from "../classes/Scenario";
import { ScoreBoard } from "../classes/ScoreBoard";
interface State {
  confirmModal: null | { cancelText?: string; confirmText?: string; title?: string; onConfirm?: Function; text?: string };
  setConfirmModal: (confirmModal: null | { cancelText?: string; confirmText?: string; title?: string; onConfirm?: Function; text?: string }) => void;

  toggleImageUploadModal: boolean;
  setToggleImageUploadModal: (toggleImageUploadModal: boolean) => void;

  uploadModal: boolean;
  setUploadModal: (uploadModal: boolean) => void;

  uploading: boolean;
  setUploading: (uploading: boolean) => void;

  toggleScoreModal: boolean;
  setToggleScoreModal: (toggleScoreModal: boolean) => void;

  toggleFilters: boolean;
  setToggleFilters: (toggleFilters: boolean) => void;

  endGameModal: Outcome | null;
  setEndGameModal: (endGameModal: Outcome | null) => void;

  teamScore: number;
  setTeamScore: (teamScore: number) => void;

  scores: any;
  setScores: (scores: any) => void;

  lobbyScores: ScoreBoard | null;
  setLobbyScores: (lobbyScores: ScoreBoard | null) => void;

  selectedElement: any;
  setSelectedElement: (selectedElement: any) => void;
}

const useModalStore = create<State>((set, get) => ({
  confirmModal: null,
  setConfirmModal: (confirmModal) => set({ confirmModal }),

  toggleImageUploadModal: false,
  setToggleImageUploadModal: (toggleImageUploadModal) => {
    let { uploadModal, setUploadModal } = get();
    if (uploadModal) {
      setUploadModal(false);
    }
    set({ toggleImageUploadModal });
  },

  uploadModal: false,
  setUploadModal: (uploadModal) => set({ uploadModal }),

  uploading: false,
  setUploading: (uploading) => set({ uploading }),

  toggleScoreModal: false,
  setToggleScoreModal: (toggleScoreModal) => set({ toggleScoreModal }),

  toggleFilters: true,
  setToggleFilters: (toggleFilters) => set({ toggleFilters }),

  endGameModal: null,
  setEndGameModal: (endGameModal) => set({ endGameModal }),

  teamScore: 0,
  setTeamScore: (teamScore) => set({ teamScore }),

  scores: null,
  setScores: (scores: ScoreTable) => set({ scores }),

  lobbyScores: null,
  setLobbyScores: (lobbyScores: ScoreBoard | null) => set({ lobbyScores }),

  selectedElement: null,
  setSelectedElement: (selectedElement: any) => set({ selectedElement }),
}));

export default useModalStore;
