import { useEffect } from "react";
interface IEvent {
  /** element of the event */
  el: any;
  /** Name of the event */
  name: string;
  /** Callback function */
  cb: Function;
}

export default function useEvents(events: IEvent[]) {
  useEffect(() => {
    for (let i = 0; i < events.length; i++) {
      events[i].el.addEventListener(events[i].name, events[i].cb);
    }
    return function cleanup() {
      for (let i = 0; i < events.length; i++) {
        events[i].el.removeEventListener(events[i].name, events[i].cb);
      }
    };
  }, [events]);
}
