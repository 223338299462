import { useEffect, useId, useState } from "react";

export default function InputWithLabel({
  label,
  onChange,
  labelClass = "",
  inputClass = "",
  defaultValue = false,
}: {
  label: string;
  onChange: Function;
  labelClass?: string;
  inputClass?: string;
  defaultValue?: boolean;
}) {
  const [checked, setChecked] = useState<boolean>(defaultValue);
  const id = useId();

  useEffect(() => {
    onChange(checked);
  }, [checked]);

  useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <input
        className={`right-editor-checkbox ${inputClass}`}
        type="checkbox"
        id={id}
        name={id}
        onChange={(e: any) => {
          setChecked(!e.target.checked);
        }}
        checked={!checked}
      />
      <label className={`right-editor-subtitle ${labelClass}`} htmlFor={id}>
        {label}
      </label>
    </>
  );
}
