import {gql} from '@apollo/client';
import { TableEnumType } from './EnumDef';


//createUser a szerveren definált
export const GET_ALL_BASEANALYTICS = gql`

    query getAllBaseAnalytics{
        getAllBaseAnalytics{
            id,
            email,
            event,
            timestamp
        }
    }
`;

export const GET_ALL_SITEVISITS = gql`

    query getAllSiteVisitsAnalytics{
        getAllSiteVisitsAnalytics{
            id, connectionID, ip
        }
    }

`

export const GET_ALL_QUERY = gql`

    query getAllAnalytics($table:[TableEnumType]!, $id:ID, $email:String, $connectionID:String, $ip:String, $scenarioID:String, $scenarioName:String, $publicSession:Boolean, $timestamp:Date, $outcome:String, $username:String, $status:String){
        getAllAnalytics(table:$table, id:$id, email:$email, connectionID:$connectionID, ip:$ip, scenarioID:$scenarioID, scenarioName:$scenarioName, publicSession:$publicSession, timestamp:$timestamp, outcome:$outcome, username:$username, status:$status){
            
            uniques,

            data{
                table,
                data{
                    ... on SiteVisitsAnalytics{
                        id, connectionID, ip, timestamp
                    },

                    ... on SceneEditAnalytics{
                        id, email, status, scenarioID, scenarioName, timestamp
                    },

                    ... on RegisterAnalytics{
                        id, email, status, timestamp
                    },

                    ... on LoginAnalytics{
                        id, email, username, timestamp
                    },

                    ... on GameStartAnalytics{
                        id, email, scenarioID, scenarioName, gameRoomID, timestamp
                    },

                    ... on GameOutcomeEndAnalytics{
                        id, outcome, scenarioID, scenarioName, gameRoomID, timestamp
                    },

                    ... on GameJoinAnalytics{
                        id, username, scenarioID, scenarioName, gameRoomID, timestamp
                    },

                    ... on LobbyJoinAnalytics{
                        id, username, lobbyID, timestamp
                    }
                }
            }
        }
    }

`

/*export const GET_ALL_ANALYTICS_BY_FILTER = gql`

    query getAllSiteVisitsAnalytics($filter: String!){
        getAllSiteVisitsAnalytics{
            id, email, ip
        }
    }

`*/