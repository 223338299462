import TextArea from "./TextArea";
import ColorOrImage from "../components/ColorOrImage";
import RoleOutcomes from "../components/RoleOutcomes";
import { Role } from "../classes/Scenario";
import { useEffect, useRef } from "react";
import Scenario, { RoleOutcomesSelector } from "../classes/Scenario";

export default function RoleEditor({
  role,
  scenario,
  updateScenario,
  setRoleOutcomesSelector,
  roleOutcomesSelector,
}: {
  role: Role;
  scenario: Scenario;
  updateScenario: Function;
  setRoleOutcomesSelector: Function;
  roleOutcomesSelector: RoleOutcomesSelector | null;
}) {
  const title: any = useRef();
  const index = getRoleIndex(role, scenario.roles);
  useEffect(() => {
    title.current.value = role.title;
  }, [role]);

  function updateTitle(title: string) {
    role.title = title;
    updateRole();
  }

  function getRoleIndex(role: Role, roles: Role[]) {
    for (let i = 0; i < roles.length; i++) {
      if (role === roles[i]) return i;
    }
    return -1;
  }
  function deleteRole() {
    let scenarioClone: Scenario = scenario.clone();
    scenarioClone.roles.splice(index, 1);
    updateScenario(scenarioClone);
  }
  function updateRole() {
    let scenarioClone: Scenario = scenario.clone();
    scenarioClone.roles[index] = role;
    updateScenario(scenarioClone);
  }

  function updateColorOrImage({ color, image }: { color: string; image: string | null }) {
    if (!image) {
      scenario.roles[index].backgroundColor = color;
      scenario.roles[index].image = image;
    } else {
      scenario.roles[index].image = image;
    }
    updateRole();
  }

  function updateDescription(text: string) {
    scenario.roles[index].description = text;
    updateRole();
  }
  return (
    <div className="right-editor-container">
      <span className="right-editor-title">Edit Role</span>
      <input ref={title} className="input input-username" type="name" placeholder="Role Title" onChange={(e) => updateTitle(e.target.value)}></input>
      <ColorOrImage color={role.backgroundColor} image={role.image} colorOrImageChanged={updateColorOrImage} />
      {/*<RoleOutcomes scenario={scenario} role={role} setRoleOutcomesSelector={setRoleOutcomesSelector} roleOutcomesSelector={roleOutcomesSelector} />*/}
      <TextArea textContent={role.description} textContentChanged={updateDescription} placeholder="Description..." title={"Description"} />
      <div className="delete-container">
        <button className="delete-button" onClick={deleteRole}>
          Delete Role
        </button>
      </div>
    </div>
  );
}
/*<ColorOrImage />*/
/* <Description />*/
