import Scenario from "../classes/Scenario";
import Slider from "./Slider";
export default function PhysicsSettings({ scenario, updateScenario }: { scenario: Scenario; updateScenario: Function }) {
    function updateForce(force: string) {
        let f: number = parseInt(force);
        let tempScenario: Scenario = scenario.clone();
        tempScenario.settings.maxForce = f;
        updateScenario(tempScenario);
    }

    function updateFriction(friction: string) {
        let f: number = parseInt(friction);
        let tempScenario: Scenario = scenario.clone();
        tempScenario.ball.frictionF = f;
        updateScenario(tempScenario);
    }
    return (
        <div className="settings-container">
            <span className="right-editor-title">Physics</span>
            <Slider title="Surface friction:" min={3} max={50} size={scenario.ball.frictionF} sliderChanged={updateFriction} />
            <Slider title="Maximum force:" min={5} max={100} size={scenario.settings.maxForce} sliderChanged={updateForce} />
        </div>
    );
}
