import Img from "./Img";
import useModalStore from "../stores/modalStore";
import CountUp from "react-countup";
import { Fragment, useEffect, useRef } from "react";
export default function EndScreen() {
  const [endGameModal, setEndGameModal, scores, setScores] = useModalStore((state) => [
    state.endGameModal,
    state.setEndGameModal,
    state.scores,
    state.setScores,
  ]);
  return (
    <>
      {endGameModal && (
        <div className="end-screen">
          <div style={{ display: "flex", width: "100%", flexDirection: "column-reverse" }}>
            {scores && (
              <>
                <TeamScores teamScore={scores.teamScore} teamScoreGain={scores.teamScoreGain} />
                <div
                  className="animated-button lg back-to-lobby"
                  onClick={(e) => {
                    setEndGameModal(null);
                    setScores(null);
                  }}
                >
                  Back to lobby
                </div>
                <div className="card-container">
                  <>
                    {scores.rolesPlayedInSession.map((role: any, index: number) => {
                      const players = scores.playersDataDisplay.filter((e: any) => role.id === e.selectedRoleId) || [];
                      return (
                        <Fragment key={index}>
                          {players.length > 0 && (
                            <div
                              className="end-screen-score border"
                              style={{ textAlign: "center", backgroundColor: `${role.backgroundColor}40`, borderColor: role.backgroundColor }}
                              key={index}
                            >
                              {role.image ? (
                                <div>
                                  <img className="end-screen-role" src={role.image}></img>
                                </div>
                              ) : (
                                <div className="end-screen-role round" style={{ backgroundColor: role.backgroundColor }}></div>
                              )}
                              <div className="end-game-tt">
                                <div className="end-screen-score truncate" style={{ color: role.backgroundColor, margin: 0 }} title={role.title}>
                                  {role.title}
                                </div>
                                {Object.keys(scores.roleScores).map((score, index) => {
                                  let s = scores.roleScoreGains[score][role.title];
                                  if (s === undefined) {
                                    s = 0;
                                  }
                                  return (
                                    <div key={index}>
                                      <span style={{ color: role.backgroundColor, margin: 0 }}>{score} : </span>
                                      <CountUp
                                        start={scores.roleScores[score][role.title] - scores.roleScoreGains[score][role.title]}
                                        end={scores.roleScores[score][role.title]}
                                        className="end-screen-score lg"
                                        style={{ color: role.backgroundColor, padding: 0, margin: 0 }}
                                      />
                                      <span className="end-screen-score lg" style={{ color: role.backgroundColor, margin: 0 }}>
                                        {s > 0 ? `(+${s})` : `(${s})`}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="end-screen-separator" style={{ borderColor: role.backgroundColor }}></div>
                              <div>
                                {players.map((player: any, index: number) => {
                                  return (
                                    <div style={{ textAlign: "center" }} key={`${index}-player`}>
                                      <div className="end-screen-score truncate" style={{ color: player.userColor, margin: 0 }} title={player.username}>
                                        {player.username}
                                      </div>
                                      {Object.keys(scores.roleScores).map((score, index) => {
                                        let s = scores.roleScoreGains[score][role.title];
                                        if (s === undefined) {
                                          s = 0;
                                        }
                                        return (
                                          <div key={index}>
                                            <span style={{ color: player.userColor, margin: 0 }}>{score} : </span>
                                            <CountUp
                                              start={scores.playerScores[score][player.playerId] - scores.playerScoreGains[score][player.playerId]}
                                              end={scores.playerScores[score][player.playerId]}
                                              style={{ color: player.userColor, margin: 0, padding: 0 }}
                                              className="end-screen-score lg"
                                            />
                                            <span className="end-screen-score lg" style={{ color: player.userColor, margin: 0 }}>
                                              {s > 0 ? `(+${s})` : `(${s})`}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                  </>
                  {/*Object.keys(scores.playerScores).map((key) => {
                  const player = scores.playersDataDisplay.find((e: any) => e.playerId === key);
                  const username = player?.username || "";
                  console.log(player);
                  return (
                    <div className="end-screen-score" style={{ textAlign: "left" }}>
                      {username} score: +
                      <CountUp start={scores.playerScores[key] - scores.playerScoreGains[key]} end={scores.playerScores[key]} className="end-screen-score" />
                      (+{scores.playerScoreGains[key]})
                    </div>
                  );
                })*/}
                </div>
              </>
            )}
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", flex: 1 }}>
              <div className="end-screen-winner-container">
                {endGameModal.image ? (
                  <Img className="end-screen-image" src={endGameModal.image}></Img>
                ) : (
                  <div className="end-screen-image" style={{ backgroundColor: endGameModal.backgroundColor }}></div>
                )}
                <div className="end-screen-title">{endGameModal.title}</div>
              </div>
              <div className="end-screen-description">{endGameModal.endgameText ? endGameModal.endgameText : ""}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function TeamScores({ teamScore, teamScoreGain }: any) {
  const teamScoreRef = useRef<any>();
  const scores = useRef<any>();

  useEffect(() => {
    if (teamScoreRef.current) {
      teamScoreRef.current.addEventListener("mouseenter", onMouseEnter);
      teamScoreRef.current.addEventListener("mouseleave", onMouseLeave);
    }
    return function cleanup() {
      if (teamScoreRef.current) {
        teamScoreRef.current.removeEventListener("mouseenter", onMouseEnter);
        teamScoreRef.current.removeEventListener("mouseleave", onMouseLeave);
      }
    };
  }, []);

  function onMouseEnter() {
    scores.current.style.display = "flex";
  }
  function onMouseLeave() {
    scores.current.style.display = "none";
  }
  return (
    <>
      {Object.keys(teamScore).length > 0 && (
        <div className="team-scores" ref={teamScoreRef}>
          Show Team Scores
          <div ref={scores} className="team-scores-box">
            {Object.keys(teamScore).length > 0 && (
              <>
                <span style={{ textAlign: "center" }}>Team Scores</span>
                <hr className="team-score-separator"></hr>
              </>
            )}

            {Object.keys(teamScore).map((score, index) => {
              let s = teamScoreGain[score];
              if (s === undefined) {
                s = 0;
              }
              return (
                <div style={{ display: "flex", justifyContent: "space-between" }} key={index}>
                  <span className="score-name" style={{ whiteSpace: "nowrap" }}>
                    {score}
                  </span>
                  <div style={{ whiteSpace: "nowrap" }}>
                    <span>{teamScore[score]}</span>
                    <span> {s > 0 ? `(+${s})` : `(${s})`}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
