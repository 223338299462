import SplashIcon from "../components/SplashIcon";
import Assets from "../assets";
import ImageContainer from "../components/ImageContainer";
import { useState } from "react";
import Img from "../components/Img";

export default function ColorOrImage({ color, image, colorOrImageChanged }: { color: string; image: string | null; colorOrImageChanged: Function }) {
    const [showImages, setShowImages] = useState<boolean>(false);
    let imageOpacity: number = image == null ? 0.5 : 1;
    let colorOpacity: number = image == null ? 1 : 0.5;

    function toggleImagePanel() {
        setShowImages(!showImages);
    }
    function colorChanged(color: string) {
        colorOrImageChanged({ image: null, color: color });
        imageOpacity = 0.5;
        colorOpacity = 1;
    }
    function imageChanged(image: string) {
        colorOrImageChanged({ image: image, color: color });
        imageOpacity = 1;
        colorOpacity = 0.5;
    }

    function deleteImage() {
        colorOrImageChanged({ image: null, color: color });
        imageOpacity = 0.5;
        colorOpacity = 1;
    }

    return (
        <div className="outcome-color-image-container">
            <div className="vertical-flex" style={{ opacity: colorOpacity }}>
                <span>Color</span>
                <SplashIcon color={color} colorChanged={colorChanged}></SplashIcon>
            </div>
            <span className="or">-or-</span>
            <div className="vertical-flex">
                <div className="vertical-flex" style={{ opacity: imageOpacity }}>
                    <span style={{ alignSelf: "flex-start" }}>Image</span>
                    <div style={{ display: "flex" }}>
                        <Img className="image-placeholder" src={image ? image : Assets.imagePlaceholder} onClick={toggleImagePanel}></Img>
                        <div className="editor-icon-buttons">
                            <button className="image-edit-button trash" onClick={deleteImage}></button>
                            <button className="image-edit-button edit" onClick={toggleImagePanel}></button>
                        </div>
                    </div>
                </div>
            </div>
            <ImageContainer visible={showImages} toggleImagePanel={toggleImagePanel} imageChanged={imageChanged} />
        </div>
    );
}
