import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { events, responses } from "../ServerCommunication";
import { Divider } from "@mui/material";

export default function MenuScreen() {
  let navigate = useNavigate();
  useEffect(() => {
    globalThis.globalEvents.addEventListener(events.hostLobbyResponse, hostGameResponse);
    return function cleanup() {
      globalThis.globalEvents.removeEventListener(events.hostLobbyResponse);
    };
  }, []);
  function hostGameResponse(data: any) {
    switch (data.response) {
      case responses.success:
        navigate("/lobby", { state: { inviteCode: data.inviteCode, lobbyID: data.lobbyID } });
        break;
      default:
        break;
    }
  }

  function hostGameRequest() {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.hostLobbyRequest,
      })
    );
  }

  function openScenarioEditor() {
    navigate("/editor");
  }

  function openAdminPanel() {
    navigate("/admin-panel");
  }

  function onLeave() {
    globalThis.superAdmin = false;
    navigate("/");
  }

  return (
    <>
      <div className="menu-container">
        <span style={{ textAlign: "center" }}>Menu</span>
        <button className="login-button" onClick={openScenarioEditor}>
          Scenario Editor
        </button>
        <hr className="separator" />
        <button className="login-button" onClick={hostGameRequest}>
          Host Game
        </button>

        {globalThis.superAdmin && (
          <>
            <hr className="separator" />
            <button className="login-button" onClick={openAdminPanel}>
              Admin panel
            </button>
          </>
        )}
        <hr className="separator" />
        <button className="login-button" onClick={onLeave}>
          Leave
        </button>
      </div>
    </>
  );
}
