import { events } from "../ServerCommunication";
import { useEffect, useState } from "react";
import Template from "../assets/svg/template.svg";
import Portrait from "../assets/svg/portrait.svg";

import Dropdown from "../components/Dropdown";
import Scenario from "../classes/Scenario";
import LockOverlay from "./LockOverlay";
import useModalStore from "../stores/modalStore";

import Upload from "../assets/svg/ImageUpload/upload.svg";
import Img from "./Img";

export default function ScenarioSelector({
    selectedScenarioID,
    scenarios,
    setScenario,
    deleteScenario,
    createScenario,
    duplicateScenario,
    scenario,
    locked = false,
}: {
    selectedScenarioID: string | null;
    scenarios: Array<any>;
    setScenario: Function;
    deleteScenario: Function;
    createScenario: Function;
    duplicateScenario: Function;
    scenario: Scenario;
    locked: boolean;
}) {
    const [setConfirmModal, setToggleImageUploadModal] = useModalStore((state) => [state.setConfirmModal, state.setToggleImageUploadModal]);
    const [filters, setFilters] = useState<{ user: boolean; template: boolean }>({ user: true, template: true });

    function setNewFilters(type: string, value: boolean) {
        let newFilters = { ...filters };
        switch (type) {
            case "template":
                newFilters.template = value;

                break;
            case "user":
                newFilters.user = value;
                break;
            default:
                break;
        }
        setFilters(newFilters);
    }

    return (
        <div className="scenario-selector-container">
            <span className="editor-subtitle">Select Scenario</span>
            <div className="scenario-selector">
                <div className="filter-container">
                    <div className="hide-templates-container" title="Templates">
                        <Img className="hide-templates" src={Template}></Img>
                        <input
                            type="checkbox"
                            className="checkbox"
                            defaultChecked={filters.template}
                            onChange={(e) => {
                                setNewFilters("template", e.target.checked);
                            }}
                        ></input>
                    </div>
                    <div className="hide-templates-container" title="User">
                        <Img className="hide-templates" src={Portrait}></Img>
                        <input
                            type="checkbox"
                            className="checkbox"
                            defaultChecked={filters.user}
                            onChange={(e) => {
                                setNewFilters("user", e.target.checked);
                            }}
                        ></input>
                    </div>
                </div>
                <Dropdown elements={scenarios} selected={selectedScenarioID} filters={filters} scenario={scenario} />
                <div className="new-scenario-buttons">
                    <button className="scenario-button plus-button" onClick={(e) => createScenario()}></button>
                    <button className="scenario-button duplicate-button" onClick={(e) => duplicateScenario()}></button>
                    <div style={locked ? { position: "relative", opacity: 0.5 } : { position: "relative" }} className="lock-transition">
                        {locked && <LockOverlay />}
                        <button
                            className="scenario-button trash"
                            onClick={(e) => setConfirmModal({ onConfirm: deleteScenario, confirmText: "Delete", title: "Delete scenario?" })}
                        ></button>
                    </div>
                    <button className="scenario-button upload-button" onClick={() => setToggleImageUploadModal(true)}></button>
                </div>
            </div>
        </div>
    );
}
