import SplashIcon from "../components/SplashIcon";
import Slider from "../components/Slider";
import Scenario from "../classes/Scenario";
export default function BallEditor({ scenario, updateScenario }: { scenario: Scenario; updateScenario: Function }) {
    function updateColor(color: string) {
        let scenarioClone: Scenario = scenario.clone();
        scenarioClone.ball.backgroundColor = color;
        updateBall(scenarioClone);
    }
    function updateSize(value: number) {
        let scenarioClone: Scenario = scenario.clone();
        scenarioClone.ball.radius = value;
        updateBall(scenarioClone);
    }
    function updateBall(scenario: Scenario) {
        updateScenario(scenario);
    }
    return (
        <div className="right-editor-container">
            <span className="right-editor-title">Edit Ball</span>
            <div className="ball-editor-container">
                <span className="right-editor-subtitle">Color:</span>
                <SplashIcon color={scenario.ball.backgroundColor} colorChanged={updateColor} />
            </div>
            <Slider title={"Size:"} min={2} max={16} size={scenario.ball.radius} sliderChanged={updateSize} />
        </div>
    );
}
/*  <Slider title={"Range:"} min={10} max={30} />
                <div id="color-picker-front">
                    <SplashIcon color={"red"}></SplashIcon>
                    <input type="color" id="color-picker" defaultValue="#000000" onChange={(e) => console.log("asd")}></input>
                </div>*/
