import create from "zustand";
import Scenario from "../classes/Scenario";

interface State {
  scenario: Scenario | null;
  setEditorScenario: (scenario: Scenario) => void;
}

const useEditorStore = create<State>((set, get) => ({
  scenario: null,
  setEditorScenario: (scenario) => set({ scenario }),
}));

export default useEditorStore;
