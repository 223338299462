import BallEditor from "../components/BallEditor";
import OutcomeEditor from "../components/OutcomeEditor";
import RoleEditor from "../components/RoleEditor";
import SettingsEditor from "../components/SettingsEditor";
import Tutorial from "../components/Tutorial";
import Scenario, { ScenarioElement, scenarioElementType, Outcome, Role, RoleOutcomesSelector } from "../classes/Scenario";
import useModalStore from "../stores/modalStore";

export default function Editor({
  scenario,
  updateScenario,
  selectedElement,
  setRoleOutcomesSelector,
  roleOutcomesSelector,
  scenarios,
}: {
  scenario: Scenario;
  updateScenario: Function;
  selectedElement: ScenarioElement;
  setRoleOutcomesSelector: Function;
  roleOutcomesSelector: RoleOutcomesSelector | null;
  scenarios: Scenario[];
}) {
  const lobbyScores = useModalStore((state) => state.lobbyScores);
  /* function save() {
        let newScen = new Scenario(scenario?.outcomes);
        newScen.outcomes[5].position = 5;
        scenario = setScenario(newScen);
    }*/

  return (
    <>
      {selectedElement.type === scenarioElementType.ball && <BallEditor scenario={scenario} updateScenario={updateScenario} />}
      {selectedElement.type === scenarioElementType.outcome && (
        <OutcomeEditor outcome={selectedElement.element as Outcome} scenario={scenario} updateScenario={updateScenario} scenarios={scenarios} />
      )}
      {selectedElement.type === scenarioElementType.role && (
        <RoleEditor
          role={selectedElement.element as Role}
          scenario={scenario}
          updateScenario={updateScenario}
          setRoleOutcomesSelector={setRoleOutcomesSelector}
          roleOutcomesSelector={roleOutcomesSelector}
        />
      )}
      {selectedElement.type === scenarioElementType.settings && <SettingsEditor scenario={scenario} updateScenario={updateScenario} />}
      {selectedElement.type === scenarioElementType.nothing && <Tutorial />}
    </>
  );
}
