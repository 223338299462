import { useState, useEffect, useRef } from "react";
import RoleInfo from "../components/RoleInfo";
import { events } from "../ServerCommunication";
import { Role, Outcome } from "../classes/Scenario";
import Img from "./Img";
let playersData: any[] = [];
const useUpdateEffect = (callback: any, dependencies: any) => {
    const initMount = useRef(true);

    useEffect(() => {
        if (initMount.current) {
            initMount.current = false;

            return;
        }

        return callback();
    }, dependencies);
};

export default function GameSetup({
    roles,
    lobbyID,
    outcomes,
    playersDataDisplay,
}: {
    roles: Role[];
    lobbyID: string;
    outcomes: Outcome[];
    playersDataDisplay: any;
}) {
    const [selectedRole, setSelectedRole] = useState<Role>(roles[0]);
    const [hoveredRole, setHoveredRole] = useState<Role | null>(null);
    useEffect(() => {
        let roleID: string = getMyRole();
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].id === roleID) {
                let myRole: Role = roles[i];
                setSelectedRole(myRole);
                break;
            }
        }
        globalThis.globalEvents.addEventListener(events.selectRoleResponse, selectRoleResponse);
        return function cleanup() {
            globalThis.globalEvents.removeEventListener(events.selectRoleResponse, selectRoleResponse);
        };
    }, []);

    useEffect(() => {
      playersData = playersDataDisplay;
      const roles = document.getElementsByClassName("role");
      for (var i = 0; i < roles.length; i++) {
        roles[i].classList.remove("selected-role");
        roles[i].classList.remove("taken-role");
        for (var j = 0; j < playersData.length; j++) {
          if (roles[i].getAttribute("data-role") === playersData[j].selectedRoleId) {
            if (playersData[j].username === globalThis.username) {
              roles[i].classList.add("selected-role");
            } else {
              roles[i].classList.add("taken-role");
            }
          }
        }
      }

      //console.log(playersData);
    }, [playersDataDisplay]);

    function selectRoleResponse(data: any) {
      //console.log(data);
    }

    function getMyRole() {
      for (let i = 0; i < playersData.length; i++) {
        //console.log(playersData);
        if (playersData[i].username === globalThis.username) {
          return playersData[i].selectedRoleID;
        }
      }
    }
    function setRole(element: any) {
      let role = element.target.getAttribute("data-role");
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].id === role) {
          let roleID: string | null;
          if (roles[i].id === getMyRole()) {
            roleID = null;
          } else {
            roleID = roles[i].id;
          }
          globalThis.socket?.send(
            JSON.stringify({
              event: events.selectRoleRequest,
              lobbyID: lobbyID,
              roleID: roleID,
            })
          );
          setSelectedRole(roles[i]);
        }
      }
    }

    useEffect(() => {
        const roles = document.getElementsByClassName("role");
        for (var i = 0; i < roles.length; i++) {
            roles[i].addEventListener("mouseover", showRole.bind(roles[i]));
            roles[i].addEventListener("click", setRole.bind(roles[i]));
            roles[i].addEventListener("mouseout", hideRole);
        }
        return () => {
            for (var i = 0; i < roles.length; i++) {
                roles[i].removeEventListener("mouseover", showRole.bind(roles[i]));
                roles[i].removeEventListener("click", setRole.bind(roles[i]));
                roles[i].removeEventListener("mouseout", hideRole);
            }
        };
    }, []);

    function showRole(element: any) {
        var role = element.target.getAttribute("data-role");
        for (var i = 0; i < roles.length; i++) {
            if (roles[i].id === role) {
                setHoveredRole(roles[i]);
            }
        }
    }

    function hideRole() {
        setHoveredRole(null);
    }
    return (
        <>
            <span>Select role</span>
            <div className="roles">
                {roles.map(function (role: Role, index: number) {
                    if (role.image) {
                        return <Img src={role.image} className="role" dataRole={role.id} key={index}></Img>;
                    } else {
                        return <div style={{ backgroundColor: role.backgroundColor }} className="role round" data-role={role.id} key={index}></div>;
                    }
                })}
            </div>
            <RoleInfo role={hoveredRole ? hoveredRole : selectedRole} outcomes={outcomes} />
        </>
    );
}
//
