import { useEffect, useRef, useState, Fragment, useId } from "react";
import Template from "../assets/svg/template.svg";
import Portrait from "../assets/svg/portrait.svg";

import { events, ScenarioType } from "../ServerCommunication";
import Arrow from "../assets/svg/angle-small-down.svg";
import Scenario from "../classes/Scenario";

import Img from "../components/Img";
export default function Dropdown({
    elements,
    selected,
    filters,
    scenario,
    getScen = null,
    className,
}: {
    elements: any;
    selected: string | null;
    filters: { user: boolean; template: boolean };
    scenario: Scenario;
    getScen?: Function | null;
    className?: string;
}) {
    const [showElements, setShowElements] = useState<boolean>(false);
    const [selectedScenario, setSelectedScenario] = useState<any>(getSelectedScenario());
    const dropdown = useRef<any>();

    function getScenario(scenarioID: string): void {
        globalThis.socket?.send(
            JSON.stringify({
                event: events.getScenarioRequest,
                scenarioID: scenarioID,
            })
        );
    }

    function getSelectedScenario() {
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].id === selected) {
                return elements[i].name;
            }
        }
    }

    /* useEffect(() => {
        window.addEventListener("click", function (event: any) {
            // If user clicks inside the element, do nothing
            if (event.target.closest(".dropdown")) return;

            if (event.target.closest(".dropdown-elements")) return;
            // If user clicks outside the element, hide it!
            //setShowElements(false);
        });
        return function cleanup() {};
    }, []);*/

    useEffect(() => {
        setSelectedScenario(getSelectedScenario());
    }, [selected]);

    return (
        <div
            className="dropdown"
            onClick={() => {
                setShowElements(!showElements);
            }}
        >
            <div className="dropdown-header">
                <span className="dropdown-scenario-title" title={scenario.name}>
                    {scenario.name}
                </span>
                <img className="arrow" src={Arrow}></img>
            </div>
            {showElements && (
                <div className={`dropdown-elements ${className}`} ref={dropdown}>
                    {elements.map((scenario: any, index: number) => {
                        return (
                            <Fragment key={index}>
                                {filters.template && scenario.type === ScenarioType.template && (
                                    <div
                                        className="dropdown-element"
                                        key={index}
                                        onClick={(e) => {
                                            getScen ? getScen(scenario.id) : getScenario(scenario.id);
                                        }}
                                    >
                                        <span className="dropdown-scenario-title" title={scenario.name}>
                                            {scenario.name}
                                        </span>
                                        <Img
                                            className="template"
                                            src={scenario.type === ScenarioType.template ? Template : Portrait}
                                            title={scenario.type === ScenarioType.template ? "Template" : "User"}
                                        ></Img>
                                    </div>
                                )}
                                {filters.user && (scenario.type === ScenarioType.public || scenario.type === ScenarioType.private) && (
                                    <div className="dropdown-element" key={index} onClick={(e) => (getScen ? getScen(scenario.id) : getScenario(scenario.id))}>
                                        <span className="dropdown-scenario-title" title={scenario.name}>
                                            {scenario.name}
                                        </span>
                                        <Img
                                            className="template"
                                            src={scenario.type === ScenarioType.template ? Template : Portrait}
                                            title={scenario.type === ScenarioType.template ? "Template" : "User"}
                                        ></Img>
                                    </div>
                                )}
                            </Fragment>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
