export default function Select({
  options,
  disabled = false,
  type = null,
  onChange = null,
  valueType = null,
  defaultOption = null,
  defaultText = "",
}: {
  options: any;
  disabled?: boolean;
  type?: string | null;
  onChange?: Function | null;
  valueType?: any;
  defaultOption?: any;
  defaultText?: string;
}) {
  function onUpdate(e: any) {
    onChange && onChange(e.target.value);
  }

  return (
    <select className="select" disabled={disabled} onChange={onUpdate} defaultValue={defaultOption}>
      {defaultText && <option value={undefined}>{defaultText}</option>}
      {options.map((option: any, index: number) => {
        return (
          <option className="option" key={index} value={valueType ? option[valueType] : option}>
            {type ? option[type] : option}
          </option>
        );
      })}
    </select>
  );
}
