import { useState, useEffect, useRef } from "react";
import EditorOutcome from "../components/EditorOutcome";
import EditorBall from "../components/EditorBall";

import { useNavigate } from "react-router-dom";
import { events, responses, ScenarioType } from "../ServerCommunication";
import { isMobile, getMousePos, popup } from "../functions";
import Editor from "../components/Editor";
import { Point } from "../utils";
import RoleSelector from "../components/RoleSelector";
import ScenarioSelector from "../components/ScenarioSelector";
import CreateNew from "../components/CreateNew";
import Gear from "../res/svg/gear.svg";
import * as uuid from "uuid";
import ScoreTable from "../components/ScoreTable";
import Scenario, {
  Outcome,
  ScenarioSettings,
  Role,
  scenarioElementType,
  ScenarioElement,
  RoleOutcomesSelector,
  ScoreTable as STable,
} from "../classes/Scenario";
import { Ball } from "../classes/Ball";
import LockOverlay from "../components/LockOverlay";
import ImageUploadModal from "../components/ImageUploadModal";
import useModalStore from "../stores/modalStore";
import Img from "../components/Img";
import useEditorStore from "../stores/editorStore";
var internalScenario: Scenario; /*= new Scenario("", [], [], 1000, new ScenarioSettings());*/
export default function ScenarioEditor() {
  const [toggleImageUploadModal, toggleScoreModal] = useModalStore((state) => [state.toggleImageUploadModal, state.toggleScoreModal]);
  const setEditorScenario = useEditorStore((state) => state.setEditorScenario);
  const canvas: any = useRef();
  const scenarioName: any = useRef();
  let navigate = useNavigate();
  const [scenarios, setScenarios] = useState<Array<any>>([]);
  const [scenario, setScenario] = useState<Scenario>(
    new Scenario("", "", [], [], new Ball(new Point(0, 0), 0, "red", 5), "", ScenarioType.private, new ScenarioSettings(), new STable())
  );

  const [selectedElement, setSelectedElement] = useState<ScenarioElement>(new ScenarioElement(null, scenarioElementType.nothing));
  const [selectedScenarioID, setSelectedScenarioID] = useState<string | null>(null);
  const [roleOutcomesSelector, setRoleOutcomesSelector] = useState<RoleOutcomesSelector | null>(null);

  const [locked, setLocked] = useState<boolean>(true);

  useEffect(() => {
    if (isMobile.any()) {
      window.addEventListener("touchmove", onMouseMove);
    } else {
      window.addEventListener("mousemove", onMouseMove);
    }
    //

    globalThis.globalEvents.addEventListener(events.getScenarioListResponse, getScenarioListResponse);
    globalThis.globalEvents.addEventListener(events.createScenarioResponse, createScenarioResponse);
    globalThis.globalEvents.addEventListener(events.scenarioUpdateResponse, scenarioUpdateResponse);
    globalThis.globalEvents.addEventListener(events.getScenarioResponse, getScenarioResponse);
    globalThis.globalEvents.addEventListener(events.deleteScenarioResponse, deleteScenarioResponse);
    globalThis.globalEvents.addEventListener(events.duplicateScenarioResponse, duplicateScenarioResponse);

    window.addEventListener("resize", resize);
    canvas.current.addEventListener("mouseup", onMouseUp);

    getScenarioListRequest();
    return function cleanup() {
      if (window) {
        if (isMobile.any()) {
          window.removeEventListener("touchmove", onMouseMove);
        } else {
          window.removeEventListener("mousemove", onMouseMove);
        }
        //
      }
      //

      globalThis.globalEvents.removeEventListener(events.getScenarioListResponse, getScenarioListResponse);
      globalThis.globalEvents.removeEventListener(events.createScenarioResponse, createScenarioResponse);
      globalThis.globalEvents.removeEventListener(events.scenarioUpdateResponse, scenarioUpdateResponse);
      globalThis.globalEvents.removeEventListener(events.getScenarioResponse, getScenarioResponse);
      globalThis.globalEvents.removeEventListener(events.deleteScenarioResponse, deleteScenarioResponse);
      globalThis.globalEvents.removeEventListener(events.duplicateScenarioResponse, duplicateScenarioResponse);
      window.removeEventListener("resize", resize);
    };
  }, []);

  function onMouseUp() {
    setSelectedElement(new ScenarioElement(null, scenarioElementType.nothing));
  }

  useEffect(() => {
    if (roleOutcomesSelector) {
      disableElements(roleOutcomesSelector);
    } else {
      enableElements();
    }
  }, [roleOutcomesSelector]);

  useEffect(() => {
    if (toggleScoreModal) {
      setSelectedElement(new ScenarioElement(null, scenarioElementType.nothing));
    }
  }, [toggleScoreModal]);

  function disableElements(roleOutcomesSelector: RoleOutcomesSelector) {
    let leftPanel: any = document.getElementsByClassName("left-panel")[0];
    let rightPanel: any = document.getElementsByClassName("right-editor-container")[0];
    let scenarioName: any = document.getElementsByClassName("scenario-name-container")[0];
    let bottomPanel: any = document.getElementsByClassName("bottom-panel")[0];
    let ball: any = document.getElementsByClassName("editor-ball")[0];

    let roleContainer: any = document.getElementsByClassName("role-outcomes-container")[0];
    let editorElements: any = document.getElementsByClassName("role-outcomes");
    if (rightPanel) disablePanel(rightPanel.children);
    if (leftPanel) disablePanel(leftPanel.children);
    if (bottomPanel) disablePanel(bottomPanel.children);
    if (scenarioName) disablePanel(scenarioName.children);
    if (roleContainer) disablePanel(roleContainer.children);
    if (ball) ball.classList.add("disabled");

    if (roleContainer) roleContainer.classList.remove("disabled");
    //if (roleContainereditorElements) {
    if (roleOutcomesSelector.outcomeCategory === "ideal") {
      editorElements[0].classList.remove("disabled");
    } else if (roleOutcomesSelector.outcomeCategory === "settle") {
      editorElements[1].classList.remove("disabled");
    } else if (roleOutcomesSelector.outcomeCategory === "failure") {
      editorElements[2].classList.remove("disabled");
    }
  }

  function enableElements() {
    let leftPanel: any = document.getElementsByClassName("left-panel")[0];
    let rightPanel: any = document.getElementsByClassName("right-editor-container")[0];
    let scenarioName: any = document.getElementsByClassName("scenario-name-container")[0];
    let bottomPanel: any = document.getElementsByClassName("bottom-panel")[0];
    let ball: any = document.getElementsByClassName("editor-ball")[0];
    let roleContainer: any = document.getElementsByClassName("role-outcomes-container")[0];
    let editorElements: any = document.getElementsByClassName("role-outcomes");
    if (rightPanel) enablePanel(rightPanel.children);
    if (leftPanel) enablePanel(leftPanel.children);
    if (bottomPanel) enablePanel(bottomPanel.children);
    if (scenarioName) enablePanel(scenarioName.children);
    if (roleContainer) enablePanel(roleContainer.children);
    if (roleContainer) roleContainer.classList.remove("disabled");
    if (ball) ball.classList.remove("disabled");
    for (let i = 0; i < editorElements.length; i++) {
      editorElements[i].classList.remove("disabled");
    }
  }
  function disablePanel(element: any) {
    for (let i = 0; i < element.length; i++) {
      element[i].classList.add("disabled");
    }
  }
  function enablePanel(element: any) {
    for (let i = 0; i < element.length; i++) {
      element[i].classList.remove("disabled");
    }
  }
  useEffect(() => {
    setSelectedElement(new ScenarioElement(null, scenarioElementType.nothing));
  }, [selectedScenarioID]);

  function deselectElement() {
    let outcomes = document.getElementsByClassName("editor-outcome");
    for (let i = 0; i < outcomes.length; i++) {
      outcomes[i].classList.add("selected-editor-outcome");
    }
  }

  function onMouseMove(e: any) {
    globalThis.mousePos = getMousePos(e);
  }

  function getScenarioListResponse(data: any) {
    setScenarios(data.scenarioPropList);
  }
  function createScenarioResponse(data: any) {
    setSelectedScenarioID(data.scenario.id);
    getScenarioListRequest();
    globalThis.socket?.send(
      JSON.stringify({
        event: events.getScenarioRequest,
        scenarioID: data.scenario.id,
      })
    );
  }
  function scenarioUpdateResponse(data: any) {
    getScenarioListRequest();
    if (data.response === responses.success) {
      popup("Save successful", "green");
    } else {
      popup(`Save failed, error code: ${data.response}`, "red");
    }
  }
  function getScenarioResponse(data: any) {
    let ball: Ball = new Ball(
      new Point(data.scenario.ball.position.x, data.scenario.ball.position.y),
      data.scenario.ball.radius,
      data.scenario.ball.backgroundColor,
      data.scenario.ball.frictionF
    );
    let tempScenario = new Scenario(
      data.scenario.id,
      data.scenario.name,
      data.scenario.outcomes.map((o: Outcome) => Outcome.createFromJSON(o)),
      data.scenario.roles,
      ball,
      data.scenario.ownerEmail,
      data.scenario.type,
      data.scenario.settings,
      STable.createFromJSON(data.scenario.scoreTable)
    );
    internalScenario = tempScenario;
    setScenario(internalScenario);
    setEditorScenario(internalScenario);
  }
  function deleteScenarioResponse({ response, scenarioID }: { response: responses; scenarioID: string }) {
    setSelectedScenarioID(null);
    getScenarioListRequest();
  }
  function duplicateScenarioResponse({ response, scenario }: { response: responses; scenario: any }) {
    //console.log("duplicatscenresponse");
    let tempScenario = new Scenario(
      scenario.id,
      scenario.name,
      scenario.outcomes.map((o: Outcome) => Outcome.createFromJSON(o)),
      scenario.roles,
      scenario.ball,
      scenario.ownerEmail,
      scenario.type,
      scenario.settings,
      STable.createFromJSON(scenario.scoreTable)
    );

    internalScenario = tempScenario;
    setSelectedScenarioID(internalScenario.id);
    setScenario(internalScenario);
    setEditorScenario(internalScenario);
    getScenarioListRequest();
  }
  useEffect(() => {
    if (scenarios.length > 0) {
      if (!selectedScenarioID) {
        if (scenarios[0]) {
          setSelectedScenarioID(scenarios[0].id);
          globalThis.socket?.send(
            JSON.stringify({
              event: events.getScenarioRequest,
              scenarioID: scenarios[0].id,
            })
          );
        }
      } else {
        // console.log("selected:", selectedScenarioID);
        // console.log("internal:", internalScenario.id);
        if (internalScenario) {
          if (selectedScenarioID !== internalScenario.id) {
            globalThis.socket?.send(
              JSON.stringify({
                event: events.getScenarioRequest,
                scenarioID: selectedScenarioID,
              })
            );
          }
        }
      }
    }
  }, [scenarios]);

  useEffect(() => {
    setSelectedScenarioID(scenario.id);
    scenarioName.current.value = scenario.name;
    if (scenario) {
      internalScenario = scenario;
    }
    if (!globalThis.superAdmin) {
      if (scenario.type === ScenarioType.template) {
        setLocked(true);
      } else {
        setLocked(false);
      }
    } else {
      setLocked(false);
    }
  }, [scenario]);

  useEffect(() => {
    if (selectedElement.type !== scenarioElementType.outcome && selectedElement.type !== scenarioElementType.ball) {
      deselectElement();
    }
    if (selectedElement.type !== scenarioElementType.ball) {
      let ball: any = document.getElementsByClassName("editor-ball")[0];
      ball.classList.remove("selected-editor-outcome");
    } else {
      let outcomes = document.getElementsByClassName("editor-outcome");
      for (let i = 0; i < outcomes.length; i++) {
        outcomes[i].classList.remove("selected-editor-outcome");
      }
    }
  }, [selectedElement]);

  function updateScenario(scenario: Scenario) {
    let tempScenario = new Scenario(
      scenario.id,
      scenario.name,
      scenario.outcomes.map((o: Outcome) => Outcome.createFromJSON(o)),
      scenario.roles,
      scenario.ball,
      scenario.ownerEmail,
      scenario.type,
      scenario.settings,
      scenario.scoreTable
    );
    setScenario(tempScenario);
    setEditorScenario(tempScenario);
  }
  function createScenario() {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.createScenarioRequest,
      })
    );
  }
  function deleteScenario() {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.deleteScenarioRequest,
        scenarioID: scenario.id,
      })
    );
  }
  function duplicateScenario() {
    //console.log("sending duplicate scen request");
    globalThis.socket?.send(
      JSON.stringify({
        event: events.duplicateScenarioRequest,
        scenarioID: scenario.id,
      })
    );
  }
  function updateScenarioName(newName: string) {
    let newScenario = internalScenario.clone();
    newScenario.name = newName;
    updateScenario(newScenario);
  }

  function getScenarioListRequest() {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.getScenarioListRequest,
      })
    );
  }

  function createNewOutcome(e: any) {
    let outcome: Outcome = new Outcome(uuid.v4(), "New Outcome", "", new Point(135 / 2, 50), 20, 20, undefined, null, "");
    internalScenario.outcomes.push(outcome);
    let newScenario = internalScenario.clone();
    updateScenario(newScenario);
  }

  function createNewRole() {
    let role: Role = new Role(uuid.v4(), "New Role", "", [], [], []);
    internalScenario.roles.push(role);
    let newScenario = internalScenario.clone();
    updateScenario(newScenario);
  }

  function resize() {
    // console.log("asd");
  }

  function leaveEditor() {
    navigate("/menu");
  }

  function saveScenario() {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.scenarioUpdateRequest,
        scenario: internalScenario,
      })
    );
  }

  /* useEffect(() => {
    }, [scenario]);*/

  /*useEffect(() => {
        console.log(selectedOutcome);
        setSelectedPanel("outcome");
    }, [selectedOutcome]);*/
  return (
    <div className="scenadio-editor-container">
      {toggleImageUploadModal && <ImageUploadModal />}
      {toggleScoreModal && <ScoreTable />}
      <div className="left-panel">
        <ScenarioSelector
          selectedScenarioID={selectedScenarioID}
          scenarios={scenarios}
          setScenario={setScenario}
          deleteScenario={deleteScenario}
          createScenario={createScenario}
          duplicateScenario={duplicateScenario}
          scenario={scenario}
          locked={locked}
        />
        <div className="lock-transition" style={locked ? { position: "relative", opacity: 0.5 } : { position: "relative" }}>
          {locked && <LockOverlay />}
          <CreateNew createNewOutcome={createNewOutcome} createNewRole={createNewRole} />
        </div>
        <div className="editor-save-button">
          <button className="login-button" onClick={leaveEditor}>
            Back
          </button>
          <button className="login-button" onClick={saveScenario}>
            Save
          </button>
        </div>
      </div>
      <div className="middle-panel">
        <div className="scenario-name-container">
          <div className="lock-transition" style={locked ? { position: "relative", opacity: 0.5 } : { position: "relative" }}>
            {locked && <LockOverlay />}
            <input
              ref={scenarioName}
              maxLength={20}
              className="scenario-name"
              type="text"
              placeholder="NEW SCENARIO"
              onChange={(e) => updateScenarioName(e.target.value)}
            ></input>
          </div>
          <Img className="scenario-gear" src={Gear} onClick={() => setSelectedElement(new ScenarioElement(null, scenarioElementType.settings))}></Img>
        </div>
        <Canvas canvas={canvas} scenario={scenario} />
        {scenario.outcomes.map(function (outcome: Outcome, index: number) {
          //console.log(outcome.position);
          return (
            <EditorOutcome
              outcomeIndex={index}
              scenario={scenario}
              roleOutcomesSelector={roleOutcomesSelector}
              setRoleOutcomesSelector={setRoleOutcomesSelector}
              updateScenario={updateScenario}
              setSelectedElement={setSelectedElement}
              key={index}
              locked={locked}
            />
          );
        })}

        <EditorBall scenario={scenario} updateScenario={updateScenario} setSelectedElement={setSelectedElement} locked={locked} />

        <div className="bottom-panel">
          <RoleSelector scenario={scenario} setSelectedElement={setSelectedElement} />
        </div>
      </div>
      <div className="right-panel lock-transition" style={locked ? { opacity: 0.7 } : {}}>
        {locked && <LockOverlay icon={true} />}
        <Editor
          scenario={scenario}
          scenarios={scenarios}
          updateScenario={updateScenario}
          selectedElement={selectedElement}
          setRoleOutcomesSelector={setRoleOutcomesSelector}
          roleOutcomesSelector={roleOutcomesSelector}
        />
      </div>
    </div>
  );
}

function Canvas({ canvas, scenario }: { canvas: any; scenario: Scenario }) {
  return (
    <div className="editor-canvas-container" style={{ backgroundColor: scenario.settings.boardBackgroundColor }}>
      <canvas ref={canvas} className="editor-canvas"></canvas>
    </div>
  );
}

/*
///////////old left panel//////////////eScenario}>
Save scenario
</button>

<button className="login-button" onClick={leaveEditor}>
Exit editor
</button>*/
/*                {scenario &&
                    scenario.outcomes.map((outcome: any, index: number) => (
                        <EditorOutcome
                            position={outcome.position}
                            width={outcome.width}
                            height={outcome.height}
                            title={outcome.title}
                            description={outcome.description}
                            image={outcome.image}
                            index={index}
                            backgroundColor={outcome.backgroundColor}
                        />
                    ))}*/
