import { RotatingLines } from "react-loader-spinner";

export default function Loader() {
    return (
        <div className="image-loader">
            <div style={{ width: "65%", height: "65%", position: "relative" }}>
                <RotatingLines strokeColor="white" strokeWidth="4" animationDuration="0.75" width="100%" visible={true} />
            </div>
        </div>
    );
}
