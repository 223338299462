import { useEffect, useRef } from "react";
export default function TextArea({
  textContent,
  textContentChanged,
  title,
  placeholder,
}: {
  textContent: string;
  textContentChanged: Function;
  title: string;
  placeholder: string;
}) {
  const textarea: any = useRef();
  useEffect(() => {
    if (textContent !== null) textarea.current.value = textContent;
  }, [textContent]);
  return (
    <div className="description-container">
      <div className="description-title-container">
        <span>{title}</span>
      </div>
      <textarea ref={textarea} placeholder={placeholder} className="description-textarea" onChange={(e) => textContentChanged(e.target.value)}></textarea>
    </div>
  );
}
