import { useState, useEffect, useRef } from "react";
import {
  accountType,
  AuthenticateAccountRequestData,
  AuthenticateAccountResponseData,
  events,
  GetInvitedUsersRequestData,
  responses,
} from "../ServerCommunication";
import { useNavigate } from "react-router-dom";
import SplashIcon from "../components/SplashIcon";
import { popup } from "../functions";
import { Test1, Test2 } from "../components/Test";
import SessionCarousel from "../components/SessionCarousel";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import { join } from "path";
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const code = urlParams.get("invite_code");
const CryptoJS = require("crypto-js");

const cookieEmail: string | null = localStorage.getItem("email");
const cookieUsername: string | undefined | null = null; // getCookie("username");
const cookiePassword: string | null = localStorage.getItem("password");

var cookiePw: string;
var cookieMail: string;
export default function HomeScreen() {
  let navigate = useNavigate();
  const [email, setEmail] = useState<string>(cookieEmail ? cookieEmail : "");
  const [password, setPassword] = useState<string>(cookiePassword ? cookiePassword : "");
  const [username, setUsername] = useState<string>(cookieUsername ? cookieUsername : "username" + Math.floor(Math.random() * 1000));
  const [inviteCode, setInviteCode] = useState<string>(code ? code : "");
  const [color, setColor] = useState<string>("#" + Math.floor(Math.random() * 16777215).toString(16));

  const loginButton: any = useRef();
  const joinInput: any = useRef(false);
  const joinButton: any = useRef();

  useEffect(() => {
    window.addEventListener("keypress", onKeyDown);

    globalThis.globalEvents.addEventListener(events.authenticateAccountResponse, authenticateAccountResponse);
    globalThis.globalEvents.addEventListener(events.joinLobbyResponse, joinGameResponse);
    return function cleanup() {
      window.removeEventListener("keypress", onKeyDown);

      globalThis.globalEvents.removeEventListener(events.authenticateAccountResponse, authenticateAccountResponse);
      globalThis.globalEvents.removeEventListener(events.joinLobbyResponse, joinGameResponse);
    };
  }, []);

  function onKeyDown(e: any) {
    if (e.key === "Enter") {
      if (joinInput.current) {
        joinButton.current.click();
      } else {
        loginButton.current.click();
      }
    }
  }

  function authenticateAccountResponse(data: AuthenticateAccountResponseData) {
    //  console.log(data);
    //  responses.success | responses.unauthorized | responses.notFound | responses.serverError;
    switch (data.response) {
      case responses.success:
        if (data.accountType === accountType.admin) {
          globalThis.superAdmin = true;
        } else {
          globalThis.superAdmin = false;
        }
        globalThis.authToken = data.authToken;
        localStorage.setItem("password", cookiePw);
        localStorage.setItem("email", cookieMail);
        navigate("/menu");
        if (data.username) globalThis.username = data.username;
        globalThis.email = data.email;

        break;
      case responses.unauthorized:
        //  showPopup("Invalid email or password!");
        popup("Invalid email or password", "red");
        break;
      case responses.notFound:
        //  showPopup("Invalid email or password!");
        popup("Invalid email or password", "red");
        break;
      case responses.serverError:
        //  showPopup("Invalid email or password!");
        popup("Invalid email or password", "red");
        break;
      default:
        break;
    }
  }
  function authenticateAccountRequest() {
    // globalThis.globalEvents.triggerEvent(events.triggerPopup);
    let requestData: AuthenticateAccountRequestData = {
      event: events.authenticateAccountRequest,
      email: email,
      password: password,
      userColor: color,
      username: username,
    };
    globalThis.socket?.send(JSON.stringify(requestData));
  }

  function joinGameRequest() {
    if (inviteCode !== undefined) {
      globalThis.socket?.send(
        JSON.stringify({
          event: events.joinLobbyRequest,
          username: username,
          userColor: color,
          inviteCode: parseInt(inviteCode),
        })
      );
    }
  }
  useEffect(() => {
    cookiePw = password;
  }, [password]);

  useEffect(() => {
    cookieMail = email;
  }, [email]);

  useEffect(() => {
    globalThis.username = username;
  }, [username]);

  function joinGameResponse(data: any) {
    switch (data.response) {
      case responses.success:
        navigate("/lobby", { state: { inviteCode: data.inviteCode, lobbyID: data.lobbyID } });
        break;
      case responses.notFound:
        popup("Invalid invite code!", "red");
        break;
      case responses.lobbyFull:
        popup("Lobby is full!", "red");
        break;
      case responses.duplicateUsername:
        popup("Username in lobby already in use!", "red");
        break;
      default:
        break;
    }
  }

  function onFocus() {
    joinInput.current = true;
  }

  function onBlur() {
    joinInput.current = false;
  }

  return (
    <div className="home-container">
      <div className="login-container">
        <Logo className="logo-svg" />
        <span className="secondary-panel-title">Choose your username and color</span>
        <SplashIcon color={color} colorChanged={setColor}></SplashIcon>
        <input
          className="input input-username"
          type="username"
          placeholder="username"
          onChange={(e) => setUsername(e.target.value)}
          maxLength={12}
          defaultValue={username}
        ></input>
        <input className="input" type="text" placeholder="email" onChange={(e) => setEmail(e.target.value)} defaultValue={email}></input>
        <input
          id="password"
          className="input"
          type="password"
          placeholder="password"
          onChange={(e) => {
            let utf16Sha256 = CryptoJS.SHA256(e.target.value);
            let hash = utf16Sha256.toString(CryptoJS.enc.Hex);
            setPassword(hash);
          }}
          defaultValue={password}
        ></input>
        <button ref={loginButton} className="login-button" onClick={authenticateAccountRequest}>
          LOGIN
        </button>
        <div className="join-container">
          <input
            className="input input-join"
            type="number"
            placeholder="invite code"
            onChange={(e) => setInviteCode(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue={inviteCode}
          ></input>
          <button className="login-button small" onClick={joinGameRequest} ref={joinButton}>
            JOIN
          </button>
        </div>
      </div>
      <SessionCarousel color={color} />
      <div className="credits-container">
        <span className="credit">
          All artwork on Scrumble is either co-created by Gary Milante with AI @ openaidalle&nbsp;
          <a href="https://openai.com/dall-e-2/" target="_blank" className="url" rel="noreferrer">
            #dalle2
          </a>
          &nbsp;or commissioned for Scrumble with license from independent creatives
        </span>
      </div>
    </div>
  );
}
