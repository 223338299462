import { Vector, Point } from "../utils";

export class Animations {
    animations: Animation[];
    ctx: any;
    constructor(ctx: any) {
        this.ctx = ctx;
        this.animations = [];
    }
    animationEnded(animation: any) {
        for (var i = 0; i < this.animations.length; i++) {
            if (animation === this.animations[i]) {
                this.animations.splice(i, 1);
                break;
            }
        }
    }
    addAnimation(animation: Animation) {
        this.animations.push(animation);
    }
    addArrowAnimation(
        color: string,
        startVector: Vector,
        selectedActionVector: Vector,
        startPoint: Point,
        radius: number,
        startTime: number,
        duration: number
    ) {
        let animation: ArrowAnimation = new ArrowAnimation(
            color,
            startVector,
            selectedActionVector,
            startPoint,
            radius,
            startTime,
            duration,
            this.animationEnded.bind(this)
        );
        this.animations.push(animation);
    }
    update() {
        for (var i = 0; i < this.animations.length; i++) {
            this.animations[i].update(this.ctx);
        }
    }
}

class Animation {
    startTime: number;
    duration: number;
    constructor(startTime: number, duration: number) {
        this.startTime = startTime;
        this.duration = duration;
    }
    update(ctx: any) {}
}

export class ArrowAnimation extends Animation {
    color: string;
    startVector: Vector;
    startPoint: Point;
    selectedActionVector: Vector;
    radius: number;
    animationEndedCallback: Function;

    constructor(
        color: string,
        startVector: Vector,
        selectedActionVector: Vector,
        startPoint: Point,
        radius: number,
        startTime: number,
        duration: number,
        animationEndedCallback: Function
    ) {
        super(startTime, duration);
        this.color = color;
        this.startVector = startVector;
        this.selectedActionVector = selectedActionVector;
        this.startPoint = startPoint;
        this.radius = radius;
        this.animationEndedCallback = animationEndedCallback;
    }

    drawArrow(ctx: any, fromx: any, fromy: any, tox: any, toy: any, arrowWidth: any, color: any, width: any) {
        //variables to be used when creating the arrow

        var headlen = 10;
        var angle = Math.atan2(toy - fromy, tox - fromx);
        ctx.save();
        ctx.strokeStyle = color;
        //ctx.fillStyle = color;
        //starting path of the arrow from the start square to the end square
        //and drawing the stroke
        if (arrowWidth < 6) {
            arrowWidth = 6;
        }
        ctx.beginPath();
        ctx.lineWidth = arrowWidth;
        ctx.stroke();

        //starting a new path from the head of the arrow to one of the sides of
        //the point
        ctx.beginPath();
        ctx.moveTo(tox, toy);
        ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / width), toy - headlen * Math.sin(angle - Math.PI / width));

        //path from the side point of the arrow, to the other side point
        ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / width), toy - headlen * Math.sin(angle + Math.PI / width));

        //path from the side point back to the tip of the arrow, and then
        //again to the opposite side point
        ctx.lineTo(tox, toy);
        ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / width), toy - headlen * Math.sin(angle - Math.PI / width));

        //draws the paths created above
        ctx.stroke();
        //ctx.fill();
        ctx.restore();
    }

    newArrow(ctx: any, vector: Vector, startPoint: Point, color: string) {
        let newVector: Vector = vector.getUnitVector();
        newVector.multiplyByScalar(Math.sqrt(vector.length()) * 4 + this.radius);
        this.drawArrow(ctx, startPoint.x, startPoint.y, startPoint.x + newVector.x, startPoint.y + newVector.y, Math.sqrt(3 * vector.length()), color, 12);
    }
    update(ctx: any) {
        let now = Date.now();
        let percent: number = (now - this.startTime) / this.duration;
        if (now - this.startTime < this.duration && this.startTime < now) {
            let arrowVector = new Vector(
                this.startVector.x * (1 - percent) + this.selectedActionVector.x * percent,
                this.startVector.y * (1 - percent) + this.selectedActionVector.y * percent
            );
            if (ctx.canvas)
                this.newArrow(ctx, new Vector(arrowVector.x * ctx.canvas.offsetWidth, arrowVector.y * ctx.canvas.offsetHeight), this.startPoint, this.color);
        }
        if (this.startTime + this.duration < now) {
            this.animationEndedCallback(this);
        }
    }
}
