import SplashIcon from "../components/SplashIcon";
import PhysicsSettings from "../components/PhysicsSettings";
import GamePlaySettings from "../components/GamePlaySettings";
import Scenario from "../classes/Scenario";
export default function SettingsEditor({ scenario, updateScenario }: { scenario: Scenario; updateScenario: Function }) {
    function colorChanged(color: string) {
        let tempScenario: Scenario = scenario.clone();
        tempScenario.settings.boardBackgroundColor = color;
        updateScenario(tempScenario);
    }
    return (
        <div className="right-editor-container">
            <span className="right-editor-title">Settings</span>
            <div className="ball-editor-container">
                <span className="right-editor-subtitle small">Background color:</span>
                <SplashIcon color={scenario.settings.boardBackgroundColor} colorChanged={colorChanged} />
            </div>
            <PhysicsSettings scenario={scenario} updateScenario={updateScenario} />
            <GamePlaySettings scenario={scenario} updateScenario={updateScenario} />
        </div>
    );
}
