import { MediaLibrary } from "./classes/Media";
import Scenario from "./classes/Scenario";
export enum responses{
    failure = 0,
    success = 1,
    notFound = 2,
    serverError = 3,
    unauthorized = 4,
    empty = 5,
    loginRequired = 6,
    invalidAccountType = 7,
    invalidPassword = 8,
    duplicate = 9,

    notAllowedFileFormat = 98,
    noFileAttached = 99,

    lobbyNotFound = 300,
    playerNotFound = 301,

    //joinLobbyRequest
    playerAlreadyInLobby = 400,
    lobbyFull = 401,
    duplicateUsername = 402,

    //leaveLobbyRequest
    userNotFoundInLobby = 500,

    userNotFoundInGameRoom = 501,
    gameRoomNotFound = 502,

    //chooseActionRequest
    alreadyChosenAction = 503,
    playerNotAnActor = 504,

    //selectRoleRequest
    roleAlreadySelected = 600,
    onlyActorsCanSelectRoles = 601,

    //startGameRequest
    
    notAllPlayersSelectedRoles = 700,
    notEnoughPlayers = 701,

    //public scenario sessions
    playerAlreadyInGameRoom = 800,
    gameRoomFull = 801,

    invalidLibraryId = 900
}

export enum events {
    authenticateAccountRequest = "authenticateAccountRequest",
    authenticateAccountResponse = "authenticateAccountResponse",

    hostLobbyRequest = "hostLobbyRequest",
    hostLobbyResponse = "hostLobbyResponse",
    kickPlayerFromLobbyRequest = "kickPlayerFromLobbyRequest",
    kickPlayerFromLobbyResponse = "kickPlayerFromLobbyResponse",
    playerKicked = "playerKicked",

    joinLobbyRequest = "joinLobbyRequest",
    joinLobbyResponse = "joinLobbyResponse",

    joinPublicScenarioSessionRequest = "joinPublicScenarioSessionRequest",
    joinPublicScenarioSessionResponse = "joinPublicScenarioSessionResponse",

    leaveLobbyRequest = "leaveLobbyRequest",
    leaveLobbyResponse = "leaveLobbyResponse",

    leaveGameRoomRequest = "leaveGameRoomRequest",
    leaveGameRoomResponse = "leaveGameRoomResponse",

    lobbyChangeScenarioRequest = "lobbyChangeScenarioRequest",
    lobbyChangeScenarioResponse = "lobbyChangeScenarioResponse",
    lobbyUpdateRequest = "lobbyUpdateRequest",
    lobbyUpdateResponse = "lobbyUpdateResponse",

    selectRoleRequest = "selectRoleRequest",
    selectRoleResponse = "selectRoleResponse",

    updateScenarioSettingsRequest = "updateScenarioSettingsRequest",
    updateScenarioSettingsResponse = "updateScenarioSettingsResponse",

    startGameRequest = "startGameRequest", //event the host sends to the server
    startGameResponse = "startGameResponse",

    chooseActionRequest = "chooseActionRequest",
    chooseActionResponse = "chooseActionResponse",

    lobbyUpdate = "lobbyUpdate",
    lobbyClosed = "lobbyClosed",
    ballApplyForce = "ballApplyForce",

    gameStarted = "gameStarted",
    turnStarted = "turnStarted",
    turnOver = "turnOver",
    gameOver = "gameOver",

    ballHitOutcomeUpdate = "ballHitOutcomeUpdate", //update sent from the facilitator to the server, alerting it that the ball has hit an outcome
    ballStoppedMoving = "ballStoppedMoving", //update sent from the facilitator to the server, alerting it that the ball has hit an outcome

    gameStateSyncRequest = "gameStateSyncRequest", //event the server sends to the lobby host, requesting its gamestate
    gameStateSyncResponse = "gameStateSyncResponse", //event the lobby host sends back with its state, and the server uses data from this response to broadcast the gamestate
    gameStateSyncUpdate = "gameStateSyncUpdate", //event the server sends to clients with updated game state

    //scenario creator
    getScenarioListRequest = "getScenarioListRequest",
    getScenarioListResponse = "getScenarioListResponse",
    getScenarioRequest = "getScenarioRequest",
    getScenarioResponse = "getScenarioResponse",
    createScenarioRequest = "createScenarioRequest",
    createScenarioResponse = "createScenarioResponse",
    deleteScenarioRequest = "deleteScenarioRequest",
    deleteScenarioResponse = "deleteScenarioResponse",
    duplicateScenarioRequest = "duplicateScenarioRequest",
    duplicateScenarioResponse = "duplicateScenarioResponse",

    //public scenario sessions

    getPublicScenarioSessionsRequest = "getPublicScenarioSessionsRequest",
    getPublicScenarioSessionsResponse = "getPublicScenarioSessionsResponse",

    scenarioUpdateRequest = "scenarioUpdateRequest",
    scenarioUpdateResponse = "scenarioUpdateResponse",

    getAllPresetImagesRequest = "getAllPresetImagesRequest",
    getAllPresetImagesResponse = "getAllPresetImagesResponse",

    //email

    sendInviteLinkRequest = "sendInviteLinkRequest",
    sendInviteLinkResponse = "sendInviteLinkResponse",

    getInvitedUsersRequest = "getInvitedUsersRequest",
    getInvitedUsersResponse = "getInvitedUsersResponse",
    //media libraries
    deleteMediaLibraryRequest = "deleteMediaLibraryRequest",
    deleteMediaLibraryResponse = "deleteMediaLibraryResponse",
    getMediaLibraryRequest = "getMediaLibraryRequest",
    getMediaLibraryResponse = "getMediaLibraryResponse",
    getMediaLibraryListRequest = "getMediaLibraryListRequest",
    getMediaLibraryListResponse = "getMediaLibraryListResponse",
    createMediaLibraryRequest = "createMediaLibraryRequest",
    createMediaLibraryResponse = "createMediaLibraryResponse",

    changeMediaLibraryNameRequest = "changeMediaLibraryNameRequest",
    changeMediaLibraryNameResponse = "changeMediaLibraryNameResponse",

    deleteMediaRequest = "deleteMediaRequest",
    deleteMediaResponse = "deleteMediaResponse",
    mediaLibraryUpdate = "mediaLibraryUpdate",

    cursorMovedRequest = "cursorMovedRequest",
    cursorUpdate = "cursorUpdate",
}
export enum playerType {
    facilitator = 0,
    actor = 1,
    spectator = 2,
}

export enum accountType {
    admin = 0,
    facilitator = 1,
    user = 2,
}

export enum ScenarioType {
    template = 0,
    private = 1,
    public = 2,
}
export class InvitedUser {
    inviteCode: string;
    email: string;
    inviteStatus: inviteStatus;
    inviteDate: Date;
    registerDate: Date | null;

    constructor(inviteCode: string, email: string, inviteStatus: inviteStatus, inviteDate: Date, registerDate: Date | null) {
        this.inviteCode = inviteCode;
        this.email = email;
        this.inviteStatus = inviteStatus;
        this.inviteDate = inviteDate;
        this.registerDate = registerDate;
    }
}

export enum inviteStatus {
    invited = 0,
    registered = 1,
}
export declare interface GetInvitedUsersRequestData {
    event: events.getInvitedUsersRequest;
}
export declare interface GetInvitedUsersResponseData {
    event: events.getInvitedUsersResponse;
    invitedUserList: InvitedUser[];
    response: responses.unauthorized | responses.success | responses.serverError;
}
export declare interface SendInviteLinkRequestData {
    event: events.sendInviteLinkRequest;
    email: string;
}
export declare interface SendInviteLinkResponseData {
    event: events.sendInviteLinkResponse;
    email: string;
    response: responses.unauthorized | responses.success | responses.loginRequired | responses.serverError;
}

export declare interface GetPublicScenarioSessionsRequestData {
    event: events.getPublicScenarioSessionsRequest;
}

export declare interface GetPublicScenarioSessionsResponseData {
    event: events.getPublicScenarioSessionsResponse;
    publicScenarioList: { scenario: Scenario; unlocked: boolean }[];
}

export declare interface JoinPublicScenarioSessionRequestData {
    event: events.joinPublicScenarioSessionRequest;
    username: string;
    userColor: string;
    scenarioId: string;
}
export declare interface JoinPublicScenarioSessionResponseData {
    event: events.joinPublicScenarioSessionResponse;
    scenarioId: string;
    response: responses.success | responses.notFound | responses.serverError | responses.unauthorized;
}
export declare interface LeaveGameRoomRequestData {
    event: events.leaveGameRoomRequest;
    gameRoomId: string;
}
export declare interface LeaveGameRoomResponseData {
    event: events.leaveGameRoomResponse;
    response: responses.unauthorized | responses.notFound | responses.success | responses.userNotFoundInGameRoom;
}

export declare interface AuthenticateAccountRequestData {
    event: events.authenticateAccountRequest;
    email: string;
    password: string;
    username: string;
    userColor: string;
}
export declare interface AuthenticateAccountResponseData {
    event: events.authenticateAccountResponse;
    response: responses.success | responses.unauthorized | responses.notFound | responses.serverError;
    email: string;
    username: string | null;
    userColor: string;
    accountType: accountType | null;
    authToken: string;
}
export declare interface GetMediaLibraryRequestData {
    event: events.getMediaLibraryRequest;
    libraryId: string;
}
export declare interface GetMediaLibraryResponseData {
    event: events.getMediaLibraryResponse;
    mediaLibrary: MediaLibrary | undefined;
    response: responses.notFound | responses.loginRequired | responses.success | responses.serverError;
}
export declare interface GetMediaLibraryListRequestData {
    event: events.getMediaLibraryListRequest;
}
export declare interface GetMediaLibraryListResponseData {
    event: events.getMediaLibraryListResponse;
    libraryPropList: { name: string; id: string }[];
    response: responses.loginRequired | responses.serverError | responses.success;
}

export declare interface MediaLibraryUpdateData {
    event: events.mediaLibraryUpdate;
    mediaLibrary: MediaLibrary;
}
export declare interface CreateMediaLibraryRequestData {
    event: events.createMediaLibraryRequest;
    name: string;
}
export declare interface CreateMediaLibraryResponseData {
    event: events.createMediaLibraryResponse;
    mediaLibrary: MediaLibrary | undefined;
    response: responses.success | responses.loginRequired | responses.unauthorized | responses.serverError;
}

export declare interface DeleteMediaLibraryRequestData {
    event: events.deleteMediaLibraryRequest;
    libraryId: string;
}
export declare interface DeleteMediaLibraryResponseData {
    event: events.deleteMediaLibraryResponse;
    response: responses.unauthorized | responses.loginRequired | responses.serverError | responses.success | responses.notFound;
}

interface ResponseData {
    response?: responses;
    responseMessage?: string;
    event: events;
}

export class DeleteMediaRequestData implements ResponseData {
    event = events.deleteMediaRequest;
    mediaIdList: string[];
    constructor(mediaIdList: string[]) {
        this.mediaIdList = mediaIdList;
    }
}
type DeleteMediaResponseDataResponses = responses.success | responses.failure | responses.loginRequired | responses.unauthorized;
export class DeleteMediaResponseData implements ResponseData {
    event = events.deleteMediaResponse;
    response: DeleteMediaResponseDataResponses;
    responseMessage?: string;
    constructor(response: DeleteMediaResponseDataResponses, responseMessage?: string) {
        this.response = response;
        this.responseMessage = responseMessage;
    }
}

interface ResponseData {
    response?: responses;
    responseMessage?: string;
    event: events;
}
export class ChangeMediaLibraryNameRequestData implements ResponseData {
    event = events.changeMediaLibraryNameRequest;
    libraryId: string;
    newName: string;
    constructor(libraryId: string, newName: string) {
        this.libraryId = libraryId;
        this.newName = newName;
    }
}
type ChangeMediaLibraryResponseDataResponses =
    | responses.success
    | responses.loginRequired
    | responses.unauthorized
    | responses.serverError
    | responses.notFound;
export class ChangeMediaLibraryNameResponseData implements ResponseData {
    event = events.changeMediaLibraryNameResponse;
    response: ChangeMediaLibraryResponseDataResponses;
    responseMessage?: string;
    constructor(response: ChangeMediaLibraryResponseDataResponses, responseMessage?: string) {
        this.response = response;
        this.responseMessage = responseMessage;
    }
}

export class CursorMovedRequestData implements ResponseData {
    event = events.cursorMovedRequest;
    lobbyId: string;
    cursorPosition: { x: number; y: number };
    constructor(lobbyId: string, cursorPosition: { x: number; y: number }) {
        this.lobbyId = lobbyId;
        this.cursorPosition = cursorPosition;
    }
}

export class CursorUpdateData implements ResponseData {
    event = events.cursorUpdate;
    lobbyId: string;
    playerId: string;
    cursorPosition: { x: number; y: number };
    constructor(lobbyId: string, playerId: string, cursorPosition: { x: number; y: number }) {
        this.lobbyId = lobbyId;
        this.playerId = playerId;
        this.cursorPosition = cursorPosition;
    }
}