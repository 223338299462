import { popup } from "../functions";
import { ReactComponent as Lock } from "../assets/svg/lock.svg";

export default function LockOverlay({ icon = false }: { icon?: boolean }) {
    function showError() {
        popup("You cannot edit template scenarios. If you would like to edit this scenario, duplicate it and edit your duplicate version.", "red");
    }
    return (
        <div className="lock-overlay" onClick={showError}>
            {icon && <Lock className="lock-overlay-icon" />}
        </div>
    );
}
