import { useEffect, useState } from "react";
import { Role, Outcome } from "../classes/Scenario";
import Img from "./Img";
export default function RoleInfo({ role, outcomes }: { role: Role; outcomes: Outcome[] }) {
  const [newOutcomes, setNewOutcomes] = useState<any>({ idealOutcomes: [], settleOutcomes: [], failureOutcomes: [] });
  useEffect(() => {
    let oc: any = { idealOutcomes: [], settleOutcomes: [], failureOutcomes: [] };
    for (let j = 0; j < outcomes.length; j++) {
      if (role.idealOutcomes) {
        for (let i = 0; i < role.idealOutcomes.length; i++) {
          if (role.idealOutcomes[i] === outcomes[j].id) {
            oc.idealOutcomes.push(outcomes[j]);
          }
        }
      }
      if (role.settleOutcomes) {
        for (let i = 0; i < role.settleOutcomes.length; i++) {
          if (role.settleOutcomes[i] === outcomes[j].id) {
            oc.settleOutcomes.push(outcomes[j]);
          }
        }
      }
      if (role.failureOutcomes) {
        for (let i = 0; i < role.failureOutcomes.length; i++) {
          if (role.failureOutcomes[i] === outcomes[j].id) {
            oc.failureOutcomes.push(outcomes[j]);
          }
        }
      }
    }
    setNewOutcomes(oc);
  }, [role]);
  return (
    <div className="role-info">
      {role.image ? (
        <img src={role.image} className="role-image"></img>
      ) : (
        <div style={{ backgroundColor: role.backgroundColor }} className="role-image round"></div>
      )}
      <div id="role-title">{role.title}</div>
      {/*
            <span className="role-subtitle">IDEAL</span>
            {newOutcomes.idealOutcomes.map(function (outcome: Outcome, index: number) {
                return (
                    <div key={index} className="role-outcome-image-container">
                        {outcome.image ? (
                            <Img className={"role-outcome-image"} src={outcome.image}></Img>
                        ) : (
                            <div className={"role-outcome-image role-round"} style={{ backgroundColor: outcome.backgroundColor }}></div>
                        )}
                        <span key={index} className="role-settings">
                            {outcome.title}
                        </span>
                    </div>
                );
            })}
            <span className="role-subtitle">SETTLE</span>
            {newOutcomes.settleOutcomes.map(function (outcome: Outcome, index: number) {
                return (
                    <div key={index} className="role-outcome-image-container">
                        {outcome.image ? (
                            <Img className={"role-outcome-image"} src={outcome.image}></Img>
                        ) : (
                            <div className={"role-outcome-image role-round"} style={{ backgroundColor: outcome.backgroundColor }}></div>
                        )}
                        <span key={index} className="role-settings">
                            {outcome.title}
                        </span>
                    </div>
                );
            })}
            <span className="role-subtitle">FAILURE</span>
            {newOutcomes.failureOutcomes.map(function (outcome: Outcome, index: number) {
                return (
                    <div key={index} className="role-outcome-image-container">
                        {outcome.image ? (
                            <Img className={"role-outcome-image"} src={outcome.image}></Img>
                        ) : (
                            <div className={"role-outcome-image role-round"} style={{ backgroundColor: outcome.backgroundColor }}></div>
                        )}
                        <span key={index} className="role-settings">
                            {outcome.title}
                        </span>
                    </div>
                );
            })}*/}
      <span className="role-subtitle">Description</span>
      <span className="description-text">{role.description}</span>
    </div>
  );
}
