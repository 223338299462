import avatar from "../res/svg/avatar-filled.svg";
import clock from "../res/svg/clock.svg";
import Scenario from "../classes/Scenario";
import { useRef } from "react";
import { ScenarioType } from "../ServerCommunication";
import Img from "./Img";
export default function GamePlaySettings({ scenario, updateScenario }: { scenario: Scenario; updateScenario: Function }) {
    const minInput: any = useRef();
    const maxInput: any = useRef();
    const min: any = useRef();
    const sec: any = useRef();
    /*function detectNumberLength(element: any) {
        if (element.value.length > 2) {
            element.value = element.value[0] + element.value[1];
        }

        if (element.value > 0) {
            return parseInt(element.value);
        }
    }*/

    function updateMin(value: string) {
        let scenarioClone: Scenario = scenario.clone();
        if (value.length > 0) {
            let t: number = parseInt(value);
            let scenarioClone: Scenario = scenario.clone();
            scenarioClone.settings.minPlayers = t;
        } else {
            scenarioClone.settings.minPlayers = 0;
        }
        updateSettings(scenarioClone);
    }

    function updateMax(value: string) {
        let scenarioClone: Scenario = scenario.clone();
        if (value.length > 0) {
            let t: number = parseInt(value);
            let scenarioClone: Scenario = scenario.clone();
            scenarioClone.settings.maxPlayers = t;
        } else {
            scenarioClone.settings.maxPlayers = 999;
        }
        updateSettings(scenarioClone);
    }

    function updateTime() {
        let minutes: number = parseInt(min.current.value);
        let seconds: number = parseInt(sec.current.value);
        let time: number = 0;
        if (minutes) {
            time += minutes * 60;
        }
        if (seconds) {
            time += seconds;
        }
        let scenarioClone: Scenario = scenario.clone();
        scenarioClone.settings.turnTimeLimit = time;
        updateSettings(scenarioClone);
    }

    function updateSettings(scenario: Scenario) {
        updateScenario(scenario);
    }
    function getTime(time: number) {
        let minutes: number = Math.floor(time / 60);
        let seconds: number = time - minutes * 60;
        return { minutes: minutes, seconds: seconds };
    }
    function updateEnableRoles(checked: boolean) {
        let scenarioClone: Scenario = scenario.clone();
        scenarioClone.settings.enableRoles = checked;
        updateScenario(scenarioClone);
    }

    function updatePublicPrivate(type: ScenarioType) {
        let scenarioClone: Scenario = scenario.clone();
        scenarioClone.type = type;
        updateScenario(scenarioClone);
    }

    return (
        <div className="settings-container">
            <span className="right-editor-title">GamePlay</span>
            <div className="sub-settings">
                <Img className="settings-icon" src={clock}></Img>
                <div className="settings-turn-limit">
                    <input
                        ref={min}
                        placeholder="mm"
                        className="turn-input"
                        type="number"
                        max={99}
                        min={10}
                        onChange={(e) => updateTime()}
                        value={getTime(scenario.settings.turnTimeLimit).minutes}
                    ></input>
                    <span className="time-letter">m</span>
                    <input
                        ref={sec}
                        placeholder="ss"
                        className="turn-input"
                        type="number"
                        max={99}
                        min={10}
                        onChange={(e) => updateTime()}
                        value={getTime(scenario.settings.turnTimeLimit).seconds}
                    ></input>
                    <span className="time-letter">s</span>
                </div>
            </div>
            <div className="sub-settings">
                <Img className="settings-icon" src={avatar}></Img>
                <div className="grid">
                    <span className="settings-subtitle">Min:</span>
                    <input
                        ref={minInput}
                        className="settings-number-input"
                        type="number"
                        placeholder="00"
                        max={99}
                        min={10}
                        onChange={(e) => updateMin(e.target.value)}
                        value={scenario.settings.minPlayers}
                    ></input>
                    <span className="settings-subtitle">Max:</span>
                    <input
                        ref={maxInput}
                        className="settings-number-input"
                        type="number"
                        placeholder="00"
                        max={99}
                        min={10}
                        onChange={(e) => updateMax(e.target.value)}
                        value={scenario.settings.maxPlayers}
                    ></input>
                </div>
            </div>
            <div className="sub-settings">
                <span className="settings-subtitle">Enable roles</span>
                <input
                    className="settings-number-input cursor"
                    type="checkbox"
                    onChange={(e) => updateEnableRoles(e.target.checked)}
                    defaultChecked={scenario.settings.enableRoles}
                ></input>
            </div>
            {globalThis.superAdmin && (
                <div className="sub-settings">
                    <span
                        className={`settings-subtitle scenario-type ${scenario.type === ScenarioType.private ? "underline" : ""}`}
                        onClick={() => updatePublicPrivate(ScenarioType.private)}
                    >
                        Private
                    </span>
                    <span
                        className={`settings-subtitle scenario-type ${scenario.type === ScenarioType.public ? "underline" : ""}`}
                        onClick={() => updatePublicPrivate(ScenarioType.public)}
                    >
                        Public
                    </span>
                    <span
                        className={`settings-subtitle scenario-type ${scenario.type === ScenarioType.template ? "underline" : ""}`}
                        onClick={() => updatePublicPrivate(ScenarioType.template)}
                    >
                        Template
                    </span>
                </div>
            )}
        </div>
    );
}
