enum gameStatus {
  started = 0,
  turnActive = 1,
  turnOver = 2,
  gameOver = 3,
}

export default function IngamePanel(data: any) {
  return (
    <>
      {data.gameStatus === gameStatus.turnActive && <span>Time left: {data.timeRemaining}</span>}
      {data.gameStatus === gameStatus.turnOver && <span>Turn over</span>}
      {data.children}
    </>
  );
}
