import useModalStore from "../stores/modalStore";

export default function CreateNew({ createNewOutcome, createNewRole }: { createNewOutcome: Function; createNewRole: Function }) {
  const [setToggleScoreModal] = useModalStore((state) => [state.setToggleScoreModal]);

  function createRole() {
    createNewRole();
  }
  function createOutcome() {
    createNewOutcome();
  }
  return (
    <div className="add-new-container">
      <span className="editor-subtitle">Create New</span>
      <div className="add-new">
        <div className="add-new-icon-container">
          <button className="add-new-button role-icon" onClick={createRole}></button>
          <span className="add-new-subtitle">Role</span>
        </div>
        <div className="add-new-icon-container">
          <button className="add-new-button outcome-icon" onClick={createOutcome}></button>
          <span className="add-new-subtitle">Outcome</span>
        </div>
      </div>
      <div className="add-new-icon-container">
        <button className="add-new-button score-icon" onClick={() => setToggleScoreModal(true)}></button>
        <span className="add-new-subtitle">Scores</span>
      </div>
    </div>
  );
}
