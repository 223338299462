import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useSwiper, useSwiperSlide } from "swiper/react";
import { useState, useEffect, useRef } from "react";
import Scenario, { Outcome } from "../classes/Scenario";
import { Ball } from "../classes/Ball";
import { useNavigate } from "react-router-dom";
import {
  events,
  responses,
  GetPublicScenarioSessionsRequestData,
  GetPublicScenarioSessionsResponseData,
  JoinPublicScenarioSessionRequestData,
  JoinPublicScenarioSessionResponseData,
} from "../ServerCommunication";
import { GameState } from "../screens/GameScreen";
import { ReactComponent as Lock } from "../assets/svg/lock.svg";
import { popup } from "../functions";
import Img from "./Img";
import useEvents from "../hooks/useEvents";
const requestTimeout: number = 20000;
const ABS_WIDTH = 135;
const ABS_HEIGHT = 100;
function CarouselItem({ scenario, index, color, unlocked }: { scenario: Scenario; index: number; color: string; unlocked: boolean }) {
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();
  const board: any = useRef();
  function joinPublicScenarioSessionRequest() {
    let requestData: JoinPublicScenarioSessionRequestData = {
      event: events.joinPublicScenarioSessionRequest,
      username: globalThis.username,
      userColor: color,
      scenarioId: scenario.id,
    };
    globalThis.socket?.send(JSON.stringify(requestData));
  }

  function slideTo() {
    swiper.slideTo(index);
  }
  return (
    <div style={{ textAlign: "center" }}>
      <span className="carousel-name">{scenario.name}</span>
      {!unlocked && (
        <div
          className="locked"
          onClick={
            swiperSlide.isActive
              ? () => {
                  popup("Scenario Locked!", "#ff0000");
                }
              : slideTo
          }
        >
          <Lock className="lock-icon" />
        </div>
      )}
      <div className="carousel-item" onClick={swiperSlide.isActive ? joinPublicScenarioSessionRequest : slideTo}>
        <div ref={board} className="carousel" style={{ backgroundColor: scenario.settings.boardBackgroundColor }}>
          <CarouselBall ball={scenario.ball} board={board.current} />
          {scenario.outcomes.map((outcome: Outcome, index: number) => {
            return <CarouselOutcome outcome={outcome} board={board.current} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}

function CarouselOutcome({ outcome, board }: { outcome: Outcome; board: any }) {
  useEvents([{ el: window, name: "resize", cb: resize }]);

  const outcomeHTML: any = useRef();
  useEffect(() => {
    window.addEventListener("resize", resize);

    return function cleanup() {
      window.removeEventListener("resize", resize);
    };
  }, []);

  function resize() {
    if (board) {
      outcomeHTML.current.style.left = board.offsetLeft + (outcome.position.x / ABS_WIDTH) * board.offsetWidth + "px";
      outcomeHTML.current.style.top = board.offsetTop + (outcome.position.y / ABS_HEIGHT) * board.offsetHeight + "px";
      outcomeHTML.current.style.width = (outcome.width / ABS_WIDTH) * board.offsetWidth + "px";
      outcomeHTML.current.style.height = (outcome.width / ABS_WIDTH) * board.offsetWidth + "px";
      if (!outcome.image) {
        outcomeHTML.current.style.backgroundColor = outcome.backgroundColor;
      }
    }
  }
  useEffect(() => {
    if (board) {
      outcomeHTML.current.style.left = board.offsetLeft + (outcome.position.x / ABS_WIDTH) * board.offsetWidth + "px";
      outcomeHTML.current.style.top = board.offsetTop + (outcome.position.y / ABS_HEIGHT) * board.offsetHeight + "px";
      outcomeHTML.current.style.width = (outcome.width / ABS_WIDTH) * board.offsetWidth + "px";
      outcomeHTML.current.style.height = (outcome.width / ABS_WIDTH) * board.offsetWidth + "px";
      if (!outcome.image) {
        outcomeHTML.current.style.backgroundColor = outcome.backgroundColor;
      }
    }
  }, [outcome, board]);
  return (
    <>
      {outcome.image ? (
        <Img ref={outcomeHTML} className="carousel-outcome" src={outcome.image}></Img>
      ) : (
        <div ref={outcomeHTML} className="carousel-outcome"></div>
      )}
    </>
  );
}

function CarouselBall({ ball, board }: { ball: Ball; board: any }) {
  useEvents([{ el: window, name: "resize", cb: resize }]);

  const ballHTML: any = useRef();

  useEffect(() => {
    window.addEventListener("resize", resize);
    return function cleanup() {
      window.removeEventListener("resize", resize);
    };
  }, []);

  function resize() {
    if (board) {
      ballHTML.current.style.left = board.offsetLeft + (ball.position.x / ABS_WIDTH) * board.offsetWidth + "px";
      ballHTML.current.style.top = board.offsetTop + (ball.position.y / ABS_HEIGHT) * board.offsetHeight + "px";
      ballHTML.current.style.width = (ball.radius / ABS_WIDTH) * board.offsetWidth * 2 + "px";
      ballHTML.current.style.height = (ball.radius / ABS_WIDTH) * board.offsetWidth * 2 + "px";
      ballHTML.current.style.backgroundColor = ball.backgroundColor;
    }
  }
  useEffect(() => {
    if (board) {
      ballHTML.current.style.left = board.offsetLeft + (ball.position.x / ABS_WIDTH) * board.offsetWidth + "px";
      ballHTML.current.style.top = board.offsetTop + (ball.position.y / ABS_HEIGHT) * board.offsetHeight + "px";
      ballHTML.current.style.width = (ball.radius / ABS_WIDTH) * board.offsetWidth * 2 + "px";
      ballHTML.current.style.height = (ball.radius / ABS_WIDTH) * board.offsetWidth * 2 + "px";
      ballHTML.current.style.backgroundColor = ball.backgroundColor;
    }
  }, [ball, board]);

  return <div ref={ballHTML} className="carousel-ball"></div>;
}

export default function SessionCarousel({ color }: { color: string }) {
  useEvents([
    { el: globalThis.globalEvents, name: events.getPublicScenarioSessionsResponse, cb: getPublicScenarioSessionsResponse },
    { el: globalThis.globalEvents, name: events.joinPublicScenarioSessionResponse, cb: joinPublicScenarioSessionResponse },
    { el: globalThis.globalEvents, name: events.gameStarted, cb: gameStarted },
  ]);

  let navigate = useNavigate();
  let timeout: any = null;
  const [scenarioList, setScenarioList] = useState<{ scenario: Scenario; unlocked: boolean }[]>([]);

  useEffect(() => {
    getScenarioListRequest();
    return function cleanup() {
      clearTimeout(timeout);
    };
  }, []);

  function getPublicScenarioSessionsResponse(data: GetPublicScenarioSessionsResponseData) {
    setScenarioList(data.publicScenarioList);
  }

  function joinPublicScenarioSessionResponse(data: JoinPublicScenarioSessionResponseData) {
    switch (data.response) {
      case responses.unauthorized:
        popup("Scenario Locked!", "#ff0000");
        break;
      case responses.success:
        break;
      case responses.notFound:
        popup("Nout found!", "#ff0000");
        break;
      case responses.serverError:
        popup("Server error!", "#ff0000");
        break;
      default:
        break;
    }
  }

  function gameStarted({ scenario, gameRoomID, gameState }: { scenario: Scenario; gameRoomID: string; gameState: GameState }) {
    navigate("/game", { state: { scenario: scenario, gameRoomID: gameRoomID, gameState: gameState } });
  }

  function getScenarioListRequest() {
    if (globalThis.online) {
      let requestData: GetPublicScenarioSessionsRequestData = {
        event: events.getPublicScenarioSessionsRequest,
      };
      globalThis.socket?.send(JSON.stringify(requestData));
      timeout = setTimeout(() => {
        getScenarioListRequest();
      }, requestTimeout);
    } else {
      timeout = setTimeout(() => {
        getScenarioListRequest();
      }, 1000);
    }
  }

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div className="public-session-title">Public Sessions</div>
      <Swiper
        spaceBetween={0}
        slidesPerView={3}
        centeredSlides={true}
        onSlideChange={() => {}}
        onSwiper={(swiper) => {}}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="carousel-container"
      >
        {scenarioList.map((item: { scenario: Scenario; unlocked: boolean }, index: number) => {
          return (
            <SwiperSlide key={index}>
              <CarouselItem scenario={item.scenario} key={index} index={index} color={color} unlocked={item.unlocked} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
