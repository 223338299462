import { Point, generateRandomColor } from "../utils";
import { Ball } from "./Ball";
import * as uuid from "uuid";
import { ScenarioType, responses } from "../ServerCommunication";
import { ScoreBoard } from "./ScoreBoard";
export class ScenarioSettings {
  enableRoles: boolean = false;
  turnTimeLimit: number = 15;
  maxForce: number = 30;
  minPlayers: number = 0;
  maxPlayers: number = 4;
  boardBackgroundColor: string = "#ffffff";
}
export class ScoreTable {
  teamScores: Record<string, Record<string, number>> = {};
  roleScores: Record<string, Record<string, number>> = {};

  scoreCurrencies: string[] = [];

  renameScoreCurrency(oldName: string, newName: string) {
    if (this.scoreCurrencies.includes(newName)) {
      return responses.duplicate;
    }
    for (let i = 0; i < this.scoreCurrencies.length; i++) {
      if (this.scoreCurrencies[i] === oldName) {
        this.scoreCurrencies[i] = newName;
        this.teamScores[newName] = this.teamScores[oldName];
        this.roleScores[newName] = this.roleScores[oldName];
        delete this.teamScores[oldName];
        delete this.roleScores[oldName];
        return responses.success;
      }
    }
    return responses.notFound;
  }
  addScoreCurrency(scoreCurrency: string) {
    let response: responses = responses.failure;
    if (this.scoreCurrencies.includes(scoreCurrency) === false) {
      this.scoreCurrencies.push(scoreCurrency);
      this.teamScores[scoreCurrency] = {};
      this.roleScores[scoreCurrency] = {};
      response = responses.success;
    } else {
      response = responses.duplicate;
    }
    return response;
  }
  removeScoreCurrency(scoreCurrencyName: string) {
    let response = responses.failure;
    let index = this.scoreCurrencies.findIndex((sc) => sc === scoreCurrencyName);
    if (index > -1) {
      this.scoreCurrencies.splice(index, 1);
      delete this.teamScores[scoreCurrencyName];
      delete this.roleScores[scoreCurrencyName];
      response = responses.success;
    }
    return response;
  }
  setTeamScoreForOutcome(scoreCurrency: string, outcome: Outcome, score: number) {
    this.teamScores[scoreCurrency][outcome.id] = score;
  }
  getTeamScoreForOutcome(scoreCurrency: string, outcome: Outcome) {
    if (scoreCurrency in this.teamScores && outcome.id in this.teamScores[scoreCurrency]) {
      return this.teamScores[scoreCurrency][outcome.id];
    } else {
      return 0;
    }
  }
  setRoleScoreForOutcome(scoreCurrency: string, role: Role, outcome: Outcome, score: number) {
    let key = [role.title, outcome.id].toString();
    this.roleScores[scoreCurrency][key] = score;
  }
  getRoleScoreForOutcome(scoreCurrency: string, role: Role, outcome: Outcome) {
    let key = [role.title, outcome.id].toString();
    if (scoreCurrency in this.roleScores && key in this.roleScores[scoreCurrency]) {
      return this.roleScores[scoreCurrency][key];
    } else {
      return 0;
    }
  }
  static createFromJSON(o: ScoreTable) {
    if (o != null) {
      let scoreTable = new ScoreTable();
      scoreTable.scoreCurrencies = o.scoreCurrencies;
      scoreTable.teamScores = o.teamScores;
      scoreTable.roleScores = o.roleScores;

      return scoreTable;
    } else {
      let warning = "Warning: Bad data. Scenario's ScoreTable is undefined.";
      console.warn(warning);

      return new ScoreTable(); //return the default ScoreTable if the data is undefined, this should slowly fill up this field of old records
    }
  }
}
export default class Scenario {
  id: string;
  name: string;
  outcomes: Outcome[];
  roles: Role[];
  ball: Ball;
  settings: ScenarioSettings = new ScenarioSettings();
  type: ScenarioType;
  ownerEmail: string;
  createdDate = Date.now();
  modifiedDate = Date.now();
  scoreTable: ScoreTable;

  constructor(
    id: string,
    name: string,
    outcomes: Outcome[],
    roles: Role[],
    Ball: Ball,
    ownerEmail: string,
    type: ScenarioType,
    settings: ScenarioSettings,
    scoreTable: ScoreTable
  ) {
    this.id = id;
    this.name = name;
    this.outcomes = outcomes;
    this.roles = roles;
    this.ball = Ball;
    this.settings = settings;
    this.type = type;
    this.ownerEmail = ownerEmail;
    this.scoreTable = scoreTable;
  }
  clone() {
    //WARNING: internal arrays are not deep copied, they are the same references
    let cloned = new Scenario(this.id, this.name, this.outcomes, this.roles, this.ball, this.ownerEmail, this.type, this.settings, this.scoreTable);
    return cloned;
  }
  getRoleByID(roleID: string) {
    for (let i = 0; i < this.roles.length; i++) {
      if (this.roles[i].id === roleID) {
        return this.roles[i];
      }
    }
    return null;
  }
  renameScoreCurrency(oldName: string, newName: string) {
    let response = this.scoreTable.renameScoreCurrency(oldName, newName);
    // if renaming was successful, make sure to rename the scorecurrency in all outcome condition definitions as well
    if (response === responses.success) {
      for (let i = 0; i < this.outcomes.length; i++) {
        let condition = this.outcomes[i].outcomeCondition;
        if (
          condition instanceof TeamScoreOutcomeCondition ||
          condition instanceof RoleScoreOutcomeCondition ||
          condition instanceof PlayerScoreOutcomeCondition
        ) {
          if (condition.scoreCurrency === oldName) {
            condition.scoreCurrency = newName;
          }
        }
      }
    }
    return response;
  }
  addScoreCurrency(scoreCurrency: string) {
    return this.scoreTable.addScoreCurrency(scoreCurrency);
  }
  removeScoreCurrency(scoreCurrencyName: string) {
    let response = this.scoreTable.removeScoreCurrency(scoreCurrencyName);
    // if removal was successful, make sure to remove outcome conditions that used this scorecurrency
    if (response === responses.success) {
      for (let i = 0; i < this.outcomes.length; i++) {
        let condition = this.outcomes[i].outcomeCondition;
        if (
          condition instanceof TeamScoreOutcomeCondition ||
          condition instanceof RoleScoreOutcomeCondition ||
          condition instanceof PlayerScoreOutcomeCondition
        ) {
          if (condition.scoreCurrency === scoreCurrencyName) {
            this.outcomes[i].outcomeCondition = new NoOutcomeCondition();
          }
        }
      }
    }
  }
}

export type OutcomeConditionBehavior = "lock" | "hide" | "none";
export type OutcomeConditionType = "NoOutcomeCondition" | "TeamScoreOutcomeCondition" | "PlayerScoreOutcomeCondition" | "RoleScoreOutcomeCondition";
export abstract class OutcomeCondition {
  behavior: OutcomeConditionBehavior;
  type: OutcomeConditionType;
  constructor(type: OutcomeConditionType, behavior: OutcomeConditionBehavior) {
    this.behavior = behavior;
    this.type = type;
  }
  isFulfilled(scoreBoard: ScoreBoard): boolean {
    return true;
  }
}

export class NoOutcomeCondition extends OutcomeCondition {
  constructor() {
    super("NoOutcomeCondition", "none");
  }
  isFulfilled(scoreBoard: ScoreBoard): boolean {
    return true;
  }
}
export class TeamScoreOutcomeCondition extends OutcomeCondition {
  minimumScore: number;
  scoreCurrency: string;
  constructor(behavior: "lock" | "hide", minimumScore: number, scoreCurrency: string) {
    super("TeamScoreOutcomeCondition", behavior);
    this.minimumScore = minimumScore;
    this.scoreCurrency = scoreCurrency;
  }
  isFulfilled(scoreBoard: ScoreBoard) {
    if (scoreBoard.scoreCurrencies.includes(this.scoreCurrency) === false || scoreBoard.teamScore[this.scoreCurrency] == null) {
      //console.log(`TeamScoreOutcomeCondition: the score ${this.scoreCurrency} is not among the provided score board's score currencies.`);
      return false;
    }
    if (scoreBoard.teamScore[this.scoreCurrency] >= this.minimumScore) {
      return true;
    } else {
      return false;
    }
  }
}
export class PlayerScoreOutcomeCondition extends OutcomeCondition {
  minimumScore: number;
  scoreCurrency: string;
  constructor(behavior: "lock" | "hide", minimumScore: number, scoreCurrency: string) {
    super("PlayerScoreOutcomeCondition", behavior);
    this.minimumScore = minimumScore;
    this.scoreCurrency = scoreCurrency;
  }
  isFulfilled(scoreBoard: ScoreBoard) {
    if (scoreBoard.scoreCurrencies.includes(this.scoreCurrency) === false || scoreBoard.playerScores[this.scoreCurrency] == null) {
      //console.log(`PlayerScoreOutcomeCondition: the score ${this.scoreCurrency} is not among the provided score board's score currencies.`);
      return false;
    }
    let playerScores = scoreBoard.playerScores[this.scoreCurrency];
    let minimumScoreReached = false;
    for (const player in playerScores) {
      if (playerScores[player] >= this.minimumScore) {
        minimumScoreReached = true;
        break;
      }
    }
    return minimumScoreReached;
  }
}
export class RoleScoreOutcomeCondition extends OutcomeCondition {
  minimumScore: number;
  scoreCurrency: string;
  roleTitle: string;
  constructor(behavior: "lock" | "hide", minimumScore: number, scoreCurrency: string, roleTitle: string) {
    super("RoleScoreOutcomeCondition", behavior);
    this.minimumScore = minimumScore;
    this.scoreCurrency = scoreCurrency;
    this.roleTitle = roleTitle;
  }
  isFulfilled(scoreBoard: ScoreBoard) {
    if (
      scoreBoard.scoreCurrencies.includes(this.scoreCurrency) === false ||
      scoreBoard.roleScores[this.scoreCurrency] == null ||
      scoreBoard.roleScores[this.scoreCurrency][this.roleTitle] == null
    ) {
      //console.log(`RoleScoreOutcomeCondition: the score ${this.scoreCurrency} is not among the provided score board's score currencies.`);
      return false;
    }
    return scoreBoard.roleScores[this.scoreCurrency][this.roleTitle] >= this.minimumScore;
  }
}

export class Outcome {
  id: string;
  title: string;
  description: string;
  endgameText: string;
  image: string | null;
  position: Point;
  width: number;
  height: number;

  backgroundColor: string;
  chainedScenarioId?: string;
  outcomeCondition: OutcomeCondition;

  constructor(
    id: string,
    title: string,
    description: string,
    position: Point,
    width: number,
    height: number,
    backgroundColor: string = generateRandomColor(),
    image: string | null = null,
    endgameText = "",
    chainedScenarioId?: string,
    outcomeCondition: OutcomeCondition = new NoOutcomeCondition()
  ) {
    this.title = title;
    this.description = description;
    this.endgameText = endgameText;
    this.position = position; // this is the center position
    this.width = width;
    this.height = height;
    this.backgroundColor = backgroundColor;
    this.image = image;
    this.id = id;
    this.chainedScenarioId = chainedScenarioId;
    this.outcomeCondition = outcomeCondition;
  }
  isPointWithin(point: Point) {
    if (
      point.x >= this.position.x - this.width / 2 &&
      point.x <= this.position.x + this.width / 2 &&
      point.y >= this.position.y - this.height / 2 &&
      point.y <= this.position.y + this.height / 2
    ) {
      return true;
    } else {
      return false;
    }
  }
  static createFromJSON(o: Outcome) {
    let outcomeCondition;
    let ooc = o.outcomeCondition;
    if (ooc != null) {
      if (ooc.type === "NoOutcomeCondition") {
        outcomeCondition = new NoOutcomeCondition();
      } else if (ooc.type === "PlayerScoreOutcomeCondition") {
        let a = ooc as PlayerScoreOutcomeCondition;
        outcomeCondition = new PlayerScoreOutcomeCondition(a.behavior as any, a.minimumScore, a.scoreCurrency);
      } else if (ooc.type === "RoleScoreOutcomeCondition") {
        let a = ooc as RoleScoreOutcomeCondition;
        outcomeCondition = new RoleScoreOutcomeCondition(a.behavior as any, a.minimumScore, a.scoreCurrency, a.roleTitle);
      } else if (ooc.type === "TeamScoreOutcomeCondition") {
        let a = ooc as TeamScoreOutcomeCondition;
        outcomeCondition = new TeamScoreOutcomeCondition(a.behavior as any, a.minimumScore, a.scoreCurrency);
      }
    }
    return new Outcome(
      o.id,
      o.title,
      o.description,
      o.position,
      o.width,
      o.height,
      o.backgroundColor,
      o.image,
      o.endgameText,
      o.chainedScenarioId,
      outcomeCondition
    );
  }
}

export class Role {
  id: string;
  title: string;
  description: string;
  idealOutcomes: string[];
  settleOutcomes: string[];
  failureOutcomes: string[];
  backgroundColor: string;
  image: string | null;
  constructor(
    id: string,
    title: string,
    description: string,
    idealOutcomes: string[],
    settleOutcomes: string[],
    failureOutcomes: string[],
    backgroundColor: string = generateRandomColor(),
    image: string | null = null
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.idealOutcomes = idealOutcomes;
    this.settleOutcomes = settleOutcomes;
    this.failureOutcomes = failureOutcomes;
    this.backgroundColor = backgroundColor;
    this.image = image;
  }
}
export enum scenarioElementType {
  outcome,
  ball,
  role,
  settings,
  nothing,
}
export class ScenarioElement {
  element: Outcome | Role | Ball | null;
  type: scenarioElementType;

  constructor(element: Outcome | Role | Ball | null, elementType: scenarioElementType) {
    this.element = element;
    this.type = elementType;
  }
}
export class RoleOutcomesSelector {
  selectedOutcomes: string[];
  role: Role;
  outcomeCategory: string;
  constructor(selectedOutcomes: string[], role: Role, outcomeCategory: string) {
    this.selectedOutcomes = selectedOutcomes;
    this.role = role;
    this.outcomeCategory = outcomeCategory;
  }
}
