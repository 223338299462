export function resize() {
  let element: any = document.getElementsByClassName("App")[0];
  let container: any = document.getElementsByClassName("container")[0];
  element.style.height = window.innerHeight + "px";
  element.style.width = window.innerWidth + "px";
  container.style.maxHeight = window.innerHeight * 0.95 + "px";
  container.style.maxWidth = window.innerWidth * 0.95 + "px";

  if (window.innerHeight / 9 >= window.innerWidth / 16) {
    document.documentElement.style.setProperty("--size", window.innerWidth / 16 / 5 + "px");
  } else {
    document.documentElement.style.setProperty("--size", window.innerHeight / 9 / 5 + "px");
  }
}

export function popup(content: string, color: string, duration?: number) {
  globalThis.globalEvents.triggerEvent("triggerPopup", JSON.stringify({ content: content, color: color, duration: duration || 2000 }));
}
export var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function () {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
  },
};

export function getMousePos(e: any) {
  var rect = document.getElementsByClassName("container")[0].getBoundingClientRect();
  if (rect !== undefined) {
    if (isMobile.any()) {
      return {
        x: e.touches[0].clientX - rect.left,
        y: e.touches[0].clientY - rect.top,
      };
    } else {
      return {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
    }
  }
}

export function sendMessage(data: any) {
  globalThis.socket?.send(JSON.stringify(data));
}

export function containsOnlySpaces(str: string) {
  return str.trim().length === 0;
}
