import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { events, responses, playerType } from "../ServerCommunication";
import { useNavigate } from "react-router-dom";
import GameSetup from "../components/GameSetup";
import Players from "../components/Players";
import SettingsPanel from "../components/SettingsPanel";
import { popup } from "../functions";
import OutcomesPanel from "../components/OutcomesPanel";
import OutcomePanel from "../components/OutcomePanel";
import Scenario, { Outcome, ScenarioSettings, ScoreTable } from "../classes/Scenario";
import MousePanel from "../components/MousePanel";
import { config } from "../config";
import useModalStore from "../stores/modalStore";
import { ScoreInfoBox } from "./GameScreen";
import useEvents from "../hooks/useEvents";

//const ABS_WIDTH = 135;
//const ABS_HEIGHT = 100;
var joinLobbyID: string | null = null;
var joinInviteCode: string | null = null;

export class PlayerData {
  username: string | null;
  userColor: string | null;
  selectedRoleId: string | null | undefined;
  playerType: playerType;
  playerId: string;

  constructor(username: string | null, userColor: string | null, selectedRoleId: string | null | undefined, playerType: playerType, playerId: string) {
    this.username = username;
    this.userColor = userColor;
    this.selectedRoleId = selectedRoleId;
    this.playerType = playerType;
    this.playerId = playerId;
  }
}
export default function LobbyScreen() {
  const ballHTML: any = useRef();

  // const inviteCode: any = data.inviteCode;
  let location: any = useLocation();
  let navigate = useNavigate();
  // const scenario: Scenario = location.state?.scenario;
  const [scenario, setScenario] = useState<any>(null);
  const [inviteCode] = useState<string>(location.state?.inviteCode ? location.state.inviteCode : null);
  const [lobbyID] = useState<string>(location.state?.lobbyID && location.state.lobbyID);
  const [playersDataDisplay, setPlayersDataDisplay] = useState<Array<any>>([]);
  const [selectedTab, setSelectedTab] = useState<string>("users");
  const [roles, setRoles] = useState<any>();
  const [scenarioOutcomes, setScenarioOutcomes] = useState<Outcome[]>([]);
  const [settings, setSettings] = useState<ScenarioSettings>(new ScenarioSettings());
  const [bgColor, setBgColor] = useState<string>("#ffffff");
  const [isFacilitator, setIsFacilitator] = useState<boolean>(false);
  const [setTeamScore, setLobbyScores] = useModalStore((state) => [state.setTeamScore, state.setLobbyScores]);
  const startEnabled = useRef<boolean>(true);
  const localScenario = useRef<Scenario | null>(null);

  useEvents([
    { el: globalThis.globalEvents, name: events.lobbyUpdate, cb: lobbyUpdate },
    { el: globalThis.globalEvents, name: events.lobbyUpdateResponse, cb: lobbyUpdate },
    { el: globalThis.globalEvents, name: events.leaveLobbyResponse, cb: leaveLobbyResponse },
    { el: globalThis.globalEvents, name: events.lobbyClosed, cb: lobbyClosed },
    { el: globalThis.globalEvents, name: events.selectRoleResponse, cb: selectRoleResponse },
    { el: globalThis.globalEvents, name: events.startGameResponse, cb: startGameResponse },
    { el: globalThis.globalEvents, name: events.gameStarted, cb: gameStarted },
    { el: globalThis.globalEvents, name: events.getScenarioResponse, cb: getScenarioResponse },
    { el: globalThis.globalEvents, name: events.updateScenarioSettingsResponse, cb: updateScenarioSettingsResponse },
    { el: globalThis.globalEvents, name: events.joinLobbyResponse, cb: joinGameResponse },
    { el: window, name: "resize", cb: resize },
  ]);

  useEffect(() => {
    lobbyUpdateRequest();
  }, []);

  function lobbyChangeScenarioResponse(data: any) {
    console.log(data);
  }

  /* useEffect(() => {
        var left = timeLeft;
        setTimeLeft(left--);
        //}, 1000);
    }, [timeLeft]);*/

  function resize() {
    if (localScenario.current) {
      let canvas: any = document.getElementsByClassName("outcomes-container")[0];
      ballHTML.current.style.left = canvas.offsetLeft + (localScenario.current.ball.position.x / 135) * canvas.offsetWidth + "px";
      ballHTML.current.style.top = canvas.offsetTop + (localScenario.current.ball.position.y / 100) * canvas.offsetHeight + "px";
      ballHTML.current.style.width = (localScenario.current.ball.radius / 135) * canvas.offsetWidth * 2 + "px";
      ballHTML.current.style.height = (localScenario.current.ball.radius / 135) * canvas.offsetWidth * 2 + "px";
    }
  }

  function updateScenarioSettingsResponse(data: any) {
    // console.log(data);
  }
  useEffect(() => {
    if (scenario) {
      setBgColor(scenario.settings.boardBackgroundColor);
      let canvas: any = document.getElementsByClassName("outcomes-container")[0];
      ballHTML.current.style.left = canvas.offsetLeft + (scenario.ball.position.x / 135) * canvas.offsetWidth + "px";
      ballHTML.current.style.top = canvas.offsetTop + (scenario.ball.position.y / 100) * canvas.offsetHeight + "px";
      ballHTML.current.style.width = (scenario.ball.radius / 135) * canvas.offsetWidth * 2 + "px";
      ballHTML.current.style.height = (scenario.ball.radius / 135) * canvas.offsetWidth * 2 + "px";
      localScenario.current = scenario;
    } else {
      localScenario.current = null;
    }
  }, [scenario]);

  function gameStarted(data: any) {
    //console.log(data);
    navigate("/game", { state: { scenario: data.scenario, gameRoomID: data.gameRoomID, gameState: data.gameState } });
  }
  function getScenarioResponse(data: any) {
    /*let tempScenario = new Scenario(
      data.scenario.id,
      data.scenario.name,
      data.scenario.outcomes,
      data.scenario.roles,
      data.scenario.ball,
      data.scenario.ownerEmail,
      data.scenario.type,
      data.scenario.settings,
      data.scenario.scoreTable
    );*/
    // console.log("data:", data);
    setBgColor(data.scenario.settings.boardBackgroundColor);
    // internalScenario = tempScenario;
    // setScenario(internalScenario);
  }
  function joinGameResponse(data: any) {
    joinLobbyID = data.lobbyID;
    joinInviteCode = data.inviteCode;
  }

  /*function resetLobby() {
        clearInterval(game.countdown);
        game.countdown = null;
        game = new DefaultVariables();
        setStarted(false);
        setSelectedTab("users");
        setWinnerOutcome(null);
    }*/

  function selectRoleResponse(data: any) {
    switch (data.response) {
      case responses.roleAlreadySelected:
        popup("Role already selected!", "red");
        break;
      case responses.onlyActorsCanSelectRoles:
        popup("Only actors can select roles!", "red");
        break;
      default:
        break;
    }
  }

  function lobbyClosed(data: any) {
    if (data.reason === "gameRoom started") {
      //nemkell
    } else if (data.reason === "Facilitator has closed the lobby.") {
      popup("Lobby has been closed!", "red");
      navigate("/");
    }
    //  game.gameActive = false;
    //globalThis.globalEvents.triggerEvent(events.triggerPopup);
    //console.log("popup triggered");
    //navigate("/");
  }

  function leaveGameRequest() {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.leaveLobbyRequest,
        lobbyID: lobbyID,
      })
    );
  }
  useEffect(() => {
    globalThis.isFacilitator = isFacilitator;
  }, [isFacilitator]);

  function leaveLobbyResponse(data: any) {
    // console.log(data);
    switch (data.response) {
      case responses.success:
        // game.gameActive = false;
        if (globalThis.isFacilitator) {
          navigate("/menu");
        } else {
          navigate("/");
        }
        break;
      default:
        break;
    }
  }

  function lobbyUpdate(data: any) {
    setPlayersDataDisplay(data.playersData);
    if (data.scenario) {
      setRoles(data.scenario.roles);
      setScenario(data.scenario);
      setScenarioOutcomes(data.scenario.outcomes);
      console.log("setting scenario", data.scenario);
    }
    setTeamScore(data.teamScore);
    setLobbyScores(data.scoreBoard);
    /* game.playersData = data.playersData;
        for (let j = 0; j < game.playersData.length; j++) {
            if (game.playersData[j].username === globalThis.username && game.playersData[j].playerType === playerType.facilitator) {
                game.isFacilitator = true;
            }
        }
        setBgColor(data.scenario.settings.boardBackgroundColor);
        setRoles(data.scenario.roles);
        let newSettings: ScenarioSettings = data.scenario.settings;
        setSettings(newSettings);
        console.log(data);
        game.scenario = data.scenario;
        //  game.scenario!.outcomes = [];
        let newOutcomes = data.scenario.outcomes;
        for (let i = 0; i < newOutcomes.length; i++) {
            let outcome = newOutcomes[i];
            game.scenario!.outcomes[i] = new Outcome(
                outcome.id,
                outcome.title,
                outcome.description,
                outcome.position,
                outcome.width,
                outcome.height,
                outcome.backgroundColor,
                outcome.image
            );
        }
        game.scenario.outcomes = newOutcomes;
        console.log(game.scenario.outcomes);
        setScenarioOutcomes(newOutcomes);
        setPlayersDataDisplay(game.playersData);
        let ball: Ball = new Ball(
            new Point(data.scenario.ball.position.x, data.scenario.ball.position.y),
            data.scenario.ball.radius,
            data.scenario.ball.backgroundColor,
            data.scenario.ball.frictionF
        );
        if (game.ctx) game.ballVF = new BallVF(ball, game.ctx.canvas);
        if (game.scenario) game.scenario.ball = ball;
        */
  }

  useEffect(() => {
    for (let i = 0; i < playersDataDisplay.length; i++) {
      if (globalThis.username === playersDataDisplay[i].username && playersDataDisplay[i].playerType === playerType.facilitator) {
        setIsFacilitator(true);
      }
    }
    globalThis.playersData = playersDataDisplay;
  }, [playersDataDisplay]);

  function lobbyUpdateRequest() {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.lobbyUpdateRequest,
        lobbyID: lobbyID,
      })
    );
  }

  function selectNewTab(tab: string) {
    setSelectedTab(tab);
    const users_button = document.getElementById("users_button");
    const roles_button = document.getElementById("roles_button");
    const settings_button = document.getElementById("settings_button");
    users_button?.classList.remove("selected");
    roles_button?.classList.remove("selected");
    settings_button?.classList.remove("selected");
    switch (tab) {
      case "users":
        users_button?.classList.add("selected");
        break;
      case "roles":
        roles_button?.classList.add("selected");
        break;
      case "settings":
        settings_button?.classList.add("selected");
        break;
      default:
        break;
    }
  }

  function startGameRequest() {
    if (startEnabled.current) {
      startEnabled.current = false;
      globalThis.socket?.send(
        JSON.stringify({
          event: events.startGameRequest,
          lobbyID: lobbyID,
        })
      );
    }
  }

  function startGameResponse(data: any) {
    startEnabled.current = true;
    switch (data.response) {
      case responses.unauthorized:
        popup("User unauthorized!", "red");
        break;
      case responses.lobbyNotFound:
        popup("Lobby not found!", "red");
        break;
      case responses.notAllPlayersSelectedRoles:
        popup("Not all players selected roles!", "red");
        break;
      case responses.notEnoughPlayers:
        popup(
          "Not enough players in the lobby to start the game. This scenario requires at least " +
            // String(game.scenario!.settings.minPlayers) +
            " players.",
          "red"
        );
        break;
      default:
        break;
    }
    /*switch (key) {
            case value:
                break;

            default:
                break;
        }*/
  }
  /* function copyInviteCode(inviteCode: string) {
        navigator.clipboard.writeText(inviteCode);
        popup("Invite code copied to clipboard", "rgb(0,128,0)");
    }*/

  function copyToClipboard(textToCopy: string, alert: string) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      //  navigator clipboard api method'
      popup(alert, "rgb(0,128,0)");
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise(() => {
        // here the magic happens
        document.execCommand("copy") ? popup(alert, "rgb(0,128,0)") : popup("Failed to copy invite code", "rgb(255,0,0)");
        textArea.remove();
      });
    }
  }

  return (
    <>
      <ScoreInfoBox />
      {config.mouseShare && <MousePanel lobbyId={lobbyID} />}
      <div className="game-canvas-container">
        <div className="overlay" style={{ backgroundColor: bgColor }}></div>
        <OutcomesPanel>
          {scenarioOutcomes.map((outcome: any, index: any) => {
            return (
              <OutcomePanel
                position={outcome.position}
                size={outcome.height}
                title={outcome.title}
                description={outcome.description}
                key={index}
                image={outcome.image}
                backgroundColor={outcome.backgroundColor}
                playersData={playersDataDisplay}
                roles={roles}
                scoreTable={ScoreTable.createFromJSON(scenario.scoreTable)}
                outcome={outcome}
              />
            );
          })}
        </OutcomesPanel>
        <div ref={ballHTML} className="lobby-ball">
          {scenario && <div style={{ backgroundColor: scenario.ball.backgroundColor }} className="lobby-ball-visual"></div>}
        </div>
        <canvas id="canvas"></canvas>
      </div>
      <div className="game-setup-container">
        <div className="game-setup">
          <>
            <div className="tab-buttons">
              <button id="users_button" className="tab-button selected" onClick={() => selectNewTab("users")}>
                Users{`(${playersDataDisplay.length})`}
              </button>
              {scenario && scenario.settings.enableRoles && (
                <button id="roles_button" className="tab-button" onClick={() => selectNewTab("roles")}>
                  Roles
                </button>
              )}
              {isFacilitator && (
                <button id="settings_button" className="tab-button" onClick={() => selectNewTab("settings")}>
                  Scenario
                </button>
              )}
            </div>
            {selectedTab === "roles" && <GameSetup roles={roles} lobbyID={lobbyID} outcomes={scenarioOutcomes} playersDataDisplay={playersDataDisplay} />}
            {selectedTab === "users" && <Players playersData={playersDataDisplay} leaveGameRequest={leaveGameRequest} roles={roles} />}
            {selectedTab === "settings" && (
              <>
                {isFacilitator && (
                  <SettingsPanel
                    inviteCode={inviteCode}
                    copyToClipboard={copyToClipboard}
                    startGameRequest={startGameRequest}
                    lobbyID={lobbyID}
                    scenario={scenario}
                    playersData={playersDataDisplay}
                  />
                )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
}

/*   <div className="game-setup">
                    {inviteCode && <span>Invite code: {inviteCode}</span>}
                    {playersData.map((user, index) => (
                        <span style={{ color: user.userColor }} key={index}>
                            {user.username}
                        </span>
                    ))}
                    <button onClick={leaveGameRequest}>Leave</button>
                    <button onClick={gameStateSyncResponse}>Sync</button>
                </div>*/
/*{inviteCode && <span>Invite code: {inviteCode}</span>}
{playersData.map((user, index) => (
    <span style={{ color: user.userColor }} key={index}>
        {user.username}
    </span>
))}
<button onClick={leaveGameRequest}>Leave</button>*/
