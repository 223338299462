import Scenario, { Role, ScenarioElement, scenarioElementType } from "../classes/Scenario";
import Img from "./Img";
export default function RoleSelector({ scenario, setSelectedElement }: { scenario: Scenario; setSelectedElement: Function }) {
    function newRole(role: Role) {
        let newRole = new ScenarioElement(role, scenarioElementType.role);
        setSelectedElement(newRole);
    }
    return (
        <div className="role-selector">
            <span className="right-editor-title">Roles</span>
            <div className="roles-container">
                {scenario.roles.map(function (role: Role, index: number) {
                    return (
                        <div className="role-selector-role-container" key={index}>
                            {role.image ? (
                                <Img key={index} src={role.image} className="role-selector-role" onClick={(e: any) => newRole(role)}></Img>
                            ) : (
                                <div
                                    key={index}
                                    className="role-selector-role color-role"
                                    style={{ backgroundColor: role.backgroundColor }}
                                    onClick={(e) => newRole(role)}
                                ></div>
                            )}
                            <span className="role-name">{role.title}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
