import useModalStore from "../stores/modalStore";

export default function ConfirmModal() {
  const [confirmModal, setConfirmModal] = useModalStore((state) => [state.confirmModal, state.setConfirmModal]);

  function onConfirm() {
    confirmModal && confirmModal.onConfirm && confirmModal.onConfirm();
    setConfirmModal(null);
  }

  return (
    <>
      {confirmModal && (
        <div className="confirm-modal-container">
          <div className="confirm-modal">
            <div className="confirm-modal-title">{confirmModal.title ? confirmModal.title : "Are you sure?"}</div>
            {confirmModal.text && <div className="confirm-modal-text">{confirmModal.text}</div>}
            <div className="confirm-modal-buttons">
              <button onClick={() => setConfirmModal(null)} className="login-button">
                {confirmModal.cancelText ? confirmModal.cancelText : "Cancel"}
              </button>
              <button onClick={onConfirm} className="login-button">
                {confirmModal.confirmText ? confirmModal.confirmText : "Yes"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
