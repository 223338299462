import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { events, responses, playerType, LeaveGameRoomRequestData, LeaveGameRoomResponseData, ScenarioType } from "../ServerCommunication";
import { Ball, BallVF } from "../classes/Ball";
import GameSetup from "../components/GameSetup";
import IngamePanel from "../components/IngamePanel";
import Players from "../components/Players";
import { Vector, Point } from "../utils";
import { popup, isMobile, getMousePos } from "../functions";
import OutcomesPanel from "../components/OutcomesPanel";
import OutcomePanel from "../components/OutcomePanel";
import { Animations } from "../scripts/Animations";
import Scenario, { Outcome, ScenarioSettings, ScoreTable } from "../classes/Scenario";
import useModalStore from "../stores/modalStore";
import { GameRoomType } from "../constants";
import { ScoreBoard } from "../classes/ScoreBoard";
const ABS_WIDTH = 135;
const ABS_HEIGHT = 100;
var turnAnimationFinished: boolean = false;
let actionArrow: Vector | null = null;
let startTime: any = null;
class DefaultVariables {
  canvas: HTMLCanvasElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;
  mouseDown = false;
  // animationRunning = false;
  ballVF: BallVF | null = null;
  mousePos: any = {};
  lastUpdate: number = Date.now();
  arrowVector: Vector = new Vector(0, 0);
  gameRoomID: string = "";
  gameActive: boolean = false;
  playersData: any[] = [];
  totalForce: Vector = new Vector(0, 0);
  startVector: Vector = new Vector(0, 0);
  gameState: GameState | null = null;
  arrowAnimationDuration = 2000;
  scenario: Scenario = new Scenario("", "", [], [], new Ball(new Point(0, 0), 0, "red", 5), "", ScenarioType.private, new ScenarioSettings(), new ScoreTable());
  AnimationContainer: Animations | null = null;
  isFacilitator: boolean = false;
  countdown: any = null;
}

var game: DefaultVariables;

enum gameStatus {
  started = 0,
  turnActive = 1,
  turnOver = 2,
  gameOver = 3,
}

export class PlayerData {
  username: string | null;
  userColor: string | null;
  selectedRoleId: string | null | undefined;
  playerType: playerType;
  playerId: string;

  constructor(username: string | null, userColor: string | null, selectedRoleId: string | null | undefined, playerType: playerType, playerId: string) {
    this.username = username;
    this.userColor = userColor;
    this.selectedRoleId = selectedRoleId;
    this.playerType = playerType;
    this.playerId = playerId;
  }
}
export class GameState {
  ball: Ball;
  turnTimeLeft: number;
  gameStatus: gameStatus;
  playersData: PlayerData[];
  constructor(ball: Ball, turnTimeLeft: number, gameStatus: gameStatus, playersData: PlayerData[]) {
    this.ball = ball;
    this.turnTimeLeft = turnTimeLeft;
    this.gameStatus = gameStatus;
    this.playersData = playersData;
  }
  updateState(newGameState: GameStateUpdate) {
    if (newGameState.ball != null) {
      this.ball.v = new Vector(newGameState.ball.v.x, newGameState.ball.v.y);
      this.ball.frictionF = newGameState.ball.frictionF;
      this.ball.position = new Point(newGameState.ball.position.x, newGameState.ball.position.y);
      this.ball.radius = newGameState.ball.radius;
    }
    if (newGameState.turnTimeLeft != null) {
      this.turnTimeLeft = newGameState.turnTimeLeft;
    }
    if (newGameState.gameStatus != null) {
      this.gameStatus = newGameState.gameStatus;
    }
    if (newGameState.playersData != null) {
      this.playersData = newGameState.playersData;
    }
    //if debug issues occur, read below
    //because types are not preserved for the key, this may have issues if the incoming object has other types of keys. See misc for more explanation
    //const obj = {};
    //Object.keys(newGameState).forEach((_key) => {
    //    const key = _key as keyof typeof obj;
    //    if (newGameState[key] != null) {
    //        this[key] = newGameState[key];
    //    }
    //});
  }
}
class GameStateUpdate {
  ball: Ball | null;
  turnTimeLeft: number | null;

  gameStatus: gameStatus | null;
  playersData: PlayerData[] | null;
  constructor({
    ball = null,
    turnTimeLeft = null,
    gameStatus = null,
    playersData = null,
  }: {
    ball?: Ball | null;
    turnTimeLeft?: number | null;
    gameStatus?: gameStatus | null;
    playersData?: PlayerData[] | null;
  }) {
    this.ball = ball;
    this.turnTimeLeft = turnTimeLeft;
    this.gameStatus = gameStatus;
    this.playersData = playersData;
  }
}
export default function GameScreen() {
  // const inviteCode: any = data.inviteCode;
  let location: any = useLocation();
  let navigate = useNavigate();
  const scenario: Scenario = location.state?.scenario;
  const gameRoomID: string = location.state?.gameRoomID;
  const gameState: GameState = location.state?.gameState;
  const [lobbyID] = useState<string>(location.state?.lobbyID && location.state.lobbyID);
  const [playersDataDisplay, setPlayersDataDisplay] = useState<Array<any>>(gameState.playersData);
  const [selectedTab, setSelectedTab] = useState<string>("users");
  const [roles] = useState<any>(scenario.roles);
  const [started, setStarted] = useState<boolean>(false);
  const [scenarioOutcomes] = useState<Outcome[]>(scenario.outcomes.map((o: Outcome) => Outcome.createFromJSON(o)));
  const [settings] = useState<ScenarioSettings>(scenario.settings);
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const [gStatus, setGStatus] = useState<gameStatus>(gameStatus.started);
  const [bgColor] = useState<string>(scenario.settings.boardBackgroundColor);
  const [setEndGameModal, setScores, lobbyScores] = useModalStore((state) => [state.setEndGameModal, state.setScores, state.lobbyScores]);

  useEffect(() => {
    game = new DefaultVariables();
    globalThis.globalEvents.addEventListener(events.ballApplyForce, ballApplyForce);
    globalThis.globalEvents.addEventListener(events.gameStateSyncUpdate, gameStateSyncUpdate);
    globalThis.globalEvents.addEventListener(events.gameStateSyncRequest, gameStateSyncRequest);
    globalThis.globalEvents.addEventListener(events.gameStarted, gameStarted);
    globalThis.globalEvents.addEventListener(events.leaveGameRoomResponse, leaveGameRoomResponse);
    globalThis.globalEvents.addEventListener(events.chooseActionResponse, chooseActionResponse);
    globalThis.globalEvents.addEventListener(events.turnOver, turnOver);
    globalThis.globalEvents.addEventListener(events.turnStarted, turnStarted);
    globalThis.globalEvents.addEventListener(events.gameOver, gameOver);
    globalThis.globalEvents.addEventListener(events.joinLobbyResponse, joinGameResponse);
    globalThis.globalEvents.addEventListener(events.joinLobbyResponse, joinGameResponse);

    window.addEventListener("resize", resize);

    //  globalThis.globalEvents.addEventListener(events.gameStateSyncResponse, gameStateSyncResponse);
    // lobbyUpdateRequest();
    game.canvas = document.getElementById("canvas") as HTMLCanvasElement;
    game.ctx = game.canvas.getContext("2d");
    const overlay: any = document.getElementsByClassName("overlay")[0];
    if (isMobile.any()) {
      window.addEventListener("touchmove", onMouseMove);
      overlay.addEventListener("touchstart", onMouseDown);
      window.addEventListener("touchend", onMouseUp);
    } else {
      window.addEventListener("mousemove", onMouseMove);
      overlay.addEventListener("mousedown", onMouseDown);
      window.addEventListener("mouseup", onMouseUp);
    }
    if (game.ctx) {
      game.AnimationContainer = new Animations(game.ctx);
      game.ctx.canvas.width = game.canvas.offsetWidth;
      game.ctx.canvas.height = game.canvas.offsetHeight;
    }

    if (scenario && gameRoomID && gameState) {
      gameStarted({ scenario: scenario, gameRoomID: gameRoomID, gameState: gameState });
    }
    return function cleanup() {
      turnAnimationFinished = false;
      actionArrow = null;
      globalThis.globalEvents.removeEventListener(events.ballApplyForce, ballApplyForce);
      globalThis.globalEvents.removeEventListener(events.gameStateSyncUpdate, gameStateSyncUpdate);
      globalThis.globalEvents.removeEventListener(events.gameStateSyncRequest, gameStateSyncRequest);
      globalThis.globalEvents.removeEventListener(events.chooseActionResponse, chooseActionResponse);
      globalThis.globalEvents.removeEventListener(events.leaveGameRoomResponse, leaveGameRoomResponse);
      globalThis.globalEvents.removeEventListener(events.turnOver, turnOver);
      globalThis.globalEvents.removeEventListener(events.turnStarted, turnStarted);
      globalThis.globalEvents.removeEventListener(events.gameOver, gameOver);
      globalThis.globalEvents.removeEventListener(events.joinLobbyResponse, joinGameResponse);

      window.removeEventListener("resize", resize);

      //   globalThis.globalEvents.removeEventListener(events.gameStateSyncResponse);

      if (game) {
        game.canvas = document.getElementById("canvas") as HTMLCanvasElement;
        if (game.canvas) {
          game.ctx = game.canvas.getContext("2d");
          if (isMobile.any()) {
            game.canvas.removeEventListener("touchstart", onMouseDown);
          } else {
            game.canvas.removeEventListener("mousedown", onMouseDown);
          }
          if (game.ctx) {
            game.ctx.canvas.width = game.canvas.offsetWidth;
            game.ctx.canvas.height = game.canvas.offsetHeight;
          }
          game.gameActive = false;
          game.canvas = null;
          game.ctx = null;
        }
      }
      game = new DefaultVariables();
      if (isMobile.any()) {
        window.removeEventListener("touchmove", onMouseMove);
        window.removeEventListener("touchend", onMouseUp);
      } else {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      }
    };
  }, []);

  function turnStarted(data: any) {
    turnAnimationFinished = false;
    setGStatus(gameStatus.turnActive);
    let gameStateUpdate: GameStateUpdate = data.gameStateUpdate as GameStateUpdate;
    game.gameState?.updateState(gameStateUpdate);
    setTimeRemaining(Math.round(game.gameState!.turnTimeLeft));
    clearInterval(game.countdown);
    startTime = Date.now();
    game.countdown = setInterval(function () {
      if (game.gameState) {
        setTimeRemaining(Math.round(game.gameState!.turnTimeLeft));
        if (game.gameState!.turnTimeLeft > 0) {
          let now = Date.now();
          let dif = (now - startTime) / 1000;
          let newTime = game.gameState!.turnTimeLeft - dif;
          setTimeRemaining(Math.round(newTime));
        } else {
          clearInterval(game.countdown);
        }
      }
    }, 1000);
  }

  function leaveGameRoomResponse(data: LeaveGameRoomResponseData) {
    switch (data.response) {
      case responses.success:
        // game.gameActive = false;
        if (globalThis.isFacilitator) {
          navigate("/menu");
        } else {
          navigate("/");
        }
        break;
      default:
        break;
    }
  }

  function leaveGameRoomRequest() {
    let requestData: LeaveGameRoomRequestData = {
      event: events.leaveGameRoomRequest,
      gameRoomId: gameRoomID,
    };
    globalThis.socket?.send(JSON.stringify(requestData));
  }

  function resize() {
    if (game.ctx) {
      if (game.canvas) {
        game.ctx.canvas.width = game.canvas.offsetWidth;
        game.ctx.canvas.height = game.canvas.offsetHeight;
        game.ballVF!.resize(game.ctx.canvas);
      }
    }
  }
  function joinGameResponse(data: any) {
    if (data.response === responses.success) {
      navigate("/lobby", { state: { inviteCode: data.inviteCode, lobbyID: data.lobbyID } });
    }
  }

  function gameOver(data: any) {
    let { resultOutcomeTitle, gameRoomType, teamScoreGain, teamScore, playerScoreGains, playerScores, roleScoreGains, roleScores, rolesPlayedInSession } = data;

    clearInterval(game.countdown);
    game.countdown = null;

    setScores({
      teamScoreGain,
      teamScore,
      playerScoreGains,
      playerScores,
      roleScoreGains,
      roleScores,
      rolesPlayedInSession,
      playersDataDisplay,
    });
    for (var i = 0; i < game.scenario!.outcomes.length; i++) {
      if (game.scenario!.outcomes[i].title === resultOutcomeTitle) {
        let outcome: Outcome = game.scenario!.outcomes[i];
        setEndGameModal(outcome);
      }
    }
    if (gameRoomType === GameRoomType.publicGameRoom) {
      navigate("/");
    }
    //popup(data.resultOutcomeTitle, "green");
  }

  function resetLobby() {
    clearInterval(game.countdown);
    game.countdown = null;
    game = new DefaultVariables();
    setStarted(false);
    setSelectedTab("users");
    setEndGameModal(null);
    navigate("/game");
    //navigate("/");
  }

  function turnOver(data: any) {
    actionArrow = null;
    let now = Date.now();
    clearInterval(game.countdown);
    game.countdown = null;
    for (let i = 0; i < data.usernames.length; i++) {
      let color;
      for (let j = 0; j < game.playersData.length; j++) {
        if (game.playersData[j].username === data.usernames[i]) {
          let selectedActionRelative = new Vector(data.selectedActions[i].x / ABS_WIDTH, data.selectedActions[i].y / ABS_HEIGHT);

          color = game.playersData[j].userColor;
          game.AnimationContainer!.addArrowAnimation(
            color,
            new Vector(0, 0),
            selectedActionRelative,
            game.ballVF!.position,
            game.ballVF!.radius,
            now + i * game.arrowAnimationDuration,
            game.arrowAnimationDuration
          );
          game.startVector = game.totalForce.clone();
          game.totalForce.add(selectedActionRelative);
          game.AnimationContainer!.addArrowAnimation(
            "red",
            game.startVector,
            game.totalForce.clone(),
            game.ballVF!.position,
            game.ballVF!.radius,
            now + i * game.arrowAnimationDuration,
            game.arrowAnimationDuration
          );
        }
      }
    }
    if (game.gameState) {
      game.gameState!.gameStatus = gameStatus.turnOver;
    }
    setGStatus(gameStatus.turnOver);
    setTimeout(() => {
      game.startVector = new Vector(0, 0);
      game.totalForce = new Vector(0, 0);
      game.ballVF?.setForceAbs(new Vector(data.totalForce.x, data.totalForce.y));
      turnAnimationFinished = true;
    }, data.usernames.length * game.arrowAnimationDuration);
  }

  function chooseActionResponse(data: any) {
    switch (data.response) {
      case responses.success:
        //popup("Role already selected!", "red");
        break;
      case responses.userNotFoundInGameRoom:
        actionArrow = null;
        popup("User not found in the game room!", "red");
        break;
      case responses.gameRoomNotFound:
        actionArrow = null;
        popup("Game room not found!", "red");
        break;
      case responses.alreadyChosenAction:
        popup("Action already chosen!", "red");
        break;
      case responses.playerNotAnActor:
        actionArrow = null;
        popup("Player is not an actor!", "red");
        break;
      default:
        break;
    }
  }

  function gameStarted({ scenario, gameRoomID, gameState }: { scenario: Scenario; gameRoomID: string; gameState: GameState }) {
    game.playersData = gameState.playersData;
    for (let j = 0; j < game.playersData.length; j++) {
      if (game.playersData[j].username === globalThis.username && game.playersData[j].playerType === playerType.facilitator) {
        game.isFacilitator = true;
      }
    }
    let ball = new Ball(
      new Point(scenario.ball.position.x, scenario.ball.position.y),
      scenario.ball.radius,
      scenario.ball.backgroundColor,
      scenario.ball.frictionF
    );
    game.gameActive = true;

    setStarted(true);
    let roomID = gameRoomID;
    game.scenario = new Scenario(
      scenario.id,
      scenario.name,
      scenario.outcomes,
      scenario.roles,
      scenario.ball,
      scenario.ownerEmail,
      scenario.type,
      scenario.settings,
      scenario.scoreTable
    );
    game.gameState = new GameState(ball, scenario.settings.turnTimeLimit, gameStatus.started, gameState.playersData);
    game.scenario!.outcomes = [];
    let newOutcomes = scenario.outcomes;
    for (let i = 0; i < newOutcomes.length; i++) {
      let outcome = newOutcomes[i];
      game.scenario!.outcomes[i] = Outcome.createFromJSON(outcome);
    }
    game.gameRoomID = roomID;
    //setScenarioOutcomes(newOutcomes);
    if (game.ctx) game.ballVF = new BallVF(ball, game.ctx.canvas);
    if (game.scenario) game.scenario.ball = ball;
    update();
  }

  function gameStateSyncUpdate(data: any) {
    //console.log("sync update");
    let gameStateUpdate: GameStateUpdate = data.gameStateUpdate as GameStateUpdate;
    game.gameState?.updateState(gameStateUpdate);
    game.playersData = game.gameState!.playersData;
    for (let j = 0; j < game.playersData.length; j++) {
      if (game.playersData[j].username === globalThis.username && game.playersData[j].playerType === playerType.facilitator) {
        game.isFacilitator = true;
      }
    }
    setPlayersDataDisplay(game.playersData);

    //console.log("balltype:", gameState.ball.constructor.name);
    //ballVF.setBall(gameState.ball);
  }

  function gameStateSyncRequest(data: any) {
    //  console.log(data);
    let gameStateUpdate = new GameStateUpdate({ ball: game.gameState?.ball });
    globalThis.socket?.send(
      JSON.stringify({
        event: events.gameStateSyncResponse,
        gameStateUpdate: gameStateUpdate,
        gameRoomID: game.gameRoomID,
      })
    );
  }

  function ballApplyForce(data: any) {
    if (game.ctx?.canvas) {
      //  console.log("ball apply force event ");
      game.ballVF?.setForceAbs(new Vector(data.force.x, data.force.y));
    }
  }

  function onMouseUp() {
    game.mouseDown = false;
    if (game.gameState) {
      if (game.gameState!.gameStatus === gameStatus.turnActive) {
        if (game.ctx?.canvas) {
          globalThis.socket?.send(
            JSON.stringify({
              event: events.chooseActionRequest,
              force: new Vector((game.arrowVector.x / game.ctx?.canvas.width) * ABS_WIDTH, (game.arrowVector.y / game.ctx?.canvas.height) * ABS_HEIGHT),
              gameRoomID: game.gameRoomID,
            })
          );
          actionArrow = new Vector(game.arrowVector.x, game.arrowVector.y);
          // console.log(new Vector((arrowEnd.x - arrowStart.x) / ctx?.canvas.width, (arrowEnd.y - arrowStart.y) / ctx?.canvas.height));
        }
      }
    }

    //console.log("Mouse down: " + mouseDown);
  }

  function onMouseDown() {
    // console.log("asd");
    game.mouseDown = true;
    //console.log("Mouse down: " + mouseDown);
  }

  function onMouseMove(e: any) {
    game.mousePos = getMousePos(e);
  }

  /* function leaveGameResponse(data: any) {
        console.log(data);
        switch (data.response) {
            case responses.success:
                game.gameActive = false;
                navigate("/");
                break;
            default:
                break;
        }
    }*/

  function getMousePos(e: any) {
    var rect = document.getElementsByClassName("overlay")[0].getBoundingClientRect();
    if (rect !== undefined) {
      if (isMobile.any()) {
        return {
          x: e.touches[0].clientX - rect.left,
          y: e.touches[0].clientY - rect.top,
        };
      } else {
        return {
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        };
      }
    }
  }
  function drawArrow(ctx: any, fromx: any, fromy: any, tox: any, toy: any, arrowWidth: any, color: any, width: any) {
    //variables to be used when creating the arrow
    var headlen = 10;
    var angle = Math.atan2(toy - fromy, tox - fromx);
    ctx.save();
    ctx.strokeStyle = color;
    // ctx.fillStyle = color;
    //starting path of the arrow from the start square to the end square
    //and drawing the stroke
    if (arrowWidth < 6) {
      arrowWidth = 6;
    }
    ctx.beginPath();
    ctx.lineWidth = arrowWidth;
    ctx.stroke();

    //starting a new path from the head of the arrow to one of the sides of
    //the point
    ctx.beginPath();
    ctx.moveTo(tox, toy);
    ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / width), toy - headlen * Math.sin(angle - Math.PI / width));

    //path from the side point of the arrow, to the other side point
    ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / width), toy - headlen * Math.sin(angle + Math.PI / width));

    //path from the side point back to the tip of the arrow, and then
    //again to the opposite side point
    ctx.lineTo(tox, toy);
    ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / width), toy - headlen * Math.sin(angle - Math.PI / width));

    //draws the paths created above
    ctx.stroke();
    //ctx.fill();
    ctx.restore();
  }

  function newArrow(ctx: any, vector: Vector, startPoint: Point, arrowWidth: any, color: any) {
    let newVector: Vector = vector.getUnitVector();
    newVector.multiplyByScalar(Math.sqrt(vector.length()) * 4 + game.ballVF!.radius);
    drawArrow(ctx, startPoint.x, startPoint.y, startPoint.x + newVector.x, startPoint.y + newVector.y, Math.sqrt(3 * vector.length()), color, 12);
  }

  function animateArrow(
    startTime: number,
    duration: number,
    dt: number,
    selectedActionVector: Vector,
    startPoint: Point,
    arrowWidth: any,
    color: any,
    startVector: Vector,
    log: boolean = false
  ) {
    var percent: any = (Date.now() - startTime) / duration;
    if (Date.now() - startTime < duration) {
      game.arrowVector = new Vector(
        startVector.x * (1 - percent) + selectedActionVector.x * percent,
        startVector.y * (1 - percent) + selectedActionVector.y * percent
      );
      if (log) {
        //     console.log(arrowVector.x);
      }

      if (game.canvas)
        newArrow(game.ctx, new Vector(game.arrowVector.x * game.canvas.offsetWidth, game.arrowVector.y * game.canvas.offsetHeight), startPoint, 50, color);
    }
    //console.log(startTime);
  }

  function update() {
    var now = Date.now();
    var dt = (now - game.lastUpdate) / 1000;
    game.lastUpdate = now;
    game.ctx?.clearRect(0, 0, game.ctx.canvas.width, game.ctx.canvas.height);
    // console.log(playersData);

    if (game.ctx) game.ballVF!.draw(game.ctx);
    //console.log("gamestatus:", game.gameState?.gameStatus);
    //console.log(actionArrow);
    if (game.mouseDown && actionArrow === null && game.gameState?.gameStatus === gameStatus.turnActive) {
      if (game.mousePos !== undefined) {
        let arrowStart = new Point(game.ballVF!.position.x, game.ballVF!.position.y);
        let arrowEnd = new Point(
          game.ballVF!.position.x + game.ballVF!.position.x - game.mousePos.x,
          game.ballVF!.position.y + game.ballVF!.position.y - game.mousePos.y
        );
        // if (
        /* distance(game.ballVF!.position.x, game.ballVF!.position.y, arrowEnd.x, arrowEnd.y) <
                    (game.scenario!.settings.maxForce / ABS_HEIGHT) * game.canvas!.height*/
        // ) {
        let forceV = new Vector(arrowEnd.x - arrowStart.x, arrowEnd.y - arrowStart.y);
        let forceVAbs = new Vector((forceV.x / game.canvas!.width) * ABS_WIDTH, (forceV.y / game.canvas!.height) * ABS_HEIGHT);

        let fMagnitude = forceVAbs.length();
        //  console.log("fMagnitude:", fMagnitude);
        //console.log("force abs:", forceVAbs);
        //console.log("MAXFORCE:", game.scenario!.settings.maxForce);
        if (fMagnitude > game.scenario!.settings.maxForce) {
          //    //check and make sure that the force applied does not extend the maximum
          let cappedForce = forceVAbs.getUnitVector();
          cappedForce.multiplyByScalar(game.scenario!.settings.maxForce);
          forceV = new Vector((cappedForce.x / ABS_WIDTH) * game.canvas!.width, (cappedForce.y / ABS_HEIGHT) * game.canvas!.height); //convert back to relative coords
        }
        game.arrowVector = forceV;

        // }

        for (var i = 0; i < game.playersData.length; i++) {
          if (globalThis.username === game.playersData[i].username) {
            newArrow(game.ctx, game.arrowVector, game.ballVF!.position, 50, game.playersData[i].userColor);
          }
        }
      }
    }
    if (actionArrow) {
      for (var i = 0; i < game.playersData.length; i++) {
        if (globalThis.username === game.playersData[i].username) {
          newArrow(game.ctx, actionArrow, game.ballVF!.position, 50, game.playersData[i].userColor);
        }
      }
    }
    if (game.gameState?.ball.v.length() === 0 && game.gameState.gameStatus === gameStatus.turnOver && game.isFacilitator && turnAnimationFinished) {
      game.gameState.gameStatus = gameStatus.turnActive;
      let gameStateUpdate = new GameStateUpdate({ ball: game.gameState?.ball });
      globalThis.socket?.send(
        JSON.stringify({
          event: events.ballStoppedMoving,
          gameStateUpdate: gameStateUpdate,
          gameRoomID: game.gameRoomID,
        })
      );
    }

    if (game.ballVF) game.ballVF?.update(dt);
    if (game.gameState?.ball.v.length() === 0 && game.isFacilitator) {
      if (game.gameState.gameStatus !== gameStatus.gameOver && game.gameState.gameStatus !== gameStatus.started) {
        for (let j = 0; j < game.scenario.outcomes.length; j++) {
          if (
            game.ballVF?.ball.position &&
            game.scenario.outcomes[j].isPointWithin(game.ballVF?.ball.position) &&
            lobbyScores &&
            game.scenario.outcomes[j].outcomeCondition.isFulfilled(lobbyScores)
          ) {
            game.gameState.gameStatus = gameStatus.gameOver;
            globalThis.socket?.send(
              JSON.stringify({
                event: events.ballHitOutcomeUpdate,
                resultOutcomeTitle: game.scenario.outcomes[j].title,
                gameStateUpdate: new GameStateUpdate({
                  ball: game.gameState.ball,
                  turnTimeLeft: game.gameState.turnTimeLeft,
                  gameStatus: game.gameState.gameStatus,
                }),
                gameRoomID: game.gameRoomID,
                resultOutcomeId: game.scenario.outcomes[j].id,
              })
            );
          }
        }
      }
    }

    game.AnimationContainer && game.AnimationContainer?.update();

    if (game.gameActive) setTimeout(update, 1);
  }

  /*function lobbyUpdate(data: any) {
        game.playersData = data.playersData;
        for (let j = 0; j < game.playersData.length; j++) {
            if (game.playersData[j].username === globalThis.username && game.playersData[j].playerType === playerType.facilitator) {
                game.isFacilitator = true;
            }
        }
        setBgColor(data.scenario.settings.boardBackgroundColor);
        setRoles(data.scenario.roles);
        let newSettings: ScenarioSettings = data.scenario.settings;
        setSettings(newSettings);
        console.log(data);
        game.scenario = data.scenario;
        //  game.scenario!.outcomes = [];
        let newOutcomes = data.scenario.outcomes;
        for (let i = 0; i < newOutcomes.length; i++) {
            let outcome = newOutcomes[i];
            game.scenario!.outcomes[i] = new Outcome(
                outcome.id,
                outcome.title,
                outcome.description,
                outcome.position,
                outcome.width,
                outcome.height,
                outcome.backgroundColor,
                outcome.image
            );
        }
        game.scenario.outcomes = newOutcomes;
        console.log(game.scenario.outcomes);
        setScenarioOutcomes(newOutcomes);
        setPlayersDataDisplay(game.playersData);
        let ball: Ball = new Ball(
            new Point(data.scenario.ball.position.x, data.scenario.ball.position.y),
            data.scenario.ball.radius,
            data.scenario.ball.backgroundColor,
            data.scenario.ball.frictionF
        );
        if (game.ctx) game.ballVF = new BallVF(ball, game.ctx.canvas);
        if (game.scenario) game.scenario.ball = ball;
        update();
    }*/

  useEffect(() => {}, [scenarioOutcomes]);

  function selectNewTab(tab: string) {
    setSelectedTab(tab);
    const users_button = document.getElementById("users_button");
    const roles_button = document.getElementById("roles_button");
    const settings_button = document.getElementById("settings_button");
    users_button?.classList.remove("selected");
    roles_button?.classList.remove("selected");
    settings_button?.classList.remove("selected");
    switch (tab) {
      case "users":
        users_button?.classList.add("selected");
        break;
      case "roles":
        roles_button?.classList.add("selected");
        break;
      case "settings":
        settings_button?.classList.add("selected");
        break;
      default:
        break;
    }
  }

  function startGameRequest() {
    globalThis.socket?.send(
      JSON.stringify({
        event: events.startGameRequest,
        lobbyID: lobbyID,
      })
    );
  }

  function copyInviteCode(inviteCode: string) {
    navigator.clipboard.writeText(inviteCode);
    popup("Invite code copied to clipboard", "rgb(0,128,0)");
  }

  function copyToClipboard(textToCopy: string, alert: string) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      //  navigator clipboard api method'
      popup(alert, "rgb(0,128,0)");
      return navigator.clipboard.writeText(textToCopy);
    } else {
      // text area method
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise(() => {
        // here the magic happens
        document.execCommand("copy") ? popup(alert, "rgb(0,128,0)") : popup("Failed to copy invite code", "rgb(255,0,0)");
        textArea.remove();
      });
    }
  }
  return (
    <>
      <div className="game-canvas-container">
        <div className="overlay" style={{ backgroundColor: bgColor }}></div>
        <OutcomesPanel>
          {scenarioOutcomes.map((outcome: any, index: any) => {
            return (
              <OutcomePanel
                position={outcome.position}
                size={outcome.height}
                title={outcome.title}
                description={outcome.description}
                key={index}
                image={outcome.image}
                backgroundColor={outcome.backgroundColor}
                playersData={playersDataDisplay}
                roles={roles}
                scoreTable={ScoreTable.createFromJSON(scenario.scoreTable)}
                outcome={Outcome.createFromJSON(outcome)}
              />
            );
          })}
        </OutcomesPanel>
        <canvas id="canvas"></canvas>
      </div>
      <div className="game-setup-container">
        <div className="game-setup">
          <>
            <div className="tab-buttons">
              <button id="users_button" className="tab-button selected" onClick={() => selectNewTab("users")}>
                Users{`(${playersDataDisplay.length})`}
              </button>
              {settings.enableRoles && (
                <button id="roles_button" className="tab-button" onClick={() => selectNewTab("roles")}>
                  Roles
                </button>
              )}
              {game && game.isFacilitator && !started && (
                <button id="settings_button" className="tab-button" onClick={() => selectNewTab("settings")}>
                  Scenario
                </button>
              )}
            </div>
            {selectedTab === "roles" && <GameSetup roles={roles} lobbyID={lobbyID} outcomes={scenarioOutcomes} playersDataDisplay={playersDataDisplay} />}
            {selectedTab === "users" && (
              <>
                <IngamePanel gameStatus={gStatus} timeRemaining={timeRemaining}>
                  {<Players playersData={playersDataDisplay} leaveGameRequest={leaveGameRoomRequest} roles={roles} />}
                </IngamePanel>
              </>
            )}
          </>
        </div>
      </div>
      <ScoreInfoBox />
    </>
  );
}

export function ScoreInfoBox() {
  const [lobbyScores, selectedElement] = useModalStore((state) => [state.lobbyScores, state.selectedElement]);
  const test = useRef();
  const [position, setPosition] = useState<any>(null);

  useEffect(() => {
    window.addEventListener("mousemove", setMousePos);
    return function cleanup() {
      window.removeEventListener("mousemove", setMousePos);
    };
  }, []);

  function setMousePos(e: any) {
    //console.log(e.clientX, e.clientY);
    //if (selectedElement) {
    //setPosition({ x: e.clientX, y: e.clientY });
    // }
    const element = document.getElementById("score-container");
    const container = document.getElementsByClassName("game-setup-container")[0];
    const rect = element?.getBoundingClientRect();
    const containerRect = container?.getBoundingClientRect();
    const mousePos = getMousePos(e);
    if (element && mousePos && rect) {
      let h = containerRect.height + containerRect.y;
      let y = mousePos.y;
      if (h < mousePos.y + rect.height) {
        element.style.top = containerRect.y + "px";
      } else {
        element.style.top = y + "px";
      }
      element.style.left = mousePos.x - rect.width + "px";
    }
  }

  useEffect(() => {
    /* if (selectedElement) {
      console.log(selectedElement.pos);
    }*/
  }, [selectedElement]);

  if (!selectedElement) return <></>;
  if (selectedElement && selectedElement.type === "player") {
    return (
      <div id="score-container" className="score-info">
        {lobbyScores && (
          <>
            <div style={{ textAlign: "center" }}>
              <span className="trunctate">{selectedElement.player.username}</span>
            </div>
            <hr className="team-score-separator"></hr>
            {Object.keys(lobbyScores.playerScores).map((item, index) => {
              return (
                <div key={index}>
                  <span>{item}: </span>
                  <span>{lobbyScores.playerScores[item][selectedElement.player.playerId] || 0}</span>
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  } else if (selectedElement && selectedElement.type === "role") {
    let role = selectedElement.role;
    return (
      <div id="score-container" className="score-info">
        <>
          {role.image ? (
            <img className="score-role" src={role.image} alt="" />
          ) : (
            <div className="score-role round" style={{ backgroundColor: role.backgroundColor }}></div>
          )}
          <div style={{ textAlign: "center" }}>
            <span className="score-role-title">{role.title}</span>
          </div>
        </>
        <hr className="team-score-separator"></hr>
        {Object.keys(lobbyScores!.roleScores).map((item, index) => {
          return (
            <div key={index}>
              <span>{item}: </span>
              <span>{lobbyScores!.roleScores[item][selectedElement.role.title] || 0}</span>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <></>;
  }
}

/*   <div className="game-setup">
                    {inviteCode && <span>Invite code: {inviteCode}</span>}
                    {playersData.map((user, index) => (
                        <span style={{ color: user.userColor }} key={index}>
                            {user.username}
                        </span>
                    ))}
                    <button onClick={leaveGameRequest}>Leave</button>
                    <button onClick={gameStateSyncResponse}>Sync</button>
                </div>*/
/*{inviteCode && <span>Invite code: {inviteCode}</span>}
{playersData.map((user, index) => (
    <span style={{ color: user.userColor }} key={index}>
        {user.username}
    </span>
))}
<button onClick={leaveGameRequest}>Leave</button>*/
