import { useState, useEffect, useRef } from "react";
import useModalStore from "../stores/modalStore";
import Scenario, { Outcome, Role, ScoreTable as STable } from "../classes/Scenario";
import useEditorStore from "../stores/editorStore";
import { responses } from "../ServerCommunication";
import { popup } from "../functions";

export default function ScoreTable() {
  const [scenario, setEditorScenario] = useEditorStore((state) => [state.scenario, state.setEditorScenario]);
  const [setToggleScoreModal] = useModalStore((state) => [state.setToggleScoreModal]);
  const [activeTable, setActiveTable] = useState<string>(
    scenario?.scoreTable?.scoreCurrencies?.length && scenario?.scoreTable?.scoreCurrencies[0] ? scenario?.scoreTable?.scoreCurrencies[0] : ""
  );
  const [triggerRender, setTriggerRender] = useState<boolean>(false);
  function addNew() {
    if (scenario && scenario?.scoreTable?.scoreCurrencies?.length < 5) {
      let added;
      if (scenario?.scoreTable?.scoreCurrencies?.length === 0) {
        added = scenario?.addScoreCurrency(`Score`);
        if (added === responses.success) {
          setActiveTable("Score");
        }
      } else {
        if (scenario) {
          let i = 1;
          while (added !== responses.success) {
            i++;
            added = scenario?.addScoreCurrency(`Score(${i})`);
          }
          if (added === responses.success) {
            setTriggerRender(!triggerRender);
            setActiveTable(`Score(${i})`);
          } else if (added === responses.failure) {
            popup("Unable to create score", "red");
          }
        }
      }
    } else {
      popup("You cannot add more than 5 score types to a scenario.", "red");
    }
  }

  return (
    <>
      {scenario && (
        <div className="score-table-container">
          <div className="score-table-header">
            <div className="score-table-buttons">
              {scenario?.scoreTable?.scoreCurrencies?.map((item) => {
                return <ScoreNameInput scenario={scenario} item={item} activeTable={activeTable} setActiveTable={setActiveTable} />;
              })}
            </div>
            <div className="score-table-add-score">
              <div className="score-table-separator"></div>
              <div className="score-table-add-new" onClick={addNew}>
                Add new score
              </div>
              <div className="score-table-separator"></div>
              <div className="login-button" onClick={() => setToggleScoreModal(false)}>
                Done
              </div>
            </div>
          </div>
          {scenario?.scoreTable?.scoreCurrencies?.length > 0 && (
            <div className="score-table-table-container">
              <table className="score-table-table">
                <thead style={{ zIndex: 999999 }}>
                  <tr className="score-row">
                    <td className="score-text border-top first-col" style={{ position: "sticky", left: 0, zIndex: 999 }}></td>
                    {scenario?.roles?.map((role, index) => {
                      return (
                        <th className="score-text border-top score" key={index}>
                          {role.title}
                        </th>
                      );
                    })}
                    <th className="score-text border-top last-score">Team Score</th>
                  </tr>
                </thead>
                <tbody>
                  {scenario?.outcomes?.map((outcome, index) => {
                    return <Row roles={scenario.roles} outcomeIndex={index} outcome={outcome} scenario={scenario} key={index} activeTable={activeTable}></Row>;
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function ScoreNameInput({ item, activeTable, setActiveTable, scenario }: { item: any; activeTable: string; setActiveTable: Function; scenario: Scenario }) {
  const setConfirmModal = useModalStore((state) => state.setConfirmModal);

  const ref = useRef<any>();
  const updateWidth = () => {
    //  const input = ref.current;
    //const contentWidth = input.scrollWidth;
    //input.style.width = `${contentWidth}px`;
  };

  useEffect(updateWidth, []);

  function deleteItem(item: string) {
    scenario?.removeScoreCurrency(item);
    setActiveTable("Score");
  }

  function updateName(value: string, oldName: string) {
    if (value.length > 0 && value !== oldName) {
      const response = scenario?.renameScoreCurrency(oldName, value);
      if (response === responses.duplicate) {
        ref.current.value = oldName;
        popup("A score with this name already exists. Each score must have a unique name.", "red", 4000);
      }
    } else {
      ref.current.value = oldName;
    }
  }

  useEffect(() => {
    ref.current.value = item;
  }, [item]);

  return (
    <div
      className={item === activeTable ? "score-button score-button-active" : "score-button"}
      onClick={() => {
        setActiveTable(item);
      }}
    >
      <input
        type="text"
        ref={ref}
        defaultValue={item}
        className="score-input-name"
        onBlur={(e: any) => updateName(e.target.value, item)}
        onChange={updateWidth}
      ></input>
      {item === activeTable && (
        <div
          className="trash score-trash"
          onClick={() => {
            setConfirmModal({
              onConfirm: () => deleteItem(item),
              title: `Are you sure you want to delete ${item}? 
              `,
              text: "Any conditions on outcomes using this score will be removed.",
            });
          }}
        ></div>
      )}
    </div>
  );
}

function Row({
  roles,
  outcomeIndex,
  outcome,
  scenario,
  activeTable,
}: {
  roles: Role[];
  outcomeIndex: number;
  outcome: Outcome;
  scenario: Scenario;
  activeTable: string;
}) {
  function onChange(score: number) {
    scenario?.scoreTable?.setTeamScoreForOutcome(activeTable, outcome, score);
    //console.log(outcomeIndex, roleIndex);
  }
  return (
    <tr className="score-row">
      {
        <td className="first-score first-col score-text" style={{ position: "sticky", left: 0 }}>
          {outcome.title}
        </td>
      }
      {roles.map((role, index) => {
        return <Cell outcome={outcome} role={role} scenario={scenario} key={index} activeTable={activeTable} />;
      })}
      <td className="score">
        <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
          {outcome && (
            <NumberInput
              activeTable={activeTable}
              maxLength={4}
              onChange={onChange}
              defaultValue={scenario?.scoreTable?.getTeamScoreForOutcome(activeTable, outcome) || 0}
            />
          )}
          {/*<div className="score-text" style={{ marginLeft: "5%", width: "45%", textAlign: "left" }}>
            Gold
          </div>*/}
        </div>
      </td>
      {/*<td className="last-score">-8 gold</td>*/}
    </tr>
  );
}

function Cell({ outcome, role, scenario, activeTable }: { outcome: Outcome; role: Role; scenario: Scenario; activeTable: string }) {
  function onChange(score: number) {
    scenario?.scoreTable?.setRoleScoreForOutcome(activeTable, role, outcome, score);
    //console.log(outcomeIndex, roleIndex);
  }
  return (
    <td className="score">
      <div style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
        {outcome && (
          <NumberInput
            maxLength={4}
            onChange={onChange}
            activeTable={activeTable}
            defaultValue={scenario?.scoreTable?.getRoleScoreForOutcome(activeTable, role, outcome) || 0}
          />
        )}
        {/*<div className="score-text" style={{ marginLeft: "5%", width: "45%", textAlign: "left" }}>
          Gold
        </div>*/}
      </div>
    </td>
  );
}

const NumberInput = ({
  maxLength,
  onChange,
  defaultValue,
  activeTable,
}: {
  maxLength?: number;
  onChange: Function;
  defaultValue: number;
  activeTable: string;
}) => {
  const ref = useRef<any>();
  const handleChange = (event: any) => {
    const inputValue = event.target.value;

    if (maxLength) {
      if (inputValue.length <= maxLength) {
        onChange(Number(inputValue));
      } else {
        ref.current.value = inputValue.slice(0, maxLength);
        onChange(Number(inputValue.slice(0, maxLength)));
      }
    }
  };

  function onFocus() {
    ref.current.value = "";
  }

  function onBlur() {
    if (ref.current.value === "") {
      ref.current.value = defaultValue;
    }
  }

  useEffect(() => {
    ref.current.value = defaultValue;
  }, [defaultValue, activeTable]);

  return (
    <input
      ref={ref}
      className="score-text score-input"
      type="number"
      defaultValue={defaultValue}
      onChange={handleChange}
      style={{ width: "40%", textAlign: "right", backgroundColor: "transparent" }}
      onFocus={onFocus}
      onBlur={onBlur}
    ></input>
  );
};
