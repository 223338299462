export class Vector {
    x: number;
    y: number;
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
    add(vector: Vector) {
        this.x += vector.x;
        this.y += vector.y;
    }
    length() {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }
    subtract(vector: Vector) {
        this.x -= vector.x;
        this.y -= vector.y;
    }
    multiplyByScalar(scalar: number) {
        this.x = this.x * scalar;
        this.y = this.y * scalar;
    }
    rotateRight() {
        let temp = this.x;
        this.x = this.y;
        this.y = -temp;
    }
    rotateLeft() {
        let temp = this.x;
        this.x = -this.y;
        this.y = temp;
    }
    clone() {
        return new Vector(this.x, this.y);
    }
    getUnitVector() {
        //let a;
        //let b;
        if (this.x === 0) {
            if (this.y === 0) {
                return new Vector(0, 0);
            } else {
                return new Vector(0, 1);
            }
        } else if (this.y === 0) {
            return new Vector(1, 0);
        } else {
            let b = Math.sqrt(1 / ((this.x * this.x) / (this.y * this.y) + 1)) * Math.sign(this.y);
            let a = (this.x / this.y) * b;
            return new Vector(a, b);
        }
    }
    getSubtract(vector: Vector) {
        //does not overwrite this vector, only returns the result
        let x = this.x - vector.x;
        let y = this.y - vector.y;
        return new Vector(x, y);
    }
    getScalarProduct(vector: Vector) {
        return this.x * vector.x + this.y * vector.y;
    }
    getScalarMultiple(scalar: number) {
        return new Vector(this.x * scalar, this.y * scalar);
    }
}

export class Point {
    x: number;
    y: number;
    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
    getDistance(point: Point) {
        return Math.sqrt(Math.pow(point.x - this.x, 2) + Math.pow(point.y - this.y, 2));
    }
    clone() {
        return new Point(this.x, this.y);
    }
}

export function distance(x1: number, y1: number, x2: number, y2: number) {
    var d;
    d = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
    return d;
}

export function generateRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color.toLowerCase();
}
