import { useState, useEffect, useRef } from "react";
import Img from "./Img";
import { Outcome, Role } from "../classes/Scenario";
import { ReactComponent as Lock } from "../assets/svg/lock.svg";
import useModalStore from "../stores/modalStore";

export default function OutcomePanel(props: any) {
  const [lobbyScores] = useModalStore((state) => [state.lobbyScores]);

  const outcome: any = useRef();
  const outcome_visual: any = useRef();
  let canvas: any = document.getElementById("canvas");
  const [position, setPosition] = useState<any>({
    x: canvas ? canvas.width / 2 : 0,
    y: canvas ? canvas.height / 2 : 0,
  });
  const [size, setSize] = useState<any>({ w: 0, h: 0 });
  const [radius, setRadius] = useState<number>(0);

  const mouseDown = useRef<boolean>(false);

  let ooutcome = Outcome.createFromJSON(props.outcome);
  useEffect(() => {
    if (canvas) setPosition({ x: (props.position.x / 135) * canvas.offsetWidth, y: (props.position.y / 100) * canvas.offsetHeight });
    if (canvas) setSize({ w: (props.size / 135) * canvas.offsetWidth, h: (props.size / 135) * canvas.offsetWidth });
    outcome.current?.addEventListener("mouseover", hover);
    outcome.current?.addEventListener("mouseout", noHover);
    window.addEventListener("resize", resize);
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mouseup", onMouseUp);
    return function cleanup() {
      outcome.current?.removeEventListener("mouseover", hover);
      outcome.current?.removeEventListener("mouseOut", noHover);
      window.removeEventListener("resize", resize);
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [props]);

  function onMouseDown() {
    outcome_visual.current.style.pointerEvents = "none";
    mouseDown.current = true;
  }

  function onMouseUp() {
    outcome_visual.current.style.pointerEvents = "auto";
    mouseDown.current = false;
  }

  useEffect(() => {
    let canvas: any = document.getElementById("canvas");
    let children: any = outcome.current.querySelector(".outcome-description");
    if (children) {
      children.style.left = "unset";
      children.style.right = "unset";
      children.style.top = "unset";
      children.style.bottom = "unset";
      if (position.x <= canvas.offsetWidth / 2) {
        children.style.left = "100%";
      } else if (position.x > canvas.offsetWidth / 2) {
        children.style.right = "100%";
      }
      if (position.y <= canvas.offsetHeight / 2) {
        children.style.top = "0%";
      } else if (position.y > canvas.offsetHeight / 2) {
        children.style.bottom = "0%";
      }

      let canvasRect = children.getBoundingClientRect();
      let childrenRect = canvas.getBoundingClientRect();

      if (childrenRect.y > canvasRect.y) {
        children.style.bottom = canvasRect.y - childrenRect.y + "px";
      }
      if (childrenRect.y + childrenRect.height < canvasRect.y + canvasRect.height) {
        children.style.top = -canvasRect.y + childrenRect.y + "px";
      }
    }
  }, [position]);

  function hover() {
    globalThis.topZIndex++;
    outcome.current.style.zIndex = globalThis.topZIndex;
    if (!mouseDown.current) {
      let outcome_layer: any = document.getElementsByClassName("outcomes-container")[0];
      outcome_layer.style.zIndex = 42;
    }
  }

  function noHover() {
    let outcome_layer: any = document.getElementsByClassName("outcomes-container")[0];
    outcome_layer.style.zIndex = 39;
  }

  function resize() {
    setPosition({ x: (props.position.x / 135) * canvas.offsetWidth, y: (props.position.y / 100) * canvas.offsetHeight });
    setSize({ w: (props.size / 135) * canvas.offsetWidth, h: (props.size / 135) * canvas.offsetWidth });

    outcome.current.style.width = (size / 135) * canvas.offsetWidth + "px";
    outcome.current.style.height = (size / 135) * canvas.offsetWidth + "px";
  }
  useEffect(() => {
    setRadius(size.w / 8);
  }, [size]);

  useEffect(() => {
    outcome_visual.current.classList.remove("selected-outcome");
    for (let i = 0; i < props.playersData.length; i++) {
      if (props.playersData[i].username === globalThis.username) {
        for (let j = 0; j < props.roles.length; j++) {
          if (props.roles[j].title === props.playersData[i].selectedRoleTitle) {
            for (let k = 0; k < props.roles[j].idealOutcomes.length; k++) {
              if (props.roles[j].idealOutcomes[k].title === props.title) {
                outcome_visual.current.classList.add("selected-outcome");
              }
            }
          }
        }
      }
    }
  }, [props.playersData]);

  function isFulfilled(type: string) {
    if (props.outcome.outcomeCondition.behavior === type && lobbyScores && !ooutcome.outcomeCondition.isFulfilled(lobbyScores)) {
      return false;
    }
    return true;
  }
  return (
    <div
      ref={outcome}
      className="outcome"
      style={{
        left: position.x + "px",
        top: position.y + "px",
        width: size.w + "px",
        height: size.h + "px",
        display: !isFulfilled("hide") ? "none" : "flex",
      }}
    >
      {props.image ? (
        <Img ref={outcome_visual} src={props.image} className={`outcome-img ${!isFulfilled("lock") ? "locked-outcome" : ""}`}></Img>
      ) : (
        <div
          ref={outcome_visual}
          className={`outcome-img ${!isFulfilled("lock") ? "locked-outcome" : ""}`}
          style={{ backgroundColor: props.backgroundColor, borderRadius: radius + "px" }}
        ></div>
      )}
      {!isFulfilled("lock") && <Lock className="locked-outcome-icon" />}
      <OutcomeUnlocked {...props} />
    </div>
  );
}

function OutcomeLocked(props: any) {
  return (
    <>
      <Lock className="locked-outcome-icon" />
      <div className="outcome-description">
        <div className="outcome-title">Outcome locked</div>
        <div className="outcome-content">{props.outcome.description}</div>
      </div>
    </>
  );
}

function OutcomeUnlocked(props: any) {
  return (
    <div className="outcome-description">
      <div className="outcome-title">{props.title}</div>
      <div className="outcome-content">{props.description}</div>
      {props.scoreTable && Object.keys(props.roles).length > 0 && (
        <>
          <hr className="team-score-separator"></hr>
          <div>
            {props.scoreTable.scoreCurrencies &&
              props.scoreTable.scoreCurrencies.map((table: any, index: any) => {
                return (
                  <div style={{ display: "flex", justifyContent: "space-between" }} key={index}>
                    <div style={{ whiteSpace: "nowrap" }} className="score-title text-sm">
                      {table}
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", width: "unset" }} className="lobby-role">
                      {props.roles.map((role: Role, index: number) => {
                        let roleScore = props.scoreTable.getRoleScoreForOutcome(table, role, props.outcome);
                        return (
                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} key={index}>
                            {role.image ? (
                              <img className="username-role" style={{ margin: 0 }} src={role.image}></img>
                            ) : (
                              <div className="username-role round" style={{ backgroundColor: role.backgroundColor, margin: 0 }}></div>
                            )}
                            <div className="text-sm">{roleScore}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
      {Object.keys(props.scoreTable.teamScores).length > 0 && (
        <>
          <hr className="team-score-separator"></hr>
          <div style={{ textAlign: "center" }} className="text-m">
            Team score
          </div>
          {props?.scoreTable?.scoreCurrencies?.map((score: any, index: number) => {
            let teamScore = props.scoreTable.getTeamScoreForOutcome(score, props.outcome) || 0;
            return (
              <div key={index} className="text-sm">
                {score}: {teamScore}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}
